<template>
  <div>
    <div class="header">
      <div class="company">
        <a href="/">
          <img
            id="profile-img"
            src="https://www.fortify247.net/wp-content/uploads/2020/12/fortify247-header-logo-black.png"
            class="profile-img-card"
        /></a>
      </div>
      <h1>IT Takeover Pack</h1>
      <div class="contact-details">
        <a href="tel:01263805012">
          <ion-icon name="call"></ion-icon> 01263 805012
        </a>
        |
        <a href="mailto:info@fortify247.net">
          <ion-icon name="mail"></ion-icon> info@fortify247.net
        </a>
        <p>
          <ion-icon name="navigate"></ion-icon> 36 Cromer Road, West Runton NR27
          9AD UK
        </p>
      </div>
    </div>
    <div class="main-container">
      <div class="page1" v-if="page == 1">
        <h2>INTRODUCTION</h2>
        <p>Hi {{ firstName }},</p>
        <p>
          Clive here, I'm with Fortify247 Ltd and as you probably know, we're
          ramping up to look after the IT support needs of
          {{ organizationName }}.
        </p>
        <p>
          We know as much as you do that it's never a fun time to end a
          relationship with a client, however it's a natural part of doing
          business and
          <strong
            >we work hard to make sure the clients are looked after throughout
            the transition</strong
          >
          (no matter what side we are on).
        </p>
        <p>
          So, during this process, we will be respectful of your time and
          appreciate your assistance and we ask that you offer us the same in
          return to make sure that this handover process is as seamless as
          possible for <strong>{{ organizationName }} </strong>.
        </p>
        <p>
          To help us kick things off, could you please securely send us all of
          the information you have on the items included in the following pages.
          While you may not have information on everything, we would appreciate
          as much as possible to ensure a smooth and safe transition.
        </p>
        <!-- <p>
          I have CC'd
          <strong
            ><i>{{ email }}</i></strong
          >
          who can verify this request to give you any required authority you may
          need if you don’t have it yet.
        </p> -->
        <p>
          In addition, we are in the process of assessing their current
          infrastructure and may uncover other items that we need throughout
          this process. If you have a preferred way of us getting in touch for
          these, please hit reply and let me know. Otherwise, we'll use the
          following:
        </p>
        <ul>
          <li>
            Name: <b>{{ firstName }} {{ lastName }}</b>
          </li>
          <li>
            Phone: <b>{{ phone }}</b>
          </li>
          <li>
            Email: <b>{{ email }}</b>
          </li>
        </ul>
        <p>
          If you need to get in touch with me at all for any reason, please
          don't hesitate to call me on
          <a href="tel:01263805012">
            01263 805012
          </a>
          or shoot me an email on
          <a href="mailto:info@fortify247.net"> info@fortify247.net </a>.
        </p>
      </div>
      <div class="page2" v-if="page == 2">
        <h2>GENERAL ITEMS</h2>

        <h3>SERVICE REQUESTS</h3>
        <ul style="list-style-type:square">
          <li>
            Please attach the most recent 12 months of Service Requests /
            Support Tickets, including all Notes and Resolutions
          </li>
          <li>
            Please attach a list of all open Service Requests / Support Tickets
            that are currently <strong>Outstanding</strong> or
            <strong>In Progress</strong>, including any existing notes
          </li>
          <li>
            Please attach a list of all Recurring Tasks required to manage the
            Systems / Network
          </li>
          <input
            type="file"
            ref="serviceRequestFiles"
            name="serviceRequestFiles"
            multiple
            @change="onServiceRequestFilesSelected"
          />
          <div class="submit-button">
            <button
              class="btn btn-primary btn-block"
              :disabled="loadingServiceRequest"
              @click="uploadServiceRequestFiles"
            >
              <span
                v-show="loadingServiceRequest"
                class="spinner-border spinner-border-sm"
              ></span>
              <span>Upload files</span>
            </button>
          </div>
        </ul>

        <h3>BUSINESS CONTINUITY & DISASTER RECOVERY PLANS</h3>
        <ul style="list-style-type:square">
          <li>
            Please attach the Business Continuity & Disaster Recovery Plans
          </li>
          <input
            type="file"
            ref="recoveryPlanFiles"
            name="recoveryPlanFiles"
            multiple
            @change="onRecoveryPlanFilesSelected"
          />
          <div class="submit-button">
            <button
              class="btn btn-primary btn-block"
              :disabled="loadingRecoveryPlan"
              @click="uploadRecoveryPlanFiles"
            >
              <span
                v-show="loadingRecoveryPlan"
                class="spinner-border spinner-border-sm"
              ></span>
              <span>Upload files</span>
            </button>
          </div>
        </ul>

        <h3>COMPANY POLICIES</h3>
        <ul style="list-style-type:square">
          <li>
            Please attach any Policies (including BYOD / Mobile Device /
            Computer / Internet & Privacy Policies)
          </li>
          <input
            type="file"
            ref="companyPolicyFiles"
            name="companyPolicyFiles"
            multiple
            @change="onCompanyPolicyFilesSelected"
          />
          <div class="submit-button">
            <button
              class="btn btn-primary btn-block"
              :disabled="loadingCompanyPolicy"
              @click="uploadCompanyPolicyFiles"
            >
              <span
                v-show="loadingCompanyPolicy"
                class="spinner-border spinner-border-sm"
              ></span>
              <span>Upload files</span>
            </button>
          </div>
        </ul>

        <h3>WARRANTIES / CARE-PACKS</h3>
        <ul style="list-style-type:square">
          <li>
            Please attach any Warranty / Care-Pack information across all
            devices
          </li>
          <input
            type="file"
            ref="warrantyFiles"
            name="warrantyFiles"
            multiple
            @change="onWarrantyFilesSelected"
          />
          <div class="submit-button">
            <button
              class="btn btn-primary btn-block"
              :disabled="loadingWarranty"
              @click="uploadWarrantyFiles"
            >
              <span
                v-show="loadingWarranty"
                class="spinner-border spinner-border-sm"
              ></span>
              <span>Upload files</span>
            </button>
          </div>
        </ul>

        <h3>NETWORK DIAGRAMS</h3>
        <ul style="list-style-type:square">
          <li>
            Please attach the Network Diagrams
          </li>
          <input
            type="file"
            ref="networkDiagramFiles"
            name="networkDiagramFiles"
            multiple
            @change="onNetworkDiagramFilesSelected"
          />
          <div class="submit-button">
            <button
              class="btn btn-primary btn-block"
              :disabled="loadingNetworkDiagram"
              @click="uploadNetworkDiagramFiles"
            >
              <span
                v-show="loadingNetworkDiagram"
                class="spinner-border spinner-border-sm"
              ></span>
              <span>Upload files</span>
            </button>
          </div>
        </ul>

        <h3>USER MANUALS</h3>
        <ul style="list-style-type:square">
          <li>
            Please attach any User Manuals
          </li>
          <input
            type="file"
            ref="userManualFiles"
            name="userManualFiles"
            multiple
            @change="onUserManualFilesSelected"
          />
          <div class="submit-button">
            <button
              class="btn btn-primary btn-block"
              :disabled="loadingUserManual"
              @click="uploadUserManualFiles"
            >
              <span
                v-show="loadingUserManual"
                class="spinner-border spinner-border-sm"
              ></span>
              <span>Upload files</span>
            </button>
          </div>
        </ul>

        <h3>LICENSES</h3>
        <ul style="list-style-type:square">
          <li>
            Please attach any License Certificates
          </li>
          <input
            type="file"
            ref="licenseFiles"
            name="licenseFiles"
            multiple
            @change="onLicenseFilesSelected"
          />
          <div class="submit-button">
            <button
              class="btn btn-primary btn-block"
              :disabled="loadingLicense"
              @click="uploadLicenseFiles"
            >
              <span
                v-show="loadingLicense"
                class="spinner-border spinner-border-sm"
              ></span>
              <span>Upload files</span>
            </button>
          </div>
        </ul>
      </div>
      <div class="page3" v-if="page == 3">
        <h2>NETWORKING & DEVICES</h2>
        <h3>ROUTERS / FIREWALLS</h3>
        <p class="sub-heading">
          Please list all Routers & Firewalls (including at remote sites)
        </p>
        <div class="form">
          <div class="inputGroup">
            <label for="routerManufacturer">Manufacturer / Model</label>
            <input
              type="text"
              name="routerManufacturer"
              id="routerManufacturer"
              v-model="routerManufacturer"
            />
          </div>
          <div class="inputGroup">
            <label for="routerSerialNumber">Serial Number</label>
            <input
              type="text"
              name="routerSerialNumber"
              id="routerSerialNumber"
              v-model="routerSerialNumber"
            />
          </div>
          <div class="inputGroup">
            <label for="routerLocation">Location</label>
            <select
              name="routerLocation"
              id="routerLocation"
              v-model="routerLocation"
            >
              <option
                :key="location.value"
                v-for="location in locations"
                :value="location.value"
                >{{ location.name }}</option
              >
            </select>
          </div>
          <div class="inputGroup">
            <label for="routerAdminURL">Admin URL</label>
            <input
              type="text"
              name="routerAdminURL"
              id="routerAdminURL"
              v-model="routerAdminURL"
            />
          </div>
          <div class="inputGroup">
            <label for="routerAdminUsername">Admin Username</label>
            <input
              type="text"
              name="routerAdminUsername"
              id="routerAdminUsername"
              v-model="routerAdminUsername"
            />
          </div>
          <div class="inputGroup">
            <label for="routerAdminPassword">Admin Password</label>
            <input
              type="text"
              name="routerAdminPassword"
              id="routerAdminPassword"
              v-model="routerAdminPassword"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateRouters(updateRouterSerial)"
            v-if="updateRouterSerial"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createRouters" v-else>
            Add
          </button>
          <button class="btn btn-primary" @click="resetRouters">
            Reset
          </button>
        </div>
        <div class="table-container" v-if="routers.length > 0">
          <table>
            <thead>
              <th>Manufacturer / Model</th>
              <th>Serial Number</th>
              <th>Location</th>
              <th>Admin URL</th>
              <th>Admin Username</th>
              <th>Admin Password</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="router in routers" :key="router.serialNumber">
                <td>{{ router.manufacturer }}</td>
                <td>{{ router.serialNumber }}</td>
                <td>{{ router.location }}</td>
                <td>{{ router.adminURL }}</td>
                <td>{{ router.adminUsername }}</td>
                <td>{{ router.adminPassword }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="editRouters(router)"
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteRouters(router.serialNumber)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form">
          <div class="inputGroup additionalNotes">
            <label for="routerAdditionalNote">Additional Notes</label>
            <input
              type="text"
              name="routerAdditionalNote"
              id="routerAdditionalNote"
              v-model="routerAdditionalNote"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateRouterNotes(updateRouterAdditionalNote)"
            v-if="updateRouterAdditionalNote"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createRouterNotes" v-else>
            Add
          </button>
          <button
            class="btn btn-primary"
            @click="
              routerAdditionalNote = '';
              updateRouterAdditionalNote = '';
            "
          >
            Reset
          </button>
        </div>
        <div class="table-container" v-if="routerAdditionalNotes.length > 0">
          <table>
            <thead>
              <th>Additional Notes</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="note in routerAdditionalNotes" :key="note">
                <td>{{ note }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="
                      updateRouterAdditionalNote = note;
                      routerAdditionalNote = note;
                    "
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteRouterNotes(note)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <h3>NETWORK SWITCHES</h3>
        <p class="sub-heading">
          Please list all Routers & Firewalls (including at remote sites)
        </p>
        <div class="form">
          <div class="inputGroup">
            <label for="networkSwitchManufacturer">Manufacturer / Model</label>
            <input
              type="text"
              name="networkSwitchManufacturer"
              id="networkSwitchManufacturer"
              v-model="networkSwitchManufacturer"
            />
          </div>
          <div class="inputGroup">
            <label for="networkSwitchSerialNumber">Serial Number</label>
            <input
              type="text"
              name="networkSwitchSerialNumber"
              id="networkSwitchSerialNumber"
              v-model="networkSwitchSerialNumber"
            />
          </div>
          <div class="inputGroup">
            <label for="networkSwitchLocation">Location</label>
            <select
              name="networkSwitchLocation"
              id="networkSwitchLocation"
              v-model="networkSwitchLocation"
            >
              <option
                :key="location.value"
                v-for="location in locations"
                :value="location.value"
                >{{ location.name }}</option
              >
            </select>
          </div>
          <div class="inputGroup">
            <label for="networkSwitchAdminURL">Admin URL</label>
            <input
              type="text"
              name="networkSwitchAdminURL"
              id="networkSwitchAdminURL"
              v-model="networkSwitchAdminURL"
            />
          </div>
          <div class="inputGroup">
            <label for="networkSwitchAdminUsername">Admin Username</label>
            <input
              type="text"
              name="networkSwitchAdminUsername"
              id="networkSwitchAdminUsername"
              v-model="networkSwitchAdminUsername"
            />
          </div>
          <div class="inputGroup">
            <label for="networkSwitchAdminPassword">Admin Password</label>
            <input
              type="text"
              name="networkSwitchAdminPassword"
              id="networkSwitchAdminPassword"
              v-model="networkSwitchAdminPassword"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateNetworkSwitches(updateNetworkSwitchSerial)"
            v-if="updateNetworkSwitchSerial"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createNetworkSwitches" v-else>
            Add
          </button>
          <button class="btn btn-primary" @click="resetNetworkSwitches">
            Reset
          </button>
        </div>
        <div class="table-container" v-if="networkSwitches.length > 0">
          <table>
            <thead>
              <th>Manufacturer / Model</th>
              <th>Serial Number</th>
              <th>Location</th>
              <th>Admin URL</th>
              <th>Admin Username</th>
              <th>Admin Password</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr
                v-for="networkSwitch in networkSwitches"
                :key="networkSwitch.serialNumber"
              >
                <td>{{ networkSwitch.manufacturer }}</td>
                <td>{{ networkSwitch.serialNumber }}</td>
                <td>{{ networkSwitch.location }}</td>
                <td>{{ networkSwitch.adminURL }}</td>
                <td>{{ networkSwitch.adminUsername }}</td>
                <td>{{ networkSwitch.adminPassword }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="editNetworkSwitches(networkSwitch)"
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteNetworkSwitches(networkSwitch.serialNumber)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form">
          <div class="inputGroup additionalNotes">
            <label for="networkSwitchAdditionalNote">Additional Notes</label>
            <input
              type="text"
              name="networkSwitchAdditionalNote"
              id="networkSwitchAdditionalNote"
              v-model="networkSwitchAdditionalNote"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateNetworkSwitchNotes(updateNetworkSwitchAdditionalNote)"
            v-if="updateNetworkSwitchAdditionalNote"
          >
            Update
          </button>
          <button
            class="btn btn-primary"
            @click="createNetworkSwitchNotes"
            v-else
          >
            Add
          </button>
          <button
            class="btn btn-primary"
            @click="
              networkSwitchAdditionalNote = '';
              updateNetworkSwitchAdditionalNote = '';
            "
          >
            Reset
          </button>
        </div>
        <div
          class="table-container"
          v-if="networkSwitchAdditionalNotes.length > 0"
        >
          <table>
            <thead>
              <th>Additional Notes</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="note in networkSwitchAdditionalNotes" :key="note">
                <td>{{ note }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="
                      updateNetworkSwitchAdditionalNote = note;
                      networkSwitchAdditionalNote = note;
                    "
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteNetworkSwitchNotes(note)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <h3>WIRELESS ACCESS</h3>
        <p class="sub-heading">
          Please list all Wireless Access Point (including at remote sites)
        </p>
        <div class="form">
          <div class="inputGroup">
            <label for="wirelessAccessManufacturer">Manufacturer / Model</label>
            <input
              type="text"
              name="wirelessAccessManufacturer"
              id="wirelessAccessManufacturer"
              v-model="wirelessAccessManufacturer"
            />
          </div>
          <div class="inputGroup">
            <label for="wirelessAccessSerialNumber">Serial Number</label>
            <input
              type="text"
              name="wirelessAccessSerialNumber"
              id="wirelessAccessSerialNumber"
              v-model="wirelessAccessSerialNumber"
            />
          </div>
          <div class="inputGroup">
            <label for="wirelessAccessLocation">Location</label>
            <select
              name="wirelessAccessLocation"
              id="wirelessAccessLocation"
              v-model="wirelessAccessLocation"
            >
              <option
                :key="location.value"
                v-for="location in locations"
                :value="location.value"
                >{{ location.name }}</option
              >
            </select>
          </div>
          <div class="inputGroup">
            <label for="wirelessAccessAdminURL">Admin URL</label>
            <input
              type="text"
              name="wirelessAccessAdminURL"
              id="wirelessAccessAdminURL"
              v-model="wirelessAccessAdminURL"
            />
          </div>
          <div class="inputGroup">
            <label for="wirelessAccessAdminUsername">Admin Username</label>
            <input
              type="text"
              name="wirelessAccessAdminUsername"
              id="wirelessAccessAdminUsername"
              v-model="wirelessAccessAdminUsername"
            />
          </div>
          <div class="inputGroup">
            <label for="wirelessAccessAdminPassword">Admin Password</label>
            <input
              type="text"
              name="wirelessAccessAdminPassword"
              id="wirelessAccessAdminPassword"
              v-model="wirelessAccessAdminPassword"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateWirelessAccesses(updateWirelessAccessSerial)"
            v-if="updateWirelessAccessSerial"
          >
            Update
          </button>
          <button
            class="btn btn-primary"
            @click="createWirelessAccesses"
            v-else
          >
            Add
          </button>
          <button class="btn btn-primary" @click="resetWirelessAccesses">
            Reset
          </button>
        </div>
        <div class="table-container" v-if="wirelessAccesses.length > 0">
          <table>
            <thead>
              <th>Manufacturer / Model</th>
              <th>Serial Number</th>
              <th>Location</th>
              <th>Admin URL</th>
              <th>Admin Username</th>
              <th>Admin Password</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr
                v-for="wirelessAccess in wirelessAccesses"
                :key="wirelessAccess.serialNumber"
              >
                <td>{{ wirelessAccess.manufacturer }}</td>
                <td>{{ wirelessAccess.serialNumber }}</td>
                <td>{{ wirelessAccess.location }}</td>
                <td>{{ wirelessAccess.adminURL }}</td>
                <td>{{ wirelessAccess.adminUsername }}</td>
                <td>{{ wirelessAccess.adminPassword }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="editWirelessAccesses(wirelessAccess)"
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteWirelessAccesses(wirelessAccess.serialNumber)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p class="small-heading">
          Please list all Wireless Networks in use (including at remote sites)
        </p>
        <div class="form">
          <div class="inputGroup">
            <label for="wirelessNetworkIPAddress">IP Address</label>
            <input
              type="text"
              name="wirelessNetworkIPAddress"
              id="wirelessNetworkIPAddress"
              v-model="wirelessNetworkIPAddress"
            />
          </div>
          <div class="inputGroup">
            <label for="wirelessNetworkSSID">SSID</label>
            <input
              type="text"
              name="wirelessNetworkSSID"
              id="wirelessNetworkSSID"
              v-model="wirelessNetworkSSID"
            />
          </div>
          <div class="inputGroup">
            <label for="wirelessNetworkKey">Passphrase / Key</label>
            <input
              type="text"
              name="wirelessNetworkKey"
              id="wirelessNetworkKey"
              v-model="wirelessNetworkKey"
            />
          </div>
          <div class="inputGroup">
            <label for="wirelessNetworkPurpose">Purpose?</label>
            <input
              type="text"
              name="wirelessNetworkPurpose"
              id="wirelessNetworkPurpose"
              v-model="wirelessNetworkPurpose"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateWirelessNetworks(updateWirelessNetworkIPAddress)"
            v-if="updateWirelessNetworkIPAddress"
          >
            Update
          </button>
          <button
            class="btn btn-primary"
            @click="createWirelessNetworks"
            v-else
          >
            Add
          </button>
          <button class="btn btn-primary" @click="resetWirelessNetworks">
            Reset
          </button>
        </div>
        <div class="table-container" v-if="wirelessNetworks.length > 0">
          <table>
            <thead>
              <th>IP Address</th>
              <th>SSID</th>
              <th>Passphrase / Key</th>
              <th>Purpose?</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr
                v-for="wirelessNetwork in wirelessNetworks"
                :key="wirelessNetwork.ipAddress"
              >
                <td>{{ wirelessNetwork.ipAddress }}</td>
                <td>{{ wirelessNetwork.ssID }}</td>
                <td>{{ wirelessNetwork.key }}</td>
                <td>{{ wirelessNetwork.purpose }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="editWirelessNetworks(wirelessNetwork)"
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteWirelessNetworks(wirelessNetwork.ipAddress)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Additional Notes -->
        <div class="form">
          <div class="inputGroup additionalNotes">
            <label for="wirelessAccessAdditionalNote">Additional Notes</label>
            <input
              type="text"
              name="wirelessAccessAdditionalNote"
              id="wirelessAccessAdditionalNote"
              v-model="wirelessAccessAdditionalNote"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="
              updateWirelessAccessNotes(updateWirelessAccessAdditionalNote)
            "
            v-if="updateWirelessAccessAdditionalNote"
          >
            Update
          </button>
          <button
            class="btn btn-primary"
            @click="createWirelessAccessNotes"
            v-else
          >
            Add
          </button>
          <button
            class="btn btn-primary"
            @click="
              wirelessAccessAdditionalNote = '';
              updateWirelessAccessAdditionalNote = '';
            "
          >
            Reset
          </button>
        </div>
        <div
          class="table-container"
          v-if="wirelessAccessAdditionalNotes.length > 0"
        >
          <table>
            <thead>
              <th>Additional Notes</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="note in wirelessAccessAdditionalNotes" :key="note">
                <td>{{ note }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="
                      updateWirelessAccessAdditionalNote = note;
                      wirelessAccessAdditionalNote = note;
                    "
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteWirelessAccessNotes(note)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <h3>VPN / REMOTE ACCESS</h3>
        <p class="sub-heading">
          Please list all VPN’s & Remote Access Details
        </p>
        <div class="form">
          <div class="inputGroup">
            <label for="remoteAccessType"
              >Type (User / Point to Point etc)</label
            >
            <input
              type="text"
              name="remoteAccessType"
              id="remoteAccessType"
              v-model="remoteAccessType"
            />
          </div>
          <div class="inputGroup">
            <label for="remoteAccessIPAddress">Public IP Address</label>
            <input
              type="text"
              name="remoteAccessIPAddress"
              id="remoteAccessIPAddress"
              v-model="remoteAccessIPAddress"
            />
          </div>
          <div class="inputGroup">
            <label for="remoteAccessFirewall">Firewall / Router Name</label>
            <input
              type="text"
              name="remoteAccessFirewall"
              id="remoteAccessFirewall"
              v-model="remoteAccessFirewall"
            />
          </div>
          <div class="inputGroup">
            <label for="remoteAccessPassphrase">Passphrase</label>
            <input
              type="text"
              name="remoteAccessPassphrase"
              id="remoteAccessPassphrase"
              v-model="remoteAccessPassphrase"
            />
          </div>
          <div class="inputGroup">
            <label for="remoteAccessPurpose">Purpose</label>
            <input
              type="text"
              name="remoteAccessPurpose"
              id="remoteAccessPurpose"
              v-model="remoteAccessPurpose"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateRemoteAccesses(updateRemoteAccessIPAddress)"
            v-if="updateRemoteAccessIPAddress"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createRemoteAccesses" v-else>
            Add
          </button>
          <button class="btn btn-primary" @click="resetRemoteAccesses">
            Reset
          </button>
        </div>
        <div class="table-container" v-if="remoteAccesses.length > 0">
          <table>
            <thead>
              <th>Type (User / Point to Point etc)</th>
              <th>Public IP Address</th>
              <th>Firewall / Router Name</th>
              <th>Passphrase</th>
              <th>Purpose</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr
                v-for="remoteAccess in remoteAccesses"
                :key="remoteAccess.ipAddress"
              >
                <td>{{ remoteAccess.type }}</td>
                <td>{{ remoteAccess.ipAddress }}</td>
                <td>{{ remoteAccess.firewall }}</td>
                <td>{{ remoteAccess.passphrase }}</td>
                <td>{{ remoteAccess.purpose }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="editRemoteAccesses(remoteAccess)"
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteRemoteAccesses(remoteAccess.ipAddress)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form">
          <div class="inputGroup additionalNotes">
            <label for="remoteAccessAdditionalNote">Additional Notes</label>
            <input
              type="text"
              name="remoteAccessAdditionalNote"
              id="remoteAccessAdditionalNote"
              v-model="remoteAccessAdditionalNote"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateRemoteAccessNotes(updateRemoteAccessAdditionalNote)"
            v-if="updateRemoteAccessAdditionalNote"
          >
            Update
          </button>
          <button
            class="btn btn-primary"
            @click="createRemoteAccessNotes"
            v-else
          >
            Add
          </button>
          <button
            class="btn btn-primary"
            @click="
              remoteAccessAdditionalNote = '';
              updateRemoteAccessAdditionalNote = '';
            "
          >
            Reset
          </button>
        </div>
        <div
          class="table-container"
          v-if="remoteAccessAdditionalNotes.length > 0"
        >
          <table>
            <thead>
              <th>Additional Notes</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="note in remoteAccessAdditionalNotes" :key="note">
                <td>{{ note }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="
                      updateRemoteAccessAdditionalNote = note;
                      remoteAccessAdditionalNote = note;
                    "
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteRemoteAccessNotes(note)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <h3>PRINTING & SCANNING</h3>
        <p class="sub-heading">
          Please list how Printers, Network Scanners and MFC Units
        </p>
        <div class="form">
          <div class="inputGroup">
            <label for="printerManufacturer">Manufacturer / Model</label>
            <input
              type="text"
              name="printerManufacturer"
              id="printerManufacturer"
              v-model="printerManufacturer"
            />
          </div>
          <div class="inputGroup">
            <label for="printerSerialNumber">Serial Number</label>
            <input
              type="text"
              name="printerSerialNumber"
              id="printerSerialNumber"
              v-model="printerSerialNumber"
            />
          </div>
          <div class="inputGroup">
            <label for="printerLocation">Location</label>
            <select
              name="printerLocation"
              id="printerLocation"
              v-model="printerLocation"
            >
              <option
                :key="location.value"
                v-for="location in locations"
                :value="location.value"
                >{{ location.name }}</option
              >
            </select>
          </div>
          <div class="inputGroup">
            <label for="printerAdminURL">Admin URL</label>
            <input
              type="text"
              name="printerAdminURL"
              id="printerAdminURL"
              v-model="printerAdminURL"
            />
          </div>
          <div class="inputGroup">
            <label for="printerAdminUsername">Admin Username</label>
            <input
              type="text"
              name="printerAdminUsername"
              id="printerAdminUsername"
              v-model="printerAdminUsername"
            />
          </div>
          <div class="inputGroup">
            <label for="printerAdminPassword">Admin Password</label>
            <input
              type="text"
              name="printerAdminPassword"
              id="printerAdminPassword"
              v-model="printerAdminPassword"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updatePrinters(updatePrinterSerial)"
            v-if="updatePrinterSerial"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createPrinters" v-else>
            Add
          </button>
          <button class="btn btn-primary" @click="resetPrinters">
            Reset
          </button>
        </div>
        <div class="table-container" v-if="printers.length > 0">
          <table>
            <thead>
              <th>Manufacturer / Model</th>
              <th>Serial Number</th>
              <th>Location</th>
              <th>Admin URL</th>
              <th>Admin Username</th>
              <th>Admin Password</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="printer in printers" :key="printer.serialNumber">
                <td>{{ printer.manufacturer }}</td>
                <td>{{ printer.serialNumber }}</td>
                <td>{{ printer.location }}</td>
                <td>{{ printer.adminURL }}</td>
                <td>{{ printer.adminUsername }}</td>
                <td>{{ printer.adminPassword }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="editPrinters(printer)"
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deletePrinters(printer.serialNumber)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form">
          <div class="inputGroup additionalNotes">
            <label for="printerAdditionalNote"
              >Notes (Supplier Contracts, Agreement Terms etc)</label
            >
            <input
              type="text"
              name="printerAdditionalNote"
              id="printerAdditionalNote"
              v-model="printerAdditionalNote"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updatePrinterNotes(updatePrinterAdditionalNote)"
            v-if="updatePrinterAdditionalNote"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createPrinterNotes" v-else>
            Add
          </button>
          <button
            class="btn btn-primary"
            @click="
              printerAdditionalNote = '';
              updatePrinterAdditionalNote = '';
            "
          >
            Reset
          </button>
        </div>
        <div class="table-container" v-if="printerAdditionalNotes.length > 0">
          <table>
            <thead>
              <th>
                <center>Notes (Supplier Contracts, Agreement Terms etc)</center>
              </th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="note in printerAdditionalNotes" :key="note">
                <td>{{ note }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="
                      updatePrinterAdditionalNote = note;
                      printerAdditionalNote = note;
                    "
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deletePrinterNotes(note)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <h3>NAS & SAN</h3>
        <p class="sub-heading">
          Please list all Network Attached Storage and Storage Area Networks
        </p>
        <div class="form">
          <div class="inputGroup">
            <label for="nasManufacturer">Manufacturer / Model</label>
            <input
              type="text"
              name="nasManufacturer"
              id="nasManufacturer"
              v-model="nasManufacturer"
            />
          </div>
          <div class="inputGroup">
            <label for="nasSerialNumber">Serial Number</label>
            <input
              type="text"
              name="nasSerialNumber"
              id="nasSerialNumber"
              v-model="nasSerialNumber"
            />
          </div>
          <div class="inputGroup">
            <label for="nasLocation">Location</label>
            <select name="nasLocation" id="nasLocation" v-model="nasLocation">
              <option
                :key="location.value"
                v-for="location in locations"
                :value="location.value"
                >{{ location.name }}</option
              >
            </select>
          </div>
          <div class="inputGroup">
            <label for="nasWarrantyExpiry">Warranty Expiry </label>
            <input
              type="date"
              name="nasWarrantyExpiry"
              id="nasWarrantyExpiry"
              v-model="nasWarrantyExpiry"
            />
          </div>
          <div class="inputGroup">
            <label for="nasServerName">Server Name</label>
            <input
              type="text"
              name="nasServerName"
              id="nasServerName"
              v-model="nasServerName"
            />
          </div>
          <div class="inputGroup">
            <label for="nasPurpose">Purpose</label>
            <input
              type="text"
              name="nasPurpose"
              id="nasPurpose"
              v-model="nasPurpose"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateNases(updateNasSerial)"
            v-if="updateNasSerial"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createNases" v-else>
            Add
          </button>
          <button class="btn btn-primary" @click="resetNases">
            Reset
          </button>
        </div>
        <div class="table-container" v-if="nases.length > 0">
          <table>
            <thead>
              <th>Manufacturer / Model</th>
              <th>Serial Number</th>
              <th>Location</th>
              <th>Warranty Expiry</th>
              <th>Server Name</th>
              <th>Purpose</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="nas in nases" :key="nas.serialNumber">
                <td>{{ nas.manufacturer }}</td>
                <td>{{ nas.serialNumber }}</td>
                <td>{{ nas.location }}</td>
                <td>{{ nas.warranty }}</td>
                <td>{{ nas.sever }}</td>
                <td>{{ nas.purpose }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="editNases(nas)"
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteNases(nas.serialNumber)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form">
          <div class="inputGroup additionalNotes">
            <label for="nasAdditionalNote"
              >Notes (Supplier Contracts, Warranty / Care Pack Details
              etc)</label
            >
            <input
              type="text"
              name="nasAdditionalNote"
              id="nasAdditionalNote"
              v-model="nasAdditionalNote"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateNasNotes(updateNasAdditionalNote)"
            v-if="updateNasAdditionalNote"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createNasNotes" v-else>
            Add
          </button>
          <button
            class="btn btn-primary"
            @click="
              nasAdditionalNote = '';
              updateNasAdditionalNote = '';
            "
          >
            Reset
          </button>
        </div>
        <div class="table-container" v-if="nasAdditionalNotes.length > 0">
          <table>
            <thead>
              <th>
                <center>
                  Notes (Supplier Contracts, Warranty / Care Pack Details etc)
                </center>
              </th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="note in nasAdditionalNotes" :key="note">
                <td>{{ note }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="
                      updateNasAdditionalNote = note;
                      nasAdditionalNote = note;
                    "
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteNasNotes(note)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <h3>UNINTERRUPTIBLE POWER SUPPLIES</h3>
        <p class="sub-heading">
          Please list all Uninterruptible Power Supplies and their details
        </p>
        <div class="form">
          <div class="inputGroup">
            <label for="upsManufacturer">Manufacturer / Model</label>
            <input
              type="text"
              name="upsManufacturer"
              id="upsManufacturer"
              v-model="upsManufacturer"
            />
          </div>
          <div class="inputGroup">
            <label for="upsSerialNumber">Serial Number</label>
            <input
              type="text"
              name="upsSerialNumber"
              id="upsSerialNumber"
              v-model="upsSerialNumber"
            />
          </div>
          <div class="inputGroup">
            <label for="upsLocation">Location</label>
            <select name="upsLocation" id="upsLocation" v-model="upsLocation">
              <option
                :key="location.value"
                v-for="location in locations"
                :value="location.value"
                >{{ location.name }}</option
              >
            </select>
          </div>
          <div class="inputGroup">
            <label for="upsIPAddress">IP Address:Port</label>
            <input
              type="text"
              name="upsIPAddress"
              id="upsIPAddress"
              v-model="upsIPAddress"
            />
          </div>
          <div class="inputGroup">
            <label for="upsUsername">Username</label>
            <input
              type="text"
              name="upsUsername"
              id="upsUsername"
              v-model="upsUsername"
            />
          </div>
          <div class="inputGroup">
            <label for="upsPassword">Password</label>
            <input
              type="text"
              name="upsPassword"
              id="upsPassword"
              v-model="upsPassword"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateUPSes(updateUPSSerial)"
            v-if="updateUPSSerial"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createUPSes" v-else>
            Add
          </button>
          <button class="btn btn-primary" @click="resetUPSes">
            Reset
          </button>
        </div>
        <div class="table-container" v-if="upses.length > 0">
          <table>
            <thead>
              <th>Manufacturer / Model</th>
              <th>Serial Number</th>
              <th>Location</th>
              <th>IP Address:Port</th>
              <th>Username</th>
              <th>Password</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="ups in upses" :key="ups.serialNumber">
                <td>{{ ups.manufacturer }}</td>
                <td>{{ ups.serialNumber }}</td>
                <td>{{ ups.location }}</td>
                <td>{{ ups.ipAddress }}</td>
                <td>{{ ups.username }}</td>
                <td>{{ ups.password }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="editUPSes(ups)"
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteUPSes(ups.serialNumber)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form">
          <div class="inputGroup additionalNotes">
            <label for="upsAdditionalNote"
              >Notes (Devices Protected, Admin Software Locations, Additional
              Warranties etc)</label
            >
            <input
              type="text"
              name="upsAdditionalNote"
              id="upsAdditionalNote"
              v-model="upsAdditionalNote"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateUPSNotes(updateUPSAdditionalNote)"
            v-if="updateUPSAdditionalNote"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createUPSNotes" v-else>
            Add
          </button>
          <button
            class="btn btn-primary"
            @click="
              upsAdditionalNote = '';
              updateUPSAdditionalNote = '';
            "
          >
            Reset
          </button>
        </div>
        <div class="table-container" v-if="upsAdditionalNotes.length > 0">
          <table>
            <thead>
              <th>
                <center>
                  Notes (Devices Protected, Admin Software Locations, Additional
                  Warranties etc)
                </center>
              </th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="note in upsAdditionalNotes" :key="note">
                <td>{{ note }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="
                      updateUPSAdditionalNote = note;
                      upsAdditionalNote = note;
                    "
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteUPSNotes(note)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="page4" v-if="page == 4">
        <h2>INTERNET & WEB SERVICES</h2>
        <h3>INTERNET SERVICE PROVIDER</h3>
        <p class="sub-heading">
          Please list all Internet Services Providers
        </p>
        <div class="form">
          <div class="inputGroup">
            <label for="ispVendor">Vendor</label>
            <input
              type="text"
              name="ispVendor"
              id="ispVendor"
              v-model="ispVendor"
            />
          </div>
          <div class="inputGroup">
            <label for="ispAccountNumber">Account Number</label>
            <input
              type="text"
              name="ispAccountNumber"
              id="ispAccountNumber"
              v-model="ispAccountNumber"
            />
          </div>
          <div class="inputGroup">
            <label for="ispPrimaryContact">Primary Contact</label>
            <input
              type="text"
              name="ispPrimaryContact"
              id="ispPrimaryContact"
              v-model="ispPrimaryContact"
            />
          </div>
          <div class="inputGroup">
            <label for="ispAgreementExpiry">Agreement Expiry</label>
            <input
              type="date"
              name="ispAgreementExpiry"
              id="ispAgreementExpiry"
              v-model="ispAgreementExpiry"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateISPs(updateISPSerial)"
            v-if="updateISPSerial"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createISPs" v-else>
            Add
          </button>
          <button class="btn btn-primary" @click="resetISPs">
            Reset
          </button>
        </div>
        <div class="table-container" v-if="isps.length > 0">
          <table>
            <thead>
              <th>Vendor</th>
              <th>Account Number</th>
              <th>Primary Contact</th>
              <th>Agreement Expiry</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="isp in isps" :key="isp.accountNumber">
                <td>{{ isp.vendor }}</td>
                <td>{{ isp.accountNumber }}</td>
                <td>{{ isp.primaryContact }}</td>
                <td>{{ isp.agreementExpiry }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="editISPs(isp)"
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteISPs(isp.accountNumber)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p class="small-heading">
          Please list all credentials for accounts used to access the web portal
          of the ISP?
        </p>
        <div class="form">
          <div class="inputGroup">
            <label for="portalVendor">Vendor</label>
            <input
              type="text"
              name="portalVendor"
              id="portalVendor"
              v-model="portalVendor"
            />
          </div>
          <div class="inputGroup">
            <label for="portalPortalURL">Portal URL</label>
            <input
              type="text"
              name="portalPortalURL"
              id="portalPortalURL"
              v-model="portalPortalURL"
            />
          </div>
          <div class="inputGroup">
            <label for="portalUsername">Username</label>
            <input
              type="text"
              name="portalUsername"
              id="portalUsername"
              v-model="portalUsername"
            />
          </div>
          <div class="inputGroup">
            <label for="portalPassword">Password</label>
            <input
              type="text"
              name="portalPassword"
              id="portalPassword"
              v-model="portalPassword"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updatePortals(updatePortalURL)"
            v-if="updatePortalURL"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createPortals" v-else>
            Add
          </button>
          <button class="btn btn-primary" @click="resetPortals">
            Reset
          </button>
        </div>
        <div class="table-container" v-if="portals.length > 0">
          <table>
            <thead>
              <th>Vendor</th>
              <th>Portal URL</th>
              <th>Username</th>
              <th>Password</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="portal in portals" :key="portal.portalURL">
                <td>{{ portal.vendor }}</td>
                <td>{{ portal.portalURL }}</td>
                <td>{{ portal.username }}</td>
                <td>{{ portal.password }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="editPortals(portal)"
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deletePortals(portal.portalURL)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p class="small-heading">
          Please list credentials for the WAN connections programmed on the
          router/s?
        </p>
        <div class="form">
          <div class="inputGroup">
            <label for="wanVendor">Vendor</label>
            <input
              type="text"
              name="wanVendor"
              id="wanVendor"
              v-model="wanVendor"
            />
          </div>
          <div class="inputGroup">
            <label for="wanIP">Static IP</label>
            <input type="text" name="wanIP" id="wanIP" v-model="wanIP" />
          </div>
          <div class="inputGroup">
            <label for="wanUsername">Username</label>
            <input
              type="text"
              name="wanUsername"
              id="wanUsername"
              v-model="wanUsername"
            />
          </div>
          <div class="inputGroup">
            <label for="wanPassword">Password</label>
            <input
              type="text"
              name="wanPassword"
              id="wanPassword"
              v-model="wanPassword"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateWans(updateWanIP)"
            v-if="updateWanIP"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createWans" v-else>
            Add
          </button>
          <button class="btn btn-primary" @click="resetWans">
            Reset
          </button>
        </div>
        <div class="table-container" v-if="wans.length > 0">
          <table>
            <thead>
              <th>Vendor</th>
              <th>Static IP</th>
              <th>Username</th>
              <th>Password</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="wan in wans" :key="wan.wanIP">
                <td>{{ wan.vendor }}</td>
                <td>{{ wan.wanIP }}</td>
                <td>{{ wan.username }}</td>
                <td>{{ wan.password }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="editWans(wan)"
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteWans(wan.wanIP)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form">
          <div class="inputGroup additionalNotes">
            <label for="wanAdditionalNote">Notes</label>
            <input
              type="text"
              name="wanAdditionalNote"
              id="wanAdditionalNote"
              v-model="wanAdditionalNote"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateWanNotes(updateWanAdditionalNote)"
            v-if="updateWanAdditionalNote"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createWanNotes" v-else>
            Add
          </button>
          <button
            class="btn btn-primary"
            @click="
              wanAdditionalNote = '';
              updateWanAdditionalNote = '';
            "
          >
            Reset
          </button>
        </div>
        <div class="table-container" v-if="wanAdditionalNotes.length > 0">
          <table>
            <thead>
              <th>Notes</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="note in wanAdditionalNotes" :key="note">
                <td>{{ note }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="
                      updateWanAdditionalNote = note;
                      wanAdditionalNote = note;
                    "
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteWanNotes(note)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <h3>WEB SITES & HOSTING</h3>
        <p class="sub-heading">
          Please list all websites
        </p>
        <div class="form">
          <div class="inputGroup">
            <label for="hostingVendor">Hosting Vendor</label>
            <input
              type="text"
              name="hostingVendor"
              id="hostingVendor"
              v-model="hostingVendor"
            />
          </div>
          <div class="inputGroup">
            <label for="hostingDomain">Primary Domain Name</label>
            <input
              type="text"
              name="hostingDomain"
              id="hostingDomain"
              v-model="hostingDomain"
            />
          </div>
          <div class="inputGroup">
            <label for="cPanelURL">cPanel URL</label>
            <input
              type="text"
              name="cPanelURL"
              id="cPanelURL"
              v-model="cPanelURL"
            />
          </div>
          <div class="inputGroup">
            <label for="cPanelUsername">cPanel Username</label>
            <input
              type="text"
              name="cPanelUsername"
              id="cPanelUsername"
              v-model="cPanelUsername"
            />
          </div>
          <div class="inputGroup">
            <label for="cPanelPassword">cPanel Password</label>
            <input
              type="text"
              name="cPanelPassword"
              id="cPanelPassword"
              v-model="cPanelPassword"
            />
          </div>
          <div class="inputGroup">
            <label for="wordPressAdminURL">WordPress Admin URL</label>
            <input
              type="text"
              name="wordPressAdminURL"
              id="wordPressAdminURL"
              v-model="wordPressAdminURL"
            />
          </div>
          <div class="inputGroup">
            <label for="wordPressUsername">WordPress Username</label>
            <input
              type="text"
              name="wordPressUsername"
              id="wordPressUsername"
              v-model="wordPressUsername"
            />
          </div>
          <div class="inputGroup">
            <label for="wordPressPassword">WordPress Password</label>
            <input
              type="text"
              name="wordPressPassword"
              id="wordPressPassword"
              v-model="wordPressPassword"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateHostings(updateHostingDomain)"
            v-if="updateHostingDomain"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createHostings" v-else>
            Add
          </button>
          <button class="btn btn-primary" @click="resetHostings">
            Reset
          </button>
        </div>
        <div class="table-container" v-if="hostings.length > 0">
          <table>
            <thead>
              <th>Hosting Vendor</th>
              <th>Primary Domain Name</th>
              <th>cPanel URL</th>
              <th>cPanel Username</th>
              <th>cPanel Password</th>
              <th>WordPress Admin URL</th>
              <th>WordPress Username</th>
              <th>WordPress Password</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="hosting in hostings" :key="hosting.domain">
                <td>{{ hosting.vendor }}</td>
                <td>{{ hosting.domain }}</td>
                <td>{{ hosting.cPanelURL }}</td>
                <td>{{ hosting.cPanelUsername }}</td>
                <td>{{ hosting.cPanelPassword }}</td>
                <td>{{ hosting.wordPressAdminURL }}</td>
                <td>{{ hosting.wordPressUsername }}</td>
                <td>{{ hosting.wordPressPassword }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="editHostings(hosting)"
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteHostings(hosting.domain)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form">
          <div class="inputGroup additionalNotes">
            <label for="webSiteAdditionalNote"
              >Notes (Web Designer Details, SEO Supplier Details etc)</label
            >
            <input
              type="text"
              name="webSiteAdditionalNote"
              id="webSiteAdditionalNote"
              v-model="webSiteAdditionalNote"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateWebSiteNotes(updateWebSiteAdditionalNote)"
            v-if="updateWebSiteAdditionalNote"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createWebSiteNotes" v-else>
            Add
          </button>
          <button
            class="btn btn-primary"
            @click="
              webSiteAdditionalNote = '';
              updateWebSiteAdditionalNote = '';
            "
          >
            Reset
          </button>
        </div>
        <div class="table-container" v-if="webSiteAdditionalNotes.length > 0">
          <table>
            <thead>
              <th>
                <center>
                  Notes (Web Designer Details, SEO Supplier Details etc)
                </center>
              </th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="note in webSiteAdditionalNotes" :key="note">
                <td>{{ note }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="
                      updateWebSiteAdditionalNote = note;
                      webSiteAdditionalNote = note;
                    "
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteWebSiteNotes(note)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <h3>SSL CERTIFICATES</h3>
        <p class="sub-heading">
          Please list all SSL Certificates
        </p>
        <div class="form">
          <div class="inputGroup">
            <label for="sslIssuer">Issuer</label>
            <input
              type="text"
              name="sslIssuer"
              id="sslIssuer"
              v-model="sslIssuer"
            />
          </div>
          <div class="inputGroup">
            <label for="sslCertificateURL">Certificate URL</label>
            <input
              type="text"
              name="sslCertificateURL"
              id="sslCertificateURL"
              v-model="sslCertificateURL"
            />
          </div>
          <div class="inputGroup">
            <label for="sslWildcard">Wildcard</label>
            <input
              type="text"
              name="sslWildcard"
              id="sslWildcard"
              v-model="sslWildcard"
            />
          </div>
          <div class="inputGroup">
            <label for="sslExpiryDate">Expiry Date</label>
            <input
              type="date"
              name="sslExpiryDate"
              id="sslExpiryDate"
              v-model="sslExpiryDate"
            />
          </div>
          <div class="inputGroup">
            <label for="sslPurpose">Purpose</label>
            <input
              type="text"
              name="sslPurpose"
              id="sslPurpose"
              v-model="sslPurpose"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateSSLs(updateSSLCertificateURL)"
            v-if="updateSSLCertificateURL"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createSSLs" v-else>
            Add
          </button>
          <button class="btn btn-primary" @click="resetSSLs">
            Reset
          </button>
        </div>
        <div class="table-container" v-if="ssls.length > 0">
          <table>
            <thead>
              <th>Issuer</th>
              <th>Certificate URL</th>
              <th>Wildcard</th>
              <th>Purpose</th>
              <th>Expiry Date</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="ssl in ssls" :key="ssl.certificateURL">
                <td>{{ ssl.issuer }}</td>
                <td>{{ ssl.certificateURL }}</td>
                <td>{{ ssl.wildcard }}</td>
                <td>{{ ssl.expiryDate }}</td>
                <td>{{ ssl.purpose }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="editSSLs(ssl)"
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteSSLs(ssl.certificateURL)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form">
          <div class="inputGroup additionalNotes">
            <label for="sslAdditionalNote">Notes</label>
            <input
              type="text"
              name="sslAdditionalNote"
              id="sslAdditionalNote"
              v-model="sslAdditionalNote"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateSSLNotes(updateSSLAdditionalNote)"
            v-if="updateSSLAdditionalNote"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createSSLNotes" v-else>
            Add
          </button>
          <button
            class="btn btn-primary"
            @click="
              sslAdditionalNote = '';
              updateSSLAdditionalNote = '';
            "
          >
            Reset
          </button>
        </div>
        <div class="table-container" v-if="sslAdditionalNotes.length > 0">
          <table>
            <thead>
              <th>Notes</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="note in sslAdditionalNotes" :key="note">
                <td>{{ note }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="
                      updateSSLAdditionalNote = note;
                      sslAdditionalNote = note;
                    "
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteSSLNotes(note)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <h3>DOMAIN NAMES</h3>
        <p class="sub-heading">
          Please list all domain names along with registrar details.
        </p>
        <div class="form">
          <div class="inputGroup">
            <label for="domainName">Domain Name</label>
            <input
              type="text"
              name="domainName"
              id="domainName"
              v-model="domainName"
            />
          </div>
          <div class="inputGroup">
            <label for="domainRegistrar">Registrar</label>
            <input
              type="text"
              name="domainRegistrar"
              id="domainRegistrar"
              v-model="domainRegistrar"
            />
          </div>
          <div class="inputGroup">
            <label for="domainAdmin">Registrar Admin URL</label>
            <input
              type="text"
              name="domainAdmin"
              id="domainAdmin"
              v-model="domainAdmin"
            />
          </div>
          <div class="inputGroup">
            <label for="domainUsername">Admin Username</label>
            <input
              type="text"
              name="domainUsername"
              id="domainUsername"
              v-model="domainUsername"
            />
          </div>
          <div class="inputGroup">
            <label for="domainPassword">Admin Password</label>
            <input
              type="text"
              name="domainPassword"
              id="domainPassword"
              v-model="domainPassword"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateDomains(updateDomainName)"
            v-if="updateDomainName"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createDomains" v-else>
            Add
          </button>
          <button class="btn btn-primary" @click="resetDomains">
            Reset
          </button>
        </div>
        <div class="table-container" v-if="domains.length > 0">
          <table>
            <thead>
              <th>Domain Name</th>
              <th>Registrar</th>
              <th>Registrar Admin URL</th>
              <th>Admin Username</th>
              <th>Admin Password</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="domain in domains" :key="domain.certificateURL">
                <td>{{ domain.certificateURL }}</td>
                <td>{{ domain.issuer }}</td>
                <td>{{ domain.wildcard }}</td>
                <td>{{ domain.expiryDate }}</td>
                <td>{{ domain.purpose }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="editDomains(domain)"
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteDomains(domain.certificateURL)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form">
          <div class="inputGroup additionalNotes">
            <label for="domainAdditionalNote">Notes</label>
            <input
              type="text"
              name="domainAdditionalNote"
              id="domainAdditionalNote"
              v-model="domainAdditionalNote"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateDomainNotes(updateDomainAdditionalNote)"
            v-if="updateDomainAdditionalNote"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createDomainNotes" v-else>
            Add
          </button>
          <button
            class="btn btn-primary"
            @click="
              domainAdditionalNote = '';
              updateDomainAdditionalNote = '';
            "
          >
            Reset
          </button>
        </div>
        <div class="table-container" v-if="domainAdditionalNotes.length > 0">
          <table>
            <thead>
              <th>Notes</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="note in domainAdditionalNotes" :key="note">
                <td>{{ note }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="
                      updateDomainAdditionalNote = note;
                      domainAdditionalNote = note;
                    "
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteDomainNotes(note)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <h3>EMAIL SETUP</h3>
        <p class="sub-heading">
          Please list the Email Provider/s
        </p>

        <div class="form">
          <div class="inputGroup">
            <label for="emailVendor">Vendor</label>
            <input
              type="text"
              name="emailVendor"
              id="emailVendor"
              v-model="emailVendor"
            />
          </div>
          <div class="inputGroup">
            <label for="emailAdminURL"
              >Control Panel / Portal / Admin URL</label
            >
            <input
              type="text"
              name="emailAdminURL"
              id="emailAdminURL"
              v-model="emailAdminURL"
            />
          </div>
          <div class="inputGroup">
            <label for="emailUsername">Admin Username</label>
            <input
              type="text"
              name="emailUsername"
              id="emailUsername"
              v-model="emailUsername"
            />
          </div>
          <div class="inputGroup">
            <label for="emailPassword">Admin Password</label>
            <input
              type="text"
              name="emailPassword"
              id="emailPassword"
              v-model="emailPassword"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateEmails(updateEmailAdminURL)"
            v-if="updateEmailAdminURL"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createEmails" v-else>
            Add
          </button>
          <button class="btn btn-primary" @click="resetEmails">
            Reset
          </button>
        </div>
        <div class="table-container" v-if="emails.length > 0">
          <table>
            <thead>
              <th>Vendor</th>
              <th>Control Panel / Portal / Admin URL</th>
              <th>Admin Username</th>
              <th>Admin Password</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="email in emails" :key="email.adminURL">
                <td>{{ email.vendor }}</td>
                <td>{{ email.adminURL }}</td>
                <td>{{ email.username }}</td>
                <td>{{ email.password }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="editEmails(email)"
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteEmails(email.adminURL)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form">
          <div class="inputGroup additionalNotes">
            <label for="emailAdditionalNote"
              >Notes (Specific Customisation etc)</label
            >
            <input
              type="text"
              name="emailAdditionalNote"
              id="emailAdditionalNote"
              v-model="emailAdditionalNote"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateEmailNotes(updateEmailAdditionalNote)"
            v-if="updateEmailAdditionalNote"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createEmailNotes" v-else>
            Add
          </button>
          <button
            class="btn btn-primary"
            @click="
              emailAdditionalNote = '';
              updateEmailAdditionalNote = '';
            "
          >
            Reset
          </button>
        </div>
        <div class="table-container" v-if="emailAdditionalNotes.length > 0">
          <table>
            <thead>
              <th>Notes (Specific Customisation etc)</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="note in emailAdditionalNotes" :key="note">
                <td>{{ note }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="
                      updateEmailAdditionalNote = note;
                      emailAdditionalNote = note;
                    "
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteEmailNotes(note)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="page5" v-if="page == 5">
        <h2>SERVERS</h2>
        <h3>PHYSICAL SERVERS</h3>
        <p class="sub-heading">
          Please list all Physical Servers (including in Data-Centres)
        </p>
        <div class="form">
          <div class="inputGroup">
            <label for="serverManufacturer">Manufacturer / Model</label>
            <input
              type="text"
              name="serverManufacturer"
              id="serverManufacturer"
              v-model="serverManufacturer"
            />
          </div>
          <div class="inputGroup">
            <label for="serverSerialNumber">Serial Number</label>
            <input
              type="text"
              name="serverSerialNumber"
              id="serverSerialNumber"
              v-model="serverSerialNumber"
            />
          </div>
          <div class="inputGroup">
            <label for="serverLocation">Location</label>
            <select
              name="serverLocation"
              id="serverLocation"
              v-model="serverLocation"
            >
              <option
                :key="location.value"
                v-for="location in locations"
                :value="location.value"
                >{{ location.name }}</option
              >
            </select>
          </div>
          <div class="inputGroup">
            <label for="serverWarrantyExpiry">Warranty Expiry </label>
            <input
              type="date"
              name="serverWarrantyExpiry"
              id="serverWarrantyExpiry"
              v-model="serverWarrantyExpiry"
            />
          </div>
          <div class="inputGroup">
            <label for="serverServerName">Server Name</label>
            <input
              type="text"
              name="serverServerName"
              id="serverServerName"
              v-model="serverServerName"
            />
          </div>
          <div class="inputGroup">
            <label for="serverPurpose">Purpose</label>
            <input
              type="text"
              name="serverPurpose"
              id="serverPurpose"
              v-model="serverPurpose"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateServers(updateServerSerial)"
            v-if="updateServerSerial"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createServers" v-else>
            Add
          </button>
          <button class="btn btn-primary" @click="resetServers">
            Reset
          </button>
        </div>
        <div class="table-container" v-if="servers.length > 0">
          <table>
            <thead>
              <th>Manufacturer / Model</th>
              <th>Serial Number</th>
              <th>Location</th>
              <th>Warranty Expiry</th>
              <th>Server Name</th>
              <th>Purpose</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="server in servers" :key="server.serialNumber">
                <td>{{ server.manufacturer }}</td>
                <td>{{ server.serialNumber }}</td>
                <td>{{ server.location }}</td>
                <td>{{ server.warranty }}</td>
                <td>{{ server.sever }}</td>
                <td>{{ server.purpose }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="editServers(server)"
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteServers(server.serialNumber)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form">
          <div class="inputGroup additionalNotes">
            <label for="serverAdditionalNote"
              >Notes (Supplier Contracts, Warranty / Care Pack Details
              etc)</label
            >
            <input
              type="text"
              name="serverAdditionalNote"
              id="serverAdditionalNote"
              v-model="serverAdditionalNote"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateServerNotes(updateServerAdditionalNote)"
            v-if="updateServerAdditionalNote"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createServerNotes" v-else>
            Add
          </button>
          <button
            class="btn btn-primary"
            @click="
              serverAdditionalNote = '';
              updateServerAdditionalNote = '';
            "
          >
            Reset
          </button>
        </div>
        <div class="table-container" v-if="serverAdditionalNotes.length > 0">
          <table>
            <thead>
              <th>
                <center>
                  Notes (Supplier Contracts, Warranty / Care Pack Details etc)
                </center>
              </th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="note in serverAdditionalNotes" :key="note">
                <td>{{ note }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="
                      updateServerAdditionalNote = note;
                      serverAdditionalNote = note;
                    "
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteServerNotes(note)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <h3>VIRTUALISATION HOSTS</h3>
        <p class="sub-heading">
          Please list all Virtual Hosts (e.g. VMWare / Hyper-V etc)
        </p>
        <div class="form">
          <div class="inputGroup">
            <label for="virtualType">Type (e.g. VMWare / Hyper-V)</label>
            <input
              type="text"
              name="virtualType"
              id="virtualType"
              v-model="virtualType"
            />
          </div>
          <div class="inputGroup">
            <label for="virtualAdminURL">Admin URL:Port</label>
            <input
              type="text"
              name="virtualAdminURL"
              id="virtualAdminURL"
              v-model="virtualAdminURL"
            />
          </div>
          <div class="inputGroup">
            <label for="virtualAdminUsername">Admin Username</label>
            <input
              type="text"
              name="virtualAdminUsername"
              id="virtualAdminUsername"
              v-model="virtualAdminUsername"
            />
          </div>
          <div class="inputGroup">
            <label for="virtualAdminPassword">Admin Password</label>
            <input
              type="text"
              name="virtualAdminPassword"
              id="virtualAdminPassword"
              v-model="virtualAdminPassword"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateVirtuals(updateVirtualURL)"
            v-if="updateVirtualURL"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createVirtuals" v-else>
            Add
          </button>
          <button class="btn btn-primary" @click="resetVirtuals">
            Reset
          </button>
        </div>
        <div class="table-container" v-if="virtuals.length > 0">
          <table>
            <thead>
              <th>Type</th>
              <th>Admin URL:Port</th>
              <th>Admin Username</th>
              <th>Admin Password</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="virtual in virtuals" :key="virtual.adminURL">
                <td>{{ virtual.type }}</td>
                <td>{{ virtual.adminURL }}</td>
                <td>{{ virtual.adminUsername }}</td>
                <td>{{ virtual.adminPassword }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="editVirtuals(virtual)"
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteVirtuals(virtual.adminURL)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form">
          <div class="inputGroup additionalNotes">
            <label for="virtualAdditionalNote"
              >Notes (Configurations /Failovers etc)</label
            >
            <input
              type="text"
              name="virtualAdditionalNote"
              id="virtualAdditionalNote"
              v-model="virtualAdditionalNote"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateVirtualNotes(updateVirtualAdditionalNote)"
            v-if="updateVirtualAdditionalNote"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createVirtualNotes" v-else>
            Add
          </button>
          <button
            class="btn btn-primary"
            @click="
              virtualAdditionalNote = '';
              updateVirtualAdditionalNote = '';
            "
          >
            Reset
          </button>
        </div>
        <div class="table-container" v-if="virtualAdditionalNotes.length > 0">
          <table>
            <thead>
              <th>Notes (Configurations /Failovers etc)</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="note in virtualAdditionalNotes" :key="note">
                <td>{{ note }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="
                      updateVirtualAdditionalNote = note;
                      virtualAdditionalNote = note;
                    "
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteVirtualNotes(note)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <h3>LOGICAL SERVER INSTANCES</h3>
        <p class="sub-heading">
          Please list all Physical Server & Virtual Server Instances (including
          in Data-Centres)
        </p>
        <div class="form">
          <div class="inputGroup">
            <label for="logServerName">Server Name</label>
            <input
              type="text"
              name="logServerName"
              id="logServerName"
              v-model="logServerName"
            />
          </div>
          <div class="inputGroup">
            <label for="logServerIP">Primary IP Address</label>
            <input
              type="text"
              name="logServerIP"
              id="logServerIP"
              v-model="logServerIP"
            />
          </div>
          <div class="inputGroup">
            <label for="logServerLocation">Location</label>
            <select
              name="logServerLocation"
              id="logServerLocation"
              v-model="logServerLocation"
            >
              <option
                :key="location.value"
                v-for="location in locations"
                :value="location.value"
                >{{ location.name }}</option
              >
            </select>
          </div>
          <div class="inputGroup">
            <label for="logServerSerial">Serial Number (if Physical)</label>
            <input
              type="text"
              name="logServerSerial"
              id="logServerSerial"
              v-model="logServerSerial"
            />
          </div>
          <div class="inputGroup">
            <label for="logServerHost">Virtual Host (if Virtual)</label>
            <input
              type="text"
              name="logServerHost"
              id="logServerHost"
              v-model="logServerHost"
            />
          </div>
          <div class="inputGroup">
            <label for="logServerPurpose">Purpose</label>
            <input
              type="text"
              name="logServerPurpose"
              id="logServerPurpose"
              v-model="logServerPurpose"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateLogServers(updateLogServerIP)"
            v-if="updateLogServerIP"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createLogServers" v-else>
            Add
          </button>
          <button class="btn btn-primary" @click="resetLogServers">
            Reset
          </button>
        </div>
        <div class="table-container" v-if="logServers.length > 0">
          <table>
            <thead>
              <th>Server Name</th>
              <th>Primary IP Address</th>
              <th>Location</th>
              <th>Serial Number (if Physical)</th>
              <th>Virtual Host (if Virtual)</th>
              <th>Purpose</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="logServer in logServers" :key="logServer.ip">
                <td>{{ logServer.name }}</td>
                <td>{{ logServer.ip }}</td>
                <td>{{ logServer.location }}</td>
                <td>{{ logServer.serialNumber }}</td>
                <td>{{ logServer.host }}</td>
                <td>{{ logServer.purpose }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="editLogServers(logServer)"
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteLogServers(logServer.ip)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form">
          <div class="inputGroup additionalNotes">
            <label for="logServerAdditionalNote"
              >Notes (Specific Configurations, etc)</label
            >
            <input
              type="text"
              name="logServerAdditionalNote"
              id="logServerAdditionalNote"
              v-model="logServerAdditionalNote"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateLogServerNotes(updateLogServerAdditionalNote)"
            v-if="updateLogServerAdditionalNote"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createLogServerNotes" v-else>
            Add
          </button>
          <button
            class="btn btn-primary"
            @click="
              logServerAdditionalNote = '';
              updateLogServerAdditionalNote = '';
            "
          >
            Reset
          </button>
        </div>
        <div class="table-container" v-if="logServerAdditionalNotes.length > 0">
          <table>
            <thead>
              <th>Notes (Specific Configurations, etc)</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="note in logServerAdditionalNotes" :key="note">
                <td>{{ note }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="
                      updateLogServerAdditionalNote = note;
                      logServerAdditionalNote = note;
                    "
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteLogServerNotes(note)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="page6" v-if="page == 6">
        <h2>CREDENTIALS</h2>
        <h3>DOMAIN ADMINISTRATOR CREDENTIALS</h3>
        <p class="sub-heading">
          Please list all Domain Administrator Credentials
        </p>
        <div class="form">
          <div class="inputGroup">
            <label for="domainCredName">Domain Name (i.e. COMPANY.LOCAL)</label>
            <input
              type="text"
              name="domainCredName"
              id="domainCredName"
              v-model="domainCredName"
            />
          </div>
          <div class="inputGroup">
            <label for="domainCredUsername">Local Admin Username</label>
            <input
              type="text"
              name="domainCredUsername"
              id="domainCredUsername"
              v-model="domainCredUsername"
            />
          </div>
          <div class="inputGroup">
            <label for="domainCredPassword">Local Admin Password</label>
            <input
              type="text"
              name="domainCredPassword"
              id="domainCredPassword"
              v-model="domainCredPassword"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateDomainCreds(updateCredName)"
            v-if="updateCredName"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createDomainCreds" v-else>
            Add
          </button>
          <button class="btn btn-primary" @click="resetDomainCreds">
            Reset
          </button>
        </div>
        <div class="table-container" v-if="domainCreds.length > 0">
          <table>
            <thead>
              <th>Domain Name</th>
              <th>Local Admin Username</th>
              <th>Local Admin Password</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="domainCred in domainCreds" :key="domainCred.name">
                <td>{{ domainCred.name }}</td>
                <td>{{ domainCred.username }}</td>
                <td>{{ domainCred.password }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="editDomainCreds(domainCred)"
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteDomainCreds(domainCred.name)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <h3>LOCAL ADMIN DETAILS</h3>
        <p class="sub-heading">
          Please provide any Local Admin Credentials (for Servers & Computers).
          If there are standard Local Admin Credentials across ALL desktops,
          please list this here.
        </p>
        <div class="form">
          <div class="inputGroup">
            <label for="deviceName">Device Name</label>
            <input
              type="text"
              name="deviceName"
              id="deviceName"
              v-model="deviceName"
            />
          </div>
          <div class="inputGroup">
            <label for="deviceUsername">Local Admin Username</label>
            <input
              type="text"
              name="deviceUsername"
              id="deviceUsername"
              v-model="deviceUsername"
            />
          </div>
          <div class="inputGroup">
            <label for="devicePassword">Local Admin Password</label>
            <input
              type="text"
              name="devicePassword"
              id="devicePassword"
              v-model="devicePassword"
            />
          </div>
          <div class="inputGroup">
            <label for="devicePurpose">Purpose</label>
            <input
              type="text"
              name="devicePurpose"
              id="devicePurpose"
              v-model="devicePurpose"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateDevices(updateDeviceName)"
            v-if="updateDeviceName"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createDevices" v-else>
            Add
          </button>
          <button class="btn btn-primary" @click="resetDevices">
            Reset
          </button>
        </div>
        <div class="table-container" v-if="devices.length > 0">
          <table>
            <thead>
              <th>Device Name</th>
              <th>Local Admin Username</th>
              <th>Local Admin Password</th>
              <th>purpose</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="device in devices" :key="device.name">
                <td>{{ device.name }}</td>
                <td>{{ device.username }}</td>
                <td>{{ device.password }}</td>
                <td>{{ device.purpose }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="editDevices(device)"
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteDevices(device.name)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <h3>DSRM CREDENTIALS</h3>
        <p class="sub-heading">
          Please list all Directory Service Restore Mode Passwords for all
          Domain Controllers.
        </p>
        <div class="form">
          <div class="inputGroup">
            <label for="dsrmServerName">Server Name</label>
            <input
              type="text"
              name="dsrmServerName"
              id="dsrmServerName"
              v-model="dsrmServerName"
            />
          </div>
          <div class="inputGroup">
            <label for="dsrmServerPassword">Password</label>
            <input
              type="text"
              name="dsrmServerPassword"
              id="dsrmServerPassword"
              v-model="dsrmServerPassword"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateDSRMServers(updateDSRMName)"
            v-if="updateDSRMName"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createDSRMServers" v-else>
            Add
          </button>
          <button class="btn btn-primary" @click="resetDSRMServers">
            Reset
          </button>
        </div>
        <div class="table-container" v-if="dsrmServers.length > 0">
          <table>
            <thead>
              <th>Server Name</th>
              <th>Password</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="dsrmServer in dsrmServers" :key="dsrmServer.name">
                <td>{{ dsrmServer.name }}</td>
                <td>{{ dsrmServer.password }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="editDSRMServers(dsrmServer)"
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteDSRMServers(dsrmServer.name)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <h3>SQL CREDENTIALS</h3>
        <p class="sub-heading">
          Please list all SQL Server / SA Credentials
        </p>
        <div class="form">
          <div class="inputGroup">
            <label for="sqlServerName">Server Name</label>
            <input
              type="text"
              name="sqlServerName"
              id="sqlServerName"
              v-model="sqlServerName"
            />
          </div>
          <div class="inputGroup">
            <label for="sqlServerUsername">Username</label>
            <input
              type="text"
              name="sqlServerUsername"
              id="sqlServerUsername"
              v-model="sqlServerUsername"
            />
          </div>
          <div class="inputGroup">
            <label for="sqlServerPassword">Password</label>
            <input
              type="text"
              name="sqlServerPassword"
              id="sqlServerPassword"
              v-model="sqlServerPassword"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateSQLServers(updateSQLName)"
            v-if="updateSQLName"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createSQLServers" v-else>
            Add
          </button>
          <button class="btn btn-primary" @click="resetSQLServers">
            Reset
          </button>
        </div>
        <div class="table-container" v-if="sqlServers.length > 0">
          <table>
            <thead>
              <th>Server Name</th>
              <th>Username</th>
              <th>Password</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="sqlServer in sqlServers" :key="sqlServer.name">
                <td>{{ sqlServer.name }}</td>
                <td>{{ sqlServer.username }}</td>
                <td>{{ sqlServer.password }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="editSQLServers(sqlServer)"
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteSQLServers(sqlServer.name)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="page7" v-if="page == 7">
        <h2>SOFTWARE</h2>
        <h3>CLOUD / SAAS VENDORS</h3>
        <p class="sub-heading">
          Please list all Cloud & SaaS Vendors (including any G-Suite /
          Office365).
        </p>
        <div class="form">
          <div class="inputGroup">
            <label for="cloudVendor">Vendor</label>
            <input
              type="text"
              vendor="cloudVendor"
              id="cloudVendor"
              v-model="cloudVendor"
            />
          </div>
          <div class="inputGroup">
            <label for="cloudAccountNumber">Account Number</label>
            <input
              type="text"
              vendor="cloudAccountNumber"
              id="cloudAccountNumber"
              v-model="cloudAccountNumber"
            />
          </div>
          <div class="inputGroup">
            <label for="cloudAdminURL">Admin URL:Port</label>
            <input
              type="text"
              vendor="cloudAdminURL"
              id="cloudAdminURL"
              v-model="cloudAdminURL"
            />
          </div>
          <div class="inputGroup">
            <label for="cloudUsername">Admin Username</label>
            <input
              type="text"
              vendor="cloudUsername"
              id="cloudUsername"
              v-model="cloudUsername"
            />
          </div>
          <div class="inputGroup">
            <label for="cloudPassword">Admin Password</label>
            <input
              type="text"
              vendor="cloudPassword"
              id="cloudPassword"
              v-model="cloudPassword"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateClouds(updateCloudVendor)"
            v-if="updateCloudVendor"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createClouds" v-else>
            Add
          </button>
          <button class="btn btn-primary" @click="resetClouds">
            Reset
          </button>
        </div>
        <div class="table-container" v-if="clouds.length > 0">
          <table>
            <thead>
              <th>Cloud Name</th>
              <th>Account Number</th>
              <th>Account Number Contact</th>
              <th>Support Number</th>
              <th>Admin Password</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="cloud in clouds" :key="cloud.vendor">
                <td>{{ cloud.vendor }}</td>
                <td>{{ cloud.accountNumber }}</td>
                <td>{{ cloud.adminURL }}</td>
                <td>{{ cloud.username }}</td>
                <td>{{ cloud.password }}</td>
                <td>
                  <ion-icon
                    vendor="create"
                    class="create"
                    @click="editClouds(cloud)"
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    vendor="trash"
                    class="trash"
                    @click="deleteClouds(cloud.vendor)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form">
          <div class="inputGroup additionalNotes">
            <label for="cloudAdditionalNote">Notes</label>
            <input
              type="text"
              vendor="cloudAdditionalNote"
              id="cloudAdditionalNote"
              v-model="cloudAdditionalNote"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateCloudNotes(updateCloudAdditionalNote)"
            v-if="updateCloudAdditionalNote"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createCloudNotes" v-else>
            Add
          </button>
          <button
            class="btn btn-primary"
            @click="
              cloudAdditionalNote = '';
              updateCloudAdditionalNote = '';
            "
          >
            Reset
          </button>
        </div>
        <div class="table-container" v-if="cloudAdditionalNotes.length > 0">
          <table>
            <thead>
              <th>Notes</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="note in cloudAdditionalNotes" :key="note">
                <td>{{ note }}</td>
                <td>
                  <ion-icon
                    vendor="create"
                    class="create"
                    @click="
                      updateCloudAdditionalNote = note;
                      cloudAdditionalNote = note;
                    "
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    vendor="trash"
                    class="trash"
                    @click="deleteCloudNotes(note)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <h3>SECURITY SOFTWARE</h3>
        <p class="sub-heading">
          Please list all Anti-Virus, Anti-Malware, Anti-Spam and Spyware
          Protection Software.
        </p>
        <div class="form">
          <div class="inputGroup">
            <label for="securityVendor">Vendor</label>
            <input
              type="text"
              vendor="securityVendor"
              id="securityVendor"
              v-model="securityVendor"
            />
          </div>
          <div class="inputGroup">
            <label for="securityAccountNumber">Account Number</label>
            <input
              type="text"
              vendor="securityAccountNumber"
              id="securityAccountNumber"
              v-model="securityAccountNumber"
            />
          </div>
          <div class="inputGroup">
            <label for="securityAdminURL">Admin URL:Port</label>
            <input
              type="text"
              vendor="securityAdminURL"
              id="securityAdminURL"
              v-model="securityAdminURL"
            />
          </div>
          <div class="inputGroup">
            <label for="securityUsername">Admin Username</label>
            <input
              type="text"
              vendor="securityUsername"
              id="securityUsername"
              v-model="securityUsername"
            />
          </div>
          <div class="inputGroup">
            <label for="securityPassword">Admin Password</label>
            <input
              type="text"
              vendor="securityPassword"
              id="securityPassword"
              v-model="securityPassword"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateSecurities(updateSecurityVendor)"
            v-if="updateSecurityVendor"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createSecurities" v-else>
            Add
          </button>
          <button class="btn btn-primary" @click="resetSecurities">
            Reset
          </button>
        </div>
        <div class="table-container" v-if="securities.length > 0">
          <table>
            <thead>
              <th>Security Name</th>
              <th>Account Number</th>
              <th>Account Number Contact</th>
              <th>Support Number</th>
              <th>Admin Password</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="security in securities" :key="security.vendor">
                <td>{{ security.vendor }}</td>
                <td>{{ security.accountNumber }}</td>
                <td>{{ security.adminURL }}</td>
                <td>{{ security.username }}</td>
                <td>{{ security.password }}</td>
                <td>
                  <ion-icon
                    vendor="create"
                    class="create"
                    @click="editSecurities(security)"
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    vendor="trash"
                    class="trash"
                    @click="deleteSecurities(security.vendor)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form">
          <div class="inputGroup additionalNotes">
            <label for="securityAdditionalNote"
              >Notes (License Details, Unique Configuration Details, Removal
              Details etc)</label
            >
            <input
              type="text"
              vendor="securityAdditionalNote"
              id="securityAdditionalNote"
              v-model="securityAdditionalNote"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateSecurityNotes(updateSecurityAdditionalNote)"
            v-if="updateSecurityAdditionalNote"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createSecurityNotes" v-else>
            Add
          </button>
          <button
            class="btn btn-primary"
            @click="
              securityAdditionalNote = '';
              updateSecurityAdditionalNote = '';
            "
          >
            Reset
          </button>
        </div>
        <div class="table-container" v-if="securityAdditionalNotes.length > 0">
          <table>
            <thead>
              <th>
                <center>
                  Notes (License Details, Unique Configuration Details, Removal
                  Details etc)
                </center>
              </th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="note in securityAdditionalNotes" :key="note">
                <td>{{ note }}</td>
                <td>
                  <ion-icon
                    vendor="create"
                    class="create"
                    @click="
                      updateSecurityAdditionalNote = note;
                      securityAdditionalNote = note;
                    "
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    vendor="trash"
                    class="trash"
                    @click="deleteSecurityNotes(note)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <h3>APPLICATIONS / LOB VENDORS</h3>
        <p class="sub-heading">
          Please list all Application / Line of Business (LOB) Vendors being
          used
        </p>
        <div class="form">
          <div class="inputGroup">
            <label for="softwareName">Software Name</label>
            <input
              type="text"
              name="softwareName"
              id="softwareName"
              v-model="softwareName"
            />
          </div>
          <div class="inputGroup">
            <label for="softwareVendor">Vendor</label>
            <input
              type="text"
              name="softwareVendor"
              id="softwareVendor"
              v-model="softwareVendor"
            />
          </div>
          <div class="inputGroup">
            <label for="softwareContact">Vendor Contact </label>
            <input
              type="text"
              name="softwareContact"
              id="softwareContact"
              v-model="softwareContact"
            />
          </div>
          <div class="inputGroup">
            <label for="softwareSupport">Support Number</label>
            <input
              type="text"
              name="softwareSupport"
              id="softwareSupport"
              v-model="softwareSupport"
            />
          </div>
          <div class="inputGroup">
            <label for="softwarePurpose">Purpose</label>
            <input
              type="text"
              name="softwarePurpose"
              id="softwarePurpose"
              v-model="softwarePurpose"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateSoftwares(updateSoftwareName)"
            v-if="updateSoftwareName"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createSoftwares" v-else>
            Add
          </button>
          <button class="btn btn-primary" @click="resetSoftwares">
            Reset
          </button>
        </div>
        <div class="table-container" v-if="softwares.length > 0">
          <table>
            <thead>
              <th>Software Name</th>
              <th>Vendor</th>
              <th>Vendor Contact</th>
              <th>Support Number</th>
              <th>Purpose</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="software in softwares" :key="software.name">
                <td>{{ software.name }}</td>
                <td>{{ software.vendor }}</td>
                <td>{{ software.contact }}</td>
                <td>{{ software.support }}</td>
                <td>{{ software.purpose }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="editSoftwares(software)"
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteSoftwares(software.name)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form">
          <div class="inputGroup additionalNotes">
            <label for="softwareAdditionalNote"
              >Notes (License Details, Account Managers etc)</label
            >
            <input
              type="text"
              name="softwareAdditionalNote"
              id="softwareAdditionalNote"
              v-model="softwareAdditionalNote"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateSoftwareNotes(updateSoftwareAdditionalNote)"
            v-if="updateSoftwareAdditionalNote"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createSoftwareNotes" v-else>
            Add
          </button>
          <button
            class="btn btn-primary"
            @click="
              softwareAdditionalNote = '';
              updateSoftwareAdditionalNote = '';
            "
          >
            Reset
          </button>
        </div>
        <div class="table-container" v-if="softwareAdditionalNotes.length > 0">
          <table>
            <thead>
              <th>
                <center>Notes (License Details, Account Managers etc)</center>
              </th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="note in softwareAdditionalNotes" :key="note">
                <td>{{ note }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="
                      updateSoftwareAdditionalNote = note;
                      softwareAdditionalNote = note;
                    "
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteSoftwareNotes(note)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="page8" v-if="page == 8">
        <h2>BACKUPS</h2>
        <h3>ON-SITE BACKUPS</h3>
        <p class="sub-heading">
          Please list all On-Site Backup Solutions in use
        </p>
        <div class="form">
          <div class="inputGroup">
            <label for="backupVendor">Vendor</label>
            <input
              type="text"
              name="backupVendor"
              id="backupVendor"
              v-model="backupVendor"
            />
          </div>
          <div class="inputGroup">
            <label for="backupAdminURL">Admin URL</label>
            <input
              type="text"
              name="backupAdminURL"
              id="backupAdminURL"
              v-model="backupAdminURL"
            />
          </div>
          <div class="inputGroup">
            <label for="backupUsername">Admin Username</label>
            <input
              type="text"
              name="backupUsername"
              id="backupUsername"
              v-model="backupUsername"
            />
          </div>
          <div class="inputGroup">
            <label for="backupPassword">Admin Password</label>
            <input
              type="text"
              name="backupPassword"
              id="backupPassword"
              v-model="backupPassword"
            />
          </div>
          <div class="inputGroup">
            <label for="backupPurpose">Purpose</label>
            <input
              type="text"
              name="backupPurpose"
              id="backupPurpose"
              v-model="backupPurpose"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateBackups(updateBackupAdminURL)"
            v-if="updateBackupAdminURL"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createBackups" v-else>
            Add
          </button>
          <button class="btn btn-primary" @click="resetBackups">
            Reset
          </button>
        </div>
        <div class="table-container" v-if="backups.length > 0">
          <table>
            <thead>
              <th>Vendor</th>
              <th>Admin URL</th>
              <th>Admin Username</th>
              <th>Admin Password</th>
              <th>Purpose</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="backup in backups" :key="backup.adminURL">
                <td>{{ backup.vendor }}</td>
                <td>{{ backup.adminURL }}</td>
                <td>{{ backup.username }}</td>
                <td>{{ backup.password }}</td>
                <td>{{ backup.purpose }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="editBackups(backup)"
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteBackups(backup.adminURL)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form">
          <div class="inputGroup additionalNotes">
            <label for="backupAdditionalNote"
              >Notes (Backup Schedules, Rotation Details, Retention Details
              etc)</label
            >
            <input
              type="text"
              name="backupAdditionalNote"
              id="backupAdditionalNote"
              v-model="backupAdditionalNote"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateBackupNotes(updateBackupAdditionalNote)"
            v-if="updateBackupAdditionalNote"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createBackupNotes" v-else>
            Add
          </button>
          <button
            class="btn btn-primary"
            @click="
              backupAdditionalNote = '';
              updateBackupAdditionalNote = '';
            "
          >
            Reset
          </button>
        </div>
        <div class="table-container" v-if="backupAdditionalNotes.length > 0">
          <table>
            <thead>
              <th>
                <center>
                  Notes (Backup Schedules, Rotation Details, Retention Details
                  etc)
                </center>
              </th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="note in backupAdditionalNotes" :key="note">
                <td>{{ note }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="
                      updateBackupAdditionalNote = note;
                      backupAdditionalNote = note;
                    "
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteBackupNotes(note)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <h3>CLOUD / OFF-SITE BACKUPS</h3>
        <p class="sub-heading">
          Please list all Cloud / Off-Site Backup solutions in use.
        </p>
        <div class="form">
          <div class="inputGroup">
            <label for="offSiteBackupVendor">Vendor</label>
            <input
              type="text"
              name="offSiteBackupVendor"
              id="offSiteBackupVendor"
              v-model="offSiteBackupVendor"
            />
          </div>
          <div class="inputGroup">
            <label for="offSiteBackupAdminURL">Admin URL</label>
            <input
              type="text"
              name="offSiteBackupAdminURL"
              id="offSiteBackupAdminURL"
              v-model="offSiteBackupAdminURL"
            />
          </div>
          <div class="inputGroup">
            <label for="offSiteBackupUsername">Admin Username</label>
            <input
              type="text"
              name="offSiteBackupUsername"
              id="offSiteBackupUsername"
              v-model="offSiteBackupUsername"
            />
          </div>
          <div class="inputGroup">
            <label for="offSiteBackupPassword">Admin Password</label>
            <input
              type="text"
              name="offSiteBackupPassword"
              id="offSiteBackupPassword"
              v-model="offSiteBackupPassword"
            />
          </div>
          <div class="inputGroup">
            <label for="offSiteBackupPurpose">Purpose</label>
            <input
              type="text"
              name="offSiteBackupPurpose"
              id="offSiteBackupPurpose"
              v-model="offSiteBackupPurpose"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateOffSiteBackups(updateOffSiteBackupAdminURL)"
            v-if="updateOffSiteBackupAdminURL"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createOffSiteBackups" v-else>
            Add
          </button>
          <button class="btn btn-primary" @click="resetOffSiteBackups">
            Reset
          </button>
        </div>
        <div class="table-container" v-if="offSiteBackups.length > 0">
          <table>
            <thead>
              <th>Vendor</th>
              <th>Admin URL</th>
              <th>Admin Username</th>
              <th>Admin Password</th>
              <th>Purpose</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr
                v-for="offSiteBackup in offSiteBackups"
                :key="offSiteBackup.adminURL"
              >
                <td>{{ offSiteBackup.vendor }}</td>
                <td>{{ offSiteBackup.adminURL }}</td>
                <td>{{ offSiteBackup.username }}</td>
                <td>{{ offSiteBackup.password }}</td>
                <td>{{ offSiteBackup.purpose }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="editOffSiteBackups(offSiteBackup)"
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteOffSiteBackups(offSiteBackup.adminURL)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form">
          <div class="inputGroup additionalNotes">
            <label for="offSiteBackupAdditionalNote">Additional Notes</label>
            <input
              type="text"
              name="offSiteBackupAdditionalNote"
              id="offSiteBackupAdditionalNote"
              v-model="offSiteBackupAdditionalNote"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateOffSiteBackupNotes(updateOffSiteBackupAdditionalNote)"
            v-if="updateOffSiteBackupAdditionalNote"
          >
            Update
          </button>
          <button
            class="btn btn-primary"
            @click="createOffSiteBackupNotes"
            v-else
          >
            Add
          </button>
          <button
            class="btn btn-primary"
            @click="
              offSiteBackupAdditionalNote = '';
              updateOffSiteBackupAdditionalNote = '';
            "
          >
            Reset
          </button>
        </div>
        <div
          class="table-container"
          v-if="offSiteBackupAdditionalNotes.length > 0"
        >
          <table>
            <thead>
              <th>Additional Notes</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="note in offSiteBackupAdditionalNotes" :key="note">
                <td>{{ note }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="
                      updateOffSiteBackupAdditionalNote = note;
                      offSiteBackupAdditionalNote = note;
                    "
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteOffSiteBackupNotes(note)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="page9" v-if="page == 9">
        <h2>COMPUTERS</h2>
        <h3>INVENTORY</h3>
        <p class="sub-heading">
          Please list all Computers & Laptops.
        </p>
        <div class="form">
          <div class="inputGroup">
            <label for="computerManufacturer">Manufacturer / Model</label>
            <input
              type="text"
              name="computerManufacturer"
              id="computerManufacturer"
              v-model="computerManufacturer"
            />
          </div>
          <div class="inputGroup">
            <label for="computerSerialNumber">Serial Number</label>
            <input
              type="text"
              name="computerSerialNumber"
              id="computerSerialNumber"
              v-model="computerSerialNumber"
            />
          </div>
          <div class="inputGroup">
            <label for="computerLocation">Location</label>
            <select
              name="computerLocation"
              id="computerLocation"
              v-model="computerLocation"
            >
              <option
                :key="location.value"
                v-for="location in locations"
                :value="location.value"
                >{{ location.name }}</option
              >
            </select>
          </div>
          <div class="inputGroup">
            <label for="computerWarrantyExpiry">Warranty Expiry</label>
            <input
              type="date"
              name="computerWarrantyExpiry"
              id="computerWarrantyExpiry"
              v-model="computerWarrantyExpiry"
            />
          </div>
          <div class="inputGroup">
            <label for="computerPrimaryUser">Primary User</label>
            <input
              type="text"
              name="computerPrimaryUser"
              id="computerPrimaryUser"
              v-model="computerPrimaryUser"
            />
          </div>
          <div class="inputGroup">
            <label for="computerDeviceName">Device Name</label>
            <input
              type="text"
              name="computerDeviceName"
              id="computerDeviceName"
              v-model="computerDeviceName"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateComputers(updateComputerSerial)"
            v-if="updateComputerSerial"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createComputers" v-else>
            Add
          </button>
          <button class="btn btn-primary" @click="resetComputers">
            Reset
          </button>
        </div>
        <div class="table-container" v-if="computers.length > 0">
          <table>
            <thead>
              <th>Manufacturer / Model</th>
              <th>Serial Number</th>
              <th>Location</th>
              <th>Warranty Expiry</th>
              <th>Primary User</th>
              <th>Device Name</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="computer in computers" :key="computer.serialNumber">
                <td>{{ computer.manufacturer }}</td>
                <td>{{ computer.serialNumber }}</td>
                <td>{{ computer.location }}</td>
                <td>{{ computer.warranty }}</td>
                <td>{{ computer.primaryUser }}</td>
                <td>{{ computer.devicePassword }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="editComputers(computer)"
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteComputers(computer.serialNumber)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="page10" v-if="page == 10">
        <h2>TELEPHONY</h2>
        <h3>VOIP / PHONE SYSTEM</h3>
        <p class="sub-heading">
          Please list all VOIP / Hosted PABX & Phone Systems
        </p>
        <div class="form">
          <div class="inputGroup">
            <label for="voipManufacturer">Manufacturer / Model</label>
            <input
              type="text"
              name="voipManufacturer"
              id="voipManufacturer"
              v-model="voipManufacturer"
            />
          </div>
          <div class="inputGroup">
            <label for="voipSerialNumber">Serial Number</label>
            <input
              type="text"
              name="voipSerialNumber"
              id="voipSerialNumber"
              v-model="voipSerialNumber"
            />
          </div>
          <div class="inputGroup">
            <label for="voipLocation">Location</label>
            <select
              name="voipLocation"
              id="voipLocation"
              v-model="voipLocation"
            >
              <option
                :key="location.value"
                v-for="location in locations"
                :value="location.value"
                >{{ location.name }}</option
              >
            </select>
          </div>
          <div class="inputGroup">
            <label for="voipAdminURL">Admin URL</label>
            <input
              type="text"
              name="voipAdminURL"
              id="voipAdminURL"
              v-model="voipAdminURL"
            />
          </div>
          <div class="inputGroup">
            <label for="voipAdminUsername">Admin Username</label>
            <input
              type="text"
              name="voipAdminUsername"
              id="voipAdminUsername"
              v-model="voipAdminUsername"
            />
          </div>
          <div class="inputGroup">
            <label for="voipAdminPassword">Admin Password</label>
            <input
              type="text"
              name="voipAdminPassword"
              id="voipAdminPassword"
              v-model="voipAdminPassword"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateVOIPs(updateVOIPSerial)"
            v-if="updateVOIPSerial"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createVOIPs" v-else>
            Add
          </button>
          <button class="btn btn-primary" @click="resetVOIPs">
            Reset
          </button>
        </div>
        <div class="table-container" v-if="voips.length > 0">
          <table>
            <thead>
              <th>Manufacturer / Model</th>
              <th>Serial Number</th>
              <th>Location</th>
              <th>Admin URL</th>
              <th>Admin Username</th>
              <th>Admin Password</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="voip in voips" :key="voip.serialNumber">
                <td>{{ voip.manufacturer }}</td>
                <td>{{ voip.serialNumber }}</td>
                <td>{{ voip.location }}</td>
                <td>{{ voip.adminURL }}</td>
                <td>{{ voip.adminUsername }}</td>
                <td>{{ voip.adminPassword }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="editVOIPs(voip)"
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteVOIPs(voip.serialNumber)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form">
          <div class="inputGroup additionalNotes">
            <label for="voipAdditionalNote"
              >Notes (Call Routing, Customised Items, Extension List etc)</label
            >
            <input
              type="text"
              name="voipAdditionalNote"
              id="voipAdditionalNote"
              v-model="voipAdditionalNote"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateVOIPNotes(updateVOIPAdditionalNote)"
            v-if="updateVOIPAdditionalNote"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createVOIPNotes" v-else>
            Add
          </button>
          <button
            class="btn btn-primary"
            @click="
              voipAdditionalNote = '';
              updateVOIPAdditionalNote = '';
            "
          >
            Reset
          </button>
        </div>
        <div class="table-container" v-if="voipAdditionalNotes.length > 0">
          <table>
            <thead>
              <th>
                <center>
                  Notes (Call Routing, Customised Items, Extension List etc)
                </center>
              </th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="note in voipAdditionalNotes" :key="note">
                <td>{{ note }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="
                      updateVOIPAdditionalNote = note;
                      voipAdditionalNote = note;
                    "
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteVOIPNotes(note)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <h3>TELEPHONY VENDOR</h3>
        <p class="sub-heading">
          Please list all Telephony vendors (including Desk Phones & Mobiles
          etc)
        </p>
        <div class="form">
          <div class="inputGroup">
            <label for="telephonyVendor">Vendor</label>
            <input
              type="text"
              name="telephonyVendor"
              id="telephonyVendor"
              v-model="telephonyVendor"
            />
          </div>
          <div class="inputGroup">
            <label for="telephonyAccountNumber">Account Number</label>
            <input
              type="text"
              name="telephonyAccountNumber"
              id="telephonyAccountNumber"
              v-model="telephonyAccountNumber"
            />
          </div>
          <div class="inputGroup">
            <label for="telephonyContact">Primary Contact</label>
            <input
              type="text"
              name="telephonyContact"
              id="telephonyContact"
              v-model="telephonyContact"
            />
          </div>
          <div class="inputGroup">
            <label for="telephonySupport">Support Phone</label>
            <input
              type="text"
              name="telephonySupport"
              id="telephonySupport"
              v-model="telephonySupport"
            />
          </div>
          <div class="inputGroup">
            <label for="telephonyPurpose">Purpose</label>
            <input
              type="text"
              name="telephonyPurpose"
              id="telephonyPurpose"
              v-model="telephonyPurpose"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateTelephones(updateTelephonyAccountNumber)"
            v-if="updateTelephonyAccountNumber"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createTelephones" v-else>
            Add
          </button>
          <button class="btn btn-primary" @click="resetTelephones">
            Reset
          </button>
        </div>
        <div class="table-container" v-if="telephones.length > 0">
          <table>
            <thead>
              <th>Vendor</th>
              <th>Account Number</th>
              <th>Primary Contact</th>
              <th>Support Phone</th>
              <th>Purpose</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr
                v-for="telephony in telephones"
                :key="telephony.accountNumber"
              >
                <td>{{ telephony.manufacturer }}</td>
                <td>{{ telephony.accountNumber }}</td>
                <td>{{ telephony.contact }}</td>
                <td>{{ telephony.support }}</td>
                <td>{{ telephony.purpose }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="editTelephones(telephony)"
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteTelephones(telephony.accountNumber)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form">
          <div class="inputGroup additionalNotes">
            <label for="telephonyAdditionalNote"
              >Notes (Specific Configurations etc)</label
            >
            <input
              type="text"
              name="telephonyAdditionalNote"
              id="telephonyAdditionalNote"
              v-model="telephonyAdditionalNote"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateTelephonyNotes(updateTelephonyAdditionalNote)"
            v-if="updateTelephonyAdditionalNote"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createTelephonyNotes" v-else>
            Add
          </button>
          <button
            class="btn btn-primary"
            @click="
              telephonyAdditionalNote = '';
              updateTelephonyAdditionalNote = '';
            "
          >
            Reset
          </button>
        </div>
        <div class="table-container" v-if="telephonyAdditionalNotes.length > 0">
          <table>
            <thead>
              <th>Notes (Specific Configurations etc)</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="note in telephonyAdditionalNotes" :key="note">
                <td>{{ note }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="
                      updateTelephonyAdditionalNote = note;
                      telephonyAdditionalNote = note;
                    "
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteTelephonyNotes(note)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="page11" v-if="page == 11">
        <h2>ADDITIONAL NOTES</h2>
        <p class="sub-heading">
          Are there any other items which we may need to be aware of to aid in a
          speedy, safe and smooth transition for the client and their team?
        </p>
        <div class="form">
          <div class="inputGroup additionalNotes">
            <label for="finalAdditionalNote">Notes</label>
            <input
              type="text"
              name="finalAdditionalNote"
              id="finalAdditionalNote"
              v-model="finalAdditionalNote"
            />
          </div>
        </div>
        <div class="submit-button">
          <button
            class="btn btn-primary"
            @click="updateFinalNotes(updateFinalAdditionalNote)"
            v-if="updateFinalAdditionalNote"
          >
            Update
          </button>
          <button class="btn btn-primary" @click="createFinalNotes" v-else>
            Add
          </button>
          <button
            class="btn btn-primary"
            @click="
              finalAdditionalNote = '';
              updateFinalAdditionalNote = '';
            "
          >
            Reset
          </button>
        </div>
        <div class="table-container" v-if="finalAdditionalNotes.length > 0">
          <table>
            <thead>
              <th>Notes</th>
              <th width="50px"></th>
              <th width="50px"></th>
            </thead>
            <tbody>
              <tr v-for="note in finalAdditionalNotes" :key="note">
                <td>{{ note }}</td>
                <td>
                  <ion-icon
                    name="create"
                    class="create"
                    @click="
                      updateFinalAdditionalNote = note;
                      finalAdditionalNote = note;
                    "
                  ></ion-icon>
                </td>
                <td>
                  <ion-icon
                    name="trash"
                    class="trash"
                    @click="deleteFinalNotes(note)"
                  ></ion-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="footer flex">
      <div class="button">
        <button
          class="btn btn-primary btn-block"
          v-if="page != 1"
          @click="back"
        >
          Back
        </button>
      </div>
      <div class="pagination">
        <div>
          {{ page }} -
          {{
            page == 1
              ? "Introduction"
              : page == 2
              ? "General Items"
              : page == 3
              ? "Networking & Devices"
              : page == 4
              ? "Internet & Web Services"
              : page == 5
              ? "Services"
              : page == 6
              ? "Credentials"
              : page == 7
              ? "Software"
              : page == 8
              ? "Backups"
              : page == 9
              ? "Computers"
              : page == 10
              ? "Telephony"
              : page == 11
              ? "Additional Notes"
              : ""
          }}
        </div>
        <div class="pagination-numbers">
          <span :class="{ 'active-page': page == 1 }" @click="loadPage(1)"
            >1</span
          >
          <span :class="{ 'active-page': page == 2 }" @click="loadPage(2)"
            >2</span
          >
          <span :class="{ 'active-page': page == 3 }" @click="loadPage(3)"
            >3</span
          >
          <span :class="{ 'active-page': page == 4 }" @click="loadPage(4)"
            >4</span
          >
          <span :class="{ 'active-page': page == 5 }" @click="loadPage(5)"
            >5</span
          >
          <span :class="{ 'active-page': page == 6 }" @click="loadPage(6)"
            >6</span
          >
          <span :class="{ 'active-page': page == 7 }" @click="loadPage(7)"
            >7</span
          >
          <span :class="{ 'active-page': page == 8 }" @click="loadPage(8)"
            >8</span
          >
          <span :class="{ 'active-page': page == 9 }" @click="loadPage(9)"
            >9</span
          >
          <span :class="{ 'active-page': page == 10 }" @click="loadPage(10)"
            >10</span
          >
          <span :class="{ 'active-page': page == 11 }" @click="loadPage(11)"
            >11</span
          >
        </div>
      </div>
      <div class="button">
        <button
          class="btn btn-primary btn-block"
          v-if="page != 11"
          @click="next"
        >
          Next
        </button>
        <button
          class="btn btn-primary btn-block"
          v-else
          @click="submitForm"
          :disabled="
            !(
              routers.length > 0 ||
              routerAdditionalNotes.length > 0 ||
              networkSwitches.length > 0 ||
              networkSwitchAdditionalNotes.length > 0 ||
              wirelessAccesses.length > 0 ||
              wirelessAccessAdditionalNotes.length > 0 ||
              wirelessNetworks.length > 0 ||
              remoteAccesses.length > 0 ||
              remoteAccessAdditionalNotes.length > 0 ||
              printers.length > 0 ||
              printerAdditionalNotes.length > 0 ||
              nases.length > 0 ||
              nasAdditionalNotes.length > 0 ||
              upses.length > 0 ||
              upsAdditionalNotes.length > 0 ||
              isps.length > 0 ||
              portals.length > 0 ||
              wans.length > 0 ||
              wanAdditionalNotes.length > 0 ||
              hostings.length > 0 ||
              webSiteAdditionalNotes.length > 0 ||
              ssls.length > 0 ||
              sslAdditionalNotes.length > 0 ||
              domains.length > 0 ||
              domainAdditionalNotes.length > 0 ||
              emails.length > 0 ||
              emailAdditionalNotes.length > 0 ||
              servers.length > 0 ||
              serverAdditionalNotes.length > 0 ||
              virtuals.length > 0 ||
              virtualAdditionalNotes.length > 0 ||
              logServers.length > 0 ||
              logServerAdditionalNotes.length > 0 ||
              domainCreds.length > 0 ||
              devices.length > 0 ||
              dsrmServers.length > 0 ||
              sqlServers.length > 0 ||
              clouds.length > 0 ||
              cloudAdditionalNotes.length > 0 ||
              softwares.length > 0 ||
              securityAdditionalNotes.length > 0 ||
              backups.length > 0 ||
              backupAdditionalNotes.length > 0 ||
              offSiteBackups.length > 0 ||
              offSiteBackupAdditionalNotes.length > 0 ||
              computers.length > 0 ||
              voips.length > 0 ||
              voipAdditionalNotes.length > 0 ||
              telephones.length > 0 ||
              telephonyAdditionalNotes.length > 0 ||
              finalAdditionalNotes.length > 0
            ) || loading
          "
        >
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span>Submit</span>
        </button>
        <button
          class="btn btn-primary btn-block"
          id="upto-here"
          v-if="page != 11 && page != 1 && page != 2"
          @click="submitForm"
          :disabled="
            !(
              routers.length > 0 ||
              routerAdditionalNotes.length > 0 ||
              networkSwitches.length > 0 ||
              networkSwitchAdditionalNotes.length > 0 ||
              wirelessAccesses.length > 0 ||
              wirelessAccessAdditionalNotes.length > 0 ||
              wirelessNetworks.length > 0 ||
              remoteAccesses.length > 0 ||
              remoteAccessAdditionalNotes.length > 0 ||
              printers.length > 0 ||
              printerAdditionalNotes.length > 0 ||
              nases.length > 0 ||
              nasAdditionalNotes.length > 0 ||
              upses.length > 0 ||
              upsAdditionalNotes.length > 0 ||
              isps.length > 0 ||
              portals.length > 0 ||
              wans.length > 0 ||
              wanAdditionalNotes.length > 0 ||
              hostings.length > 0 ||
              webSiteAdditionalNotes.length > 0 ||
              ssls.length > 0 ||
              sslAdditionalNotes.length > 0 ||
              domains.length > 0 ||
              domainAdditionalNotes.length > 0 ||
              emails.length > 0 ||
              emailAdditionalNotes.length > 0 ||
              servers.length > 0 ||
              serverAdditionalNotes.length > 0 ||
              virtuals.length > 0 ||
              virtualAdditionalNotes.length > 0 ||
              logServers.length > 0 ||
              logServerAdditionalNotes.length > 0 ||
              domainCreds.length > 0 ||
              devices.length > 0 ||
              dsrmServers.length > 0 ||
              sqlServers.length > 0 ||
              clouds.length > 0 ||
              cloudAdditionalNotes.length > 0 ||
              softwares.length > 0 ||
              securityAdditionalNotes.length > 0 ||
              backups.length > 0 ||
              backupAdditionalNotes.length > 0 ||
              offSiteBackups.length > 0 ||
              offSiteBackupAdditionalNotes.length > 0 ||
              computers.length > 0 ||
              voips.length > 0 ||
              voipAdditionalNotes.length > 0 ||
              telephones.length > 0 ||
              telephonyAdditionalNotes.length > 0 ||
              finalAdditionalNotes.length > 0
            ) || loading
          "
        >
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span>Submit up to here</span>
        </button>
      </div>
    </div>
    <div class="popup" v-if="successful">
      <div class="popup-inner">
        <img
          id="profile-img"
          src="https://www.fortify247.net/wp-content/uploads/2020/12/fortify247-header-logo-black.png"
          class="profile-img-card"
        />
        <p>
          Thank you for submitting the form! We have received your information
          and it has been successfully saved in our system. We appreciate your
          interest and value your input.
        </p>
        <center>
          <button class="btn btn-primary btn-block" @click="successful = false">
            Close
          </button>
        </center>
      </div>
    </div>
    <div class="popup-organization" v-if="organizationNA">
      <div class="popup-inner">
        <div class="header">
          <div class="company">
            <a href="/">
              <img
                id="profile-img"
                src="https://www.fortify247.net/wp-content/uploads/2020/12/fortify247-header-logo-black.png"
                class="profile-img-card"
            /></a>
          </div>
          <div class="contact-details">
            <a href="tel:01263805012">
              <ion-icon name="call"></ion-icon> 01263 805012
            </a>
            |
            <a href="mailto:info@fortify247.net">
              <ion-icon name="mail"></ion-icon> info@fortify247.net
            </a>
            <p>
              <ion-icon name="navigate"></ion-icon> 36 Cromer Road, West Runton
              NR27 9AD UK
            </p>
          </div>
        </div>
        <p>
          We are very sorry, seems your email is not saved in our system. Please
          contact us for to resolving this issue. We are looking forward for
          your response.
        </p>
        <center>
          <a
            href="https://www.fortify247.net/contact-us/"
            class="btn btn-primary btn-block"
          >
            Contact Us
          </a>
        </center>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ITTakeOver",
  data() {
    return {
      organizationNA: false,
      loading: false,
      message: "",
      page: 1,

      serviceRequestFiles: null,
      loadingServiceRequest: false,

      recoveryPlanFiles: null,
      loadingRecoveryPlan: false,

      companyPolicyFiles: null,
      loadingCompanyPolicy: false,

      warrantyFiles: null,
      loadingWarranty: false,

      networkDiagramFiles: null,
      loadingNetworkDiagram: false,

      userManualFiles: null,
      loadingUserManual: false,

      licenseFiles: null,
      loadingLicense: false,

      successful: false,

      email: this.$route.query.email,
      organizationID: "",
      organizationName: "",
      firstName: "",
      lastName: "",
      phone: "",
      locationID: "",
      locations: "",

      routerManufacturer: "",
      routerSerialNumber: "",
      routerLocation: "",
      routerAdminURL: "",
      routerAdminUsername: "",
      routerAdminPassword: "",
      updateRouterSerial: "",
      routerAdditionalNote: "",
      updateRouterAdditionalNote: "",
      routers: [],
      routerAdditionalNotes: [],

      networkSwitchManufacturer: "",
      networkSwitchSerialNumber: "",
      networkSwitchLocation: "",
      networkSwitchAdminURL: "",
      networkSwitchAdminUsername: "",
      networkSwitchAdminPassword: "",
      updateNetworkSwitchSerial: "",
      networkSwitchAdditionalNote: "",
      updateNetworkSwitchAdditionalNote: "",
      networkSwitches: [],
      networkSwitchAdditionalNotes: [],

      wirelessAccessManufacturer: "",
      wirelessAccessSerialNumber: "",
      wirelessAccessLocation: "",
      wirelessAccessAdminURL: "",
      wirelessAccessAdminUsername: "",
      wirelessAccessAdminPassword: "",
      updateWirelessAccessSerial: "",
      wirelessAccessAdditionalNote: "",
      updateWirelessAccessAdditionalNote: "",
      wirelessAccesses: [],
      wirelessAccessAdditionalNotes: [],

      wirelessNetworkIPAddress: "",
      wirelessNetworkSSID: "",
      wirelessNetworkKey: "",
      wirelessNetworkPurpose: "",
      updateWirelessNetworkIPAddress: "",
      wirelessNetworks: [],

      remoteAccessType: "",
      remoteAccessIPAddress: "",
      remoteAccessFirewall: "",
      remoteAccessPassphrase: "",
      remoteAccessPurpose: "",
      updateRemoteAccessIPAddress: "",
      remoteAccessAdditionalNote: "",
      updateRemoteAccessAdditionalNote: "",
      remoteAccesses: [],
      remoteAccessAdditionalNotes: [],

      printerManufacturer: "",
      printerSerialNumber: "",
      printerLocation: "",
      printerAdminURL: "",
      printerAdminUsername: "",
      printerAdminPassword: "",
      updatePrinterSerial: "",
      printerAdditionalNote: "",
      updatePrinterAdditionalNote: "",
      printers: [],
      printerAdditionalNotes: [],

      nasManufacturer: "",
      nasSerialNumber: "",
      nasLocation: "",
      nasWarrantyExpiry: "",
      nasServerName: "",
      nasPurpose: "",
      updateNasSerial: "",
      nasAdditionalNote: "",
      updateNasAdditionalNote: "",
      nases: [],
      nasAdditionalNotes: [],

      upsManufacturer: "",
      upsSerialNumber: "",
      upsLocation: "",
      upsIPAddress: "",
      upsUsername: "",
      upsPassword: "",
      updateUPSSerial: "",
      upsAdditionalNote: "",
      updateUPSAdditionalNote: "",
      upses: [],
      upsAdditionalNotes: [],

      ispVendor: "",
      ispAccountNumber: "",
      ispPrimaryContact: "",
      ispAgreementExpiry: "",
      updateISPSerial: "",
      isps: [],

      portalVendor: "",
      portalPortalURL: "",
      portalUsername: "",
      portalPassword: "",
      updatePortalURL: "",
      portals: [],

      wanVendor: "",
      wanIP: "",
      wanUsername: "",
      wanPassword: "",
      updateWanIP: "",
      wans: [],

      wanAdditionalNote: "",
      updateWanAdditionalNote: "",
      wanAdditionalNotes: [],

      webSiteAdditionalNote: "",
      updateWebSiteAdditionalNote: "",
      webSiteAdditionalNotes: [],

      hostingVendor: "",
      hostingDomain: "",
      cPanelUsername: "",
      cPanelPassword: "",
      cPanelURL: "",
      wordPressUsername: "",
      wordPressPassword: "",
      wordPressAdminURL: "",
      updateHostingDomain: "",
      hostings: [],

      sslIssuer: "",
      sslCertificateURL: "",
      sslWildcard: "",
      sslPurpose: "",
      sslExpiryDate: "",
      updateSSLCertificateURL: "",
      sslAdditionalNote: "",
      updateSSLAdditionalNote: "",
      ssls: [],
      sslAdditionalNotes: [],

      domainRegistrar: "",
      domainName: "",
      domainAdmin: "",
      domainUsername: "",
      domainPassword: "",
      updateDomainName: "",
      domainAdditionalNote: "",
      updateDomainAdditionalNote: "",
      domains: [],
      domainAdditionalNotes: [],

      emailVendor: "",
      emailAdminURL: "",
      emailUsername: "",
      emailPassword: "",
      updateEmailAdminURL: "",
      emailAdditionalNote: "",
      updateEmailAdditionalNote: "",
      emails: [],
      emailAdditionalNotes: [],

      serverManufacturer: "",
      serverSerialNumber: "",
      serverLocation: "",
      serverWarrantyExpiry: "",
      serverServerName: "",
      serverPurpose: "",
      updateServerSerial: "",
      serverAdditionalNote: "",
      updateServerAdditionalNote: "",
      servers: [],
      serverAdditionalNotes: [],

      virtualType: "",
      virtualAdminURL: "",
      virtualAdminUsername: "",
      virtualAdminPassword: "",
      updateVirtualURL: "",
      virtualAdditionalNote: "",
      updateVirtualAdditionalNote: "",
      virtuals: [],
      virtualAdditionalNotes: [],

      logServerName: "",
      logServerIP: "",
      logServerLocation: "",
      logServerSerial: "",
      logServerHost: "",
      logServerPurpose: "",
      updateLogServerIP: "",
      logServerAdditionalNote: "",
      updateLogServerAdditionalNote: "",
      logServers: [],
      logServerAdditionalNotes: [],

      domainCredName: "",
      domainCredUsername: "",
      domainCredPassword: "",
      updateCredName: "",
      domainCreds: [],

      deviceName: "",
      devicePurpose: "",
      deviceUsername: "",
      devicePassword: "",
      updateDeviceName: "",
      devices: [],

      dsrmServerName: "",
      dsrmServerPassword: "",
      updateDSRMName: "",
      dsrmServers: [],

      sqlServerName: "",
      sqlServerUsername: "",
      sqlServerPassword: "",
      updateSQLName: "",
      sqlServers: [],

      cloudAccountNumber: "",
      cloudVendor: "",
      cloudAdminURL: "",
      cloudUsername: "",
      cloudPassword: "",
      updateCloudVendor: "",
      cloudAdditionalNote: "",
      updateCloudAdditionalNote: "",
      clouds: [],
      cloudAdditionalNotes: [],

      securityAccountNumber: "",
      securityVendor: "",
      securityAdminURL: "",
      securityUsername: "",
      securityPassword: "",
      updateSecurityVendor: "",
      securityAdditionalNote: "",
      updateSecurityAdditionalNote: "",
      securities: [],
      securityAdditionalNotes: [],

      softwareVendor: "",
      softwareName: "",
      softwareContact: "",
      softwareSupport: "",
      softwarePurpose: "",
      updateSoftwareName: "",
      softwareAdditionalNote: "",
      updateSoftwareAdditionalNote: "",
      softwares: [],
      softwareAdditionalNotes: [],

      backupVendor: "",
      backupAdminURL: "",
      backupUsername: "",
      backupPassword: "",
      backupPurpose: "",
      updateBackupAdminURL: "",
      backupAdditionalNote: "",
      updateBackupAdditionalNote: "",
      backups: [],
      backupAdditionalNotes: [],

      offSiteBackupVendor: "",
      offSiteBackupAdminURL: "",
      offSiteBackupUsername: "",
      offSiteBackupPassword: "",
      offSiteBackupPurpose: "",
      updateOffSiteBackupAdminURL: "",
      offSiteBackupAdditionalNote: "",
      updateOffSiteBackupAdditionalNote: "",
      offSiteBackups: [],
      offSiteBackupAdditionalNotes: [],

      computerManufacturer: "",
      computerSerialNumber: "",
      computerLocation: "",
      computerWarrantyExpiry: "",
      computerPrimaryUser: "",
      computerDeviceName: "",
      updateComputerSerial: "",
      computers: [],

      voipManufacturer: "",
      voipSerialNumber: "",
      voipLocation: "",
      voipAdminURL: "",
      voipAdminUsername: "",
      voipAdminPassword: "",
      updateVOIPSerial: "",
      voipAdditionalNote: "",
      updateVOIPAdditionalNote: "",
      voips: [],
      voipAdditionalNotes: [],

      telephonyVendor: "",
      telephonyAccountNumber: "",
      telephonyContact: "",
      telephonySupport: "",
      telephonyPurpose: "",
      updateTelephonyAccountNumber: "",
      telephonyAdditionalNote: "",
      updateTelephonyAdditionalNote: "",
      telephones: [],
      telephonyAdditionalNotes: [],

      finalAdditionalNote: "",
      updateFinalAdditionalNote: "",
      finalAdditionalNotes: [],
    };
  },
  async mounted() {
    await this.getITGUserByEmail();
  },
  methods: {
    onServiceRequestFilesSelected(event) {
      this.serviceRequestFiles = event.target.files;
    },

    async uploadServiceRequestFiles() {
      this.loadingServiceRequest = true;
      let formData = new FormData();

      for (let i = 0; i < this.serviceRequestFiles.length; i++) {
        formData.append("files", this.serviceRequestFiles[i]);
      }

      formData.append("organizationID", this.organizationID);

      await axios
        .post("https://api.fortify247.net/upload/service-request", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.loadingServiceRequest = false;
          console.log(response);
        })
        .catch((error) => {
          this.loadingServiceRequest = false;
          console.log(error);
        });
    },

    onRecoveryPlanFilesSelected(event) {
      this.recoveryPlanFiles = event.target.files;
    },

    async uploadRecoveryPlanFiles() {
      this.loadingRecoveryPlan = true;
      let formData = new FormData();

      for (let i = 0; i < this.recoveryPlanFiles.length; i++) {
        formData.append("files", this.recoveryPlanFiles[i]);
      }

      formData.append("organizationID", this.organizationID);

      await axios
        .post("https://api.fortify247.net/upload/recovery-plan", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.loadingRecoveryPlan = false;
          console.log(response);
        })
        .catch((error) => {
          this.loadingRecoveryPlan = false;
          console.log(error);
        });
    },

    onCompanyPolicyFilesSelected(event) {
      this.companyPolicyFiles = event.target.files;
    },

    async uploadCompanyPolicyFiles() {
      this.loadingCompanyPolicy = true;
      let formData = new FormData();

      for (let i = 0; i < this.companyPolicyFiles.length; i++) {
        formData.append("files", this.companyPolicyFiles[i]);
      }

      formData.append("organizationID", this.organizationID);

      await axios
        .post("https://api.fortify247.net/upload/company-policy", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.loadingCompanyPolicy = false;
          console.log(response);
        })
        .catch((error) => {
          this.loadingCompanyPolicy = false;
          console.log(error);
        });
    },

    onWarrantyFilesSelected(event) {
      this.warrantyFiles = event.target.files;
    },

    async uploadWarrantyFiles() {
      this.loadingWarranty = true;
      let formData = new FormData();

      for (let i = 0; i < this.warrantyFiles.length; i++) {
        formData.append("files", this.warrantyFiles[i]);
      }

      formData.append("organizationID", this.organizationID);

      await axios
        .post("https://api.fortify247.net/upload/warranty", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.loadingWarranty = false;
          console.log(response);
        })
        .catch((error) => {
          this.loadingWarranty = false;
          console.log(error);
        });
    },

    onNetworkDiagramFilesSelected(event) {
      this.networkDiagramFiles = event.target.files;
    },

    async uploadNetworkDiagramFiles() {
      this.loadingNetworkDiagram = true;
      let formData = new FormData();

      for (let i = 0; i < this.networkDiagramFiles.length; i++) {
        formData.append("files", this.networkDiagramFiles[i]);
      }

      formData.append("organizationID", this.organizationID);

      await axios
        .post("https://api.fortify247.net/upload/network-diagram", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.loadingNetworkDiagram = false;
          console.log(response);
        })
        .catch((error) => {
          this.loadingNetworkDiagram = false;
          console.log(error);
        });
    },

    onUserManualFilesSelected(event) {
      this.userManualFiles = event.target.files;
    },

    async uploadUserManualFiles() {
      this.loadingUserManual = true;
      let formData = new FormData();

      for (let i = 0; i < this.userManualFiles.length; i++) {
        formData.append("files", this.userManualFiles[i]);
      }

      formData.append("organizationID", this.organizationID);

      await axios
        .post("https://api.fortify247.net/upload/user-manual", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.loadingUserManual = false;
          console.log(response);
        })
        .catch((error) => {
          this.loadingUserManual = false;
          console.log(error);
        });
    },

    onLicenseFilesSelected(event) {
      this.licenseFiles = event.target.files;
    },

    async uploadLicenseFiles() {
      this.loadingLicense = true;
      let formData = new FormData();

      for (let i = 0; i < this.licenseFiles.length; i++) {
        formData.append("files", this.licenseFiles[i]);
      }

      formData.append("organizationID", this.organizationID);

      await axios
        .post("https://api.fortify247.net/upload/license", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.loadingLicense = false;
          console.log(response);
        })
        .catch((error) => {
          this.loadingLicense = false;
          console.log(error);
        });
    },

    next() {
      this.page++;
    },

    back() {
      this.page--;
    },

    loadPage(page) {
      this.page = page;
    },

    async getITGUserByEmail() {
      await axios
        .get("https://api.fortify247.net/auth/get-itg-user/" + this.email)
        .then((response) => {
          if (response.data.organizationID) {
            this.organizationID = response.data.organizationID;
            this.organizationName = response.data.organizationName;
            this.firstName = response.data.firstName;
            this.lastName = response.data.lastName;
            this.phone = response.data.phone;
            this.locationID = response.data.locationID;
            this.locations = response.data.locations;
            this.routerLocation = this.locationID;
            this.networkSwitchLocation = this.locationID;
            this.wirelessAccessLocation = this.locationID;
            this.printerLocation = this.locationID;
            this.nasLocation = this.locationID;
            this.upsLocation = this.locationID;
            this.serverLocation = this.locationID;
            this.logServerLocation = this.locationID;
            this.computerLocation = this.locationID;
            this.voipLocation = this.locationID;
          } else {
            this.organizationNA = true;
          }
        })
        .catch(() => {
          this.organizationNA = true;
        });
    },

    resetFields() {
      this.page = 1;
      this.routers = [];
      this.routerAdditionalNotes = [];

      this.networkSwitches = [];
      this.networkSwitchAdditionalNotes = [];

      this.wirelessAccesses = [];
      this.wirelessAccessAdditionalNotes = [];

      this.wirelessNetworks = [];

      this.remoteAccesses = [];
      this.remoteAccessAdditionalNotes = [];

      this.printers = [];
      this.printerAdditionalNotes = [];

      this.nases = [];
      this.nasAdditionalNotes = [];

      this.upses = [];
      this.upsAdditionalNotes = [];

      this.isps = [];

      this.portals = [];

      this.wans = [];
      this.wanAdditionalNotes = [];

      this.hostings = [];
      this.webSiteAdditionalNotes = [];

      this.ssls = [];
      this.sslAdditionalNotes = [];

      this.domains = [];
      this.domainAdditionalNotes = [];

      this.emails = [];
      this.emailAdditionalNotes = [];

      this.servers = [];
      this.serverAdditionalNotes = [];

      this.virtuals = [];
      this.virtualAdditionalNotes = [];

      this.logServers = [];
      this.logServerAdditionalNotes = [];

      this.domainCreds = [];

      this.devices = [];

      this.dsrmServers = [];

      this.sqlServers = [];

      this.clouds = [];
      this.cloudAdditionalNotes = [];

      this.softwares = [];
      this.securityAdditionalNotes = [];

      this.backups = [];
      this.backupAdditionalNotes = [];

      this.offSiteBackups = [];
      this.offSiteBackupAdditionalNotes = [];

      this.computers = [];

      this.voips = [];
      this.voipAdditionalNotes = [];

      this.telephones = [];
      this.telephonyAdditionalNotes = [];

      this.finalAdditionalNotes = [];
    },

    async submitForm() {
      this.loading = true;

      await axios
        .post("https://api.fortify247.net/it-take-over", {
          organizationID: this.organizationID,

          routers: this.routers,
          routerAdditionalNotes: this.routerAdditionalNotes,

          networkSwitches: this.networkSwitches,
          networkSwitchAdditionalNotes: this.networkSwitchAdditionalNotes,

          wirelessAccesses: this.wirelessAccesses,
          wirelessAccessAdditionalNotes: this.wirelessAccessAdditionalNotes,

          wirelessNetworks: this.wirelessNetworks,

          remoteAccesses: this.remoteAccesses,
          remoteAccessAdditionalNotes: this.remoteAccessAdditionalNotes,

          printers: this.printers,
          printerAdditionalNotes: this.printerAdditionalNotes,

          nases: this.nases,
          nasAdditionalNotes: this.nasAdditionalNotes,

          upses: this.upses,
          upsAdditionalNotes: this.upsAdditionalNotes,

          isps: this.isps,

          portals: this.portals,

          wans: this.wans,
          wanAdditionalNotes: this.wanAdditionalNotes,

          hostings: this.hostings,
          hostingAdditionalNotes: this.webSiteAdditionalNotes,

          ssls: this.ssls,
          sslAdditionalNotes: this.sslAdditionalNotes,

          domains: this.domains,
          domainAdditionalNotes: this.domainAdditionalNotes,

          emails: this.emails,
          emailAdditionalNotes: this.emailAdditionalNotes,

          servers: this.servers,
          serverAdditionalNotes: this.serverAdditionalNotes,

          virtuals: this.virtuals,
          virtualAdditionalNotes: this.virtualAdditionalNotes,

          logServers: this.logServers,
          logServerAdditionalNotes: this.logServerAdditionalNotes,

          domainCreds: this.domainCreds,

          devices: this.devices,

          dsrmServers: this.dsrmServers,

          sqlServers: this.sqlServers,

          clouds: this.clouds,
          cloudAdditionalNotes: this.cloudAdditionalNotes,

          securities: this.securities,
          securityAdditionalNotes: this.securityAdditionalNotes,

          softwares: this.softwares,
          softwareAdditionalNotes: this.softwareAdditionalNotes,

          backups: this.backups,
          backupAdditionalNotes: this.backupAdditionalNotes,

          offSiteBackups: this.offSiteBackups,
          offSiteBackupAdditionalNotes: this.offSiteBackupAdditionalNotes,

          computers: this.computers,

          voips: this.voips,
          voipAdditionalNotes: this.voipAdditionalNotes,

          telephones: this.telephones,
          telephonyAdditionalNotes: this.telephonyAdditionalNotes,

          finalAdditionalNotes: this.finalAdditionalNotes,
        })
        .then(() => {
          this.loading = false;
          this.successful = true;
          this.resetFields();
        })
        .catch(() => {
          this.loading = false;
        });
      this.loading = false;
    },

    createRouters() {
      if (this.routerSerialNumber) {
        this.routers.push({
          manufacturer: this.routerManufacturer,
          serialNumber: this.routerSerialNumber,
          location: this.routerLocation,
          adminURL: this.routerAdminURL,
          adminUsername: this.routerAdminUsername,
          adminPassword: this.routerAdminPassword,
        });
        this.resetRouters();
      }
    },
    editRouters(router) {
      this.updateRouterSerial = router.serialNumber;
      this.routerManufacturer = router.manufacturer;
      this.routerSerialNumber = router.serialNumber;
      this.routerLocation = router.location;
      this.routerAdminURL = router.adminURL;
      this.routerAdminUsername = router.adminUsername;
      this.routerAdminPassword = router.adminPassword;
    },
    updateRouters(serialNumber) {
      let find = this.routers.findIndex(
        (router) => router.serialNumber == serialNumber
      );

      if (find > -1) {
        this.routers[find].manufacturer = this.routerManufacturer;
        this.routers[find].serialNumber = this.routerSerialNumber;
        this.routers[find].location = this.routerLocation;
        this.routers[find].adminURL = this.routerAdminURL;
        this.routers[find].adminUsername = this.routerAdminUsername;
        this.routers[find].adminPassword = this.routerAdminPassword;
        this.resetRouters();
      }
    },
    deleteRouters(serialNumber) {
      let find = this.routers.findIndex(
        (router) => router.serialNumber == serialNumber
      );

      if (find > -1) {
        this.routers.splice(find, 1);
        this.resetRouters();
      }
    },
    resetRouters() {
      this.routerManufacturer = "";
      this.routerSerialNumber = "";
      this.routerLocation = "";
      this.routerAdminURL = "";
      this.routerAdminUsername = "";
      this.routerAdminPassword = "";
      this.updateRouterSerial = "";
    },
    createRouterNotes() {
      if (this.routerAdditionalNote) {
        this.routerAdditionalNotes.push(this.routerAdditionalNote);
        this.routerAdditionalNote = "";
        this.updateRouterAdditionalNote = "";
      }
    },
    updateRouterNotes(note) {
      let find = this.routerAdditionalNotes.findIndex((notes) => notes == note);

      if (find > -1) {
        this.routerAdditionalNotes[find] = this.routerAdditionalNote;
        this.routerAdditionalNote = "";
        this.updateRouterAdditionalNote = "";
      }
    },
    deleteRouterNotes(note) {
      let find = this.routerAdditionalNotes.findIndex((notes) => notes == note);

      if (find > -1) {
        this.routerAdditionalNotes.splice(find, 1);
        this.routerAdditionalNote = "";
        this.updateRouterAdditionalNote = "";
      }
    },

    createNetworkSwitches() {
      if (this.networkSwitchSerialNumber) {
        this.networkSwitches.push({
          manufacturer: this.networkSwitchManufacturer,
          serialNumber: this.networkSwitchSerialNumber,
          location: this.networkSwitchLocation,
          adminURL: this.networkSwitchAdminURL,
          adminUsername: this.networkSwitchAdminUsername,
          adminPassword: this.networkSwitchAdminPassword,
        });
        this.resetNetworkSwitches();
      }
    },
    editNetworkSwitches(networkSwitch) {
      this.updateNetworkSwitchSerial = networkSwitch.serialNumber;
      this.networkSwitchManufacturer = networkSwitch.manufacturer;
      this.networkSwitchSerialNumber = networkSwitch.serialNumber;
      this.networkSwitchLocation = networkSwitch.location;
      this.networkSwitchAdminURL = networkSwitch.adminURL;
      this.networkSwitchAdminUsername = networkSwitch.adminUsername;
      this.networkSwitchAdminPassword = networkSwitch.adminPassword;
    },
    updateNetworkSwitches(serialNumber) {
      let find = this.networkSwitches.findIndex(
        (networkSwitch) => networkSwitch.serialNumber == serialNumber
      );

      if (find > -1) {
        this.networkSwitches[
          find
        ].manufacturer = this.networkSwitchManufacturer;
        this.networkSwitches[
          find
        ].serialNumber = this.networkSwitchSerialNumber;
        this.networkSwitches[find].location = this.networkSwitchLocation;
        this.networkSwitches[find].adminURL = this.networkSwitchAdminURL;
        this.networkSwitches[
          find
        ].adminUsername = this.networkSwitchAdminUsername;
        this.networkSwitches[
          find
        ].adminPassword = this.networkSwitchAdminPassword;
        this.resetNetworkSwitches();
      }
    },
    deleteNetworkSwitches(serialNumber) {
      let find = this.networkSwitches.findIndex(
        (networkSwitch) => networkSwitch.serialNumber == serialNumber
      );

      if (find > -1) {
        this.networkSwitches.splice(find, 1);
        this.resetNetworkSwitches();
      }
    },
    resetNetworkSwitches() {
      this.networkSwitchManufacturer = "";
      this.networkSwitchSerialNumber = "";
      this.networkSwitchLocation = "";
      this.networkSwitchAdminURL = "";
      this.networkSwitchAdminUsername = "";
      this.networkSwitchAdminPassword = "";
      this.updateNetworkSwitchSerial = "";
    },
    createNetworkSwitchNotes() {
      if (this.networkSwitchAdditionalNote) {
        this.networkSwitchAdditionalNotes.push(
          this.networkSwitchAdditionalNote
        );
        this.networkSwitchAdditionalNote = "";
        this.updateNetworkSwitchAdditionalNote = "";
      }
    },
    updateNetworkSwitchNotes(note) {
      let find = this.networkSwitchAdditionalNotes.findIndex(
        (notes) => notes == note
      );

      if (find > -1) {
        this.networkSwitchAdditionalNotes[
          find
        ] = this.networkSwitchAdditionalNote;
        this.networkSwitchAdditionalNote = "";
        this.updateNetworkSwitchAdditionalNote = "";
      }
    },
    deleteNetworkSwitchNotes(note) {
      let find = this.networkSwitchAdditionalNotes.findIndex(
        (notes) => notes == note
      );

      if (find > -1) {
        this.networkSwitchAdditionalNotes.splice(find, 1);
        this.networkSwitchAdditionalNote = "";
        this.updateNetworkSwitchAdditionalNote = "";
      }
    },

    createWirelessAccesses() {
      if (this.wirelessAccessSerialNumber) {
        this.wirelessAccesses.push({
          manufacturer: this.wirelessAccessManufacturer,
          serialNumber: this.wirelessAccessSerialNumber,
          location: this.wirelessAccessLocation,
          adminURL: this.wirelessAccessAdminURL,
          adminUsername: this.wirelessAccessAdminUsername,
          adminPassword: this.wirelessAccessAdminPassword,
        });
        this.resetWirelessAccesses();
      }
    },
    editWirelessAccesses(wirelessAccess) {
      this.updateWirelessAccessSerial = wirelessAccess.serialNumber;
      this.wirelessAccessManufacturer = wirelessAccess.manufacturer;
      this.wirelessAccessSerialNumber = wirelessAccess.serialNumber;
      this.wirelessAccessLocation = wirelessAccess.location;
      this.wirelessAccessAdminURL = wirelessAccess.adminURL;
      this.wirelessAccessAdminUsername = wirelessAccess.adminUsername;
      this.wirelessAccessAdminPassword = wirelessAccess.adminPassword;
    },
    updateWirelessAccesses(serialNumber) {
      let find = this.wirelessAccesses.findIndex(
        (wirelessAccess) => wirelessAccess.serialNumber == serialNumber
      );

      if (find > -1) {
        this.wirelessAccesses[
          find
        ].manufacturer = this.wirelessAccessManufacturer;
        this.wirelessAccesses[
          find
        ].serialNumber = this.wirelessAccessSerialNumber;
        this.wirelessAccesses[find].location = this.wirelessAccessLocation;
        this.wirelessAccesses[find].adminURL = this.wirelessAccessAdminURL;
        this.wirelessAccesses[
          find
        ].adminUsername = this.wirelessAccessAdminUsername;
        this.wirelessAccesses[
          find
        ].adminPassword = this.wirelessAccessAdminPassword;
        this.resetWirelessAccesses();
      }
    },
    deleteWirelessAccesses(serialNumber) {
      let find = this.wirelessAccesses.findIndex(
        (wirelessAccess) => wirelessAccess.serialNumber == serialNumber
      );

      if (find > -1) {
        this.wirelessAccesses.splice(find, 1);
        this.resetWirelessAccesses();
      }
    },
    resetWirelessAccesses() {
      this.wirelessAccessManufacturer = "";
      this.wirelessAccessSerialNumber = "";
      this.wirelessAccessLocation = "";
      this.wirelessAccessAdminURL = "";
      this.wirelessAccessAdminUsername = "";
      this.wirelessAccessAdminPassword = "";
      this.updateWirelessAccessSerial = "";
    },
    createWirelessAccessNotes() {
      if (this.wirelessAccessAdditionalNote) {
        this.wirelessAccessAdditionalNotes.push(
          this.wirelessAccessAdditionalNote
        );
        this.wirelessAccessAdditionalNote = "";
        this.updateWirelessAccessAdditionalNote = "";
      }
    },
    updateWirelessAccessNotes(note) {
      let find = this.wirelessAccessAdditionalNotes.findIndex(
        (notes) => notes == note
      );

      if (find > -1) {
        this.wirelessAccessAdditionalNotes[
          find
        ] = this.wirelessAccessAdditionalNote;
        this.wirelessAccessAdditionalNote = "";
        this.updateWirelessAccessAdditionalNote = "";
      }
    },
    deleteWirelessAccessNotes(note) {
      let find = this.wirelessAccessAdditionalNotes.findIndex(
        (notes) => notes == note
      );

      if (find > -1) {
        this.wirelessAccessAdditionalNotes.splice(find, 1);
        this.wirelessAccessAdditionalNote = "";
        this.updateWirelessAccessAdditionalNote = "";
      }
    },

    createWirelessNetworks() {
      if (this.wirelessNetworkIPAddress) {
        this.wirelessNetworks.push({
          ipAddress: this.wirelessNetworkIPAddress,
          ssID: this.wirelessNetworkSSID,
          key: this.wirelessNetworkKey,
          purpose: this.wirelessNetworkPurpose,
        });
        this.resetWirelessNetworks();
      }
    },
    editWirelessNetworks(wirelessNetwork) {
      this.updateWirelessNetworkIPAddress = wirelessNetwork.ipAddress;
      this.wirelessNetworkIPAddress = wirelessNetwork.ipAddress;
      this.wirelessNetworkSSID = wirelessNetwork.ssID;
      this.wirelessNetworkKey = wirelessNetwork.key;
      this.wirelessNetworkPurpose = wirelessNetwork.purpose;
    },
    updateWirelessNetworks(ipAddress) {
      let find = this.wirelessNetworks.findIndex(
        (wirelessNetwork) => wirelessNetwork.ipAddress == ipAddress
      );

      if (find > -1) {
        this.wirelessNetworks[find].ipAddress = this.wirelessNetworkIPAddress;
        this.wirelessNetworks[find].ssID = this.wirelessNetworkSSID;
        this.wirelessNetworks[find].key = this.wirelessNetworkKey;
        this.wirelessNetworks[find].purpose = this.wirelessNetworkPurpose;
        this.resetWirelessNetworks();
      }
    },
    deleteWirelessNetworks(ipAddress) {
      let find = this.wirelessNetworks.findIndex(
        (wirelessNetwork) => wirelessNetwork.ipAddress == ipAddress
      );

      if (find > -1) {
        this.wirelessNetworks.splice(find, 1);
        this.resetWirelessNetworks();
      }
    },
    resetWirelessNetworks() {
      this.wirelessNetworkIPAddress = "";
      this.wirelessNetworkSSID = "";
      this.wirelessNetworkKey = "";
      this.wirelessNetworkPurpose = "";
      this.updateWirelessNetworkIPAddress = "";
    },

    createRemoteAccesses() {
      if (this.remoteAccessIPAddress) {
        this.remoteAccesses.push({
          type: this.remoteAccessType,
          ipAddress: this.remoteAccessIPAddress,
          firewall: this.remoteAccessFirewall,
          passphrase: this.remoteAccessPassphrase,
          purpose: this.remoteAccessPurpose,
        });
        this.resetRemoteAccesses();
      }
    },
    editRemoteAccesses(remoteAccess) {
      this.updateRemoteAccessIPAddress = remoteAccess.ipAddress;
      this.remoteAccessType = remoteAccess.type;
      this.remoteAccessIPAddress = remoteAccess.ipAddress;
      this.remoteAccessFirewall = remoteAccess.firewall;
      this.remoteAccessPassphrase = remoteAccess.passphrase;
      this.remoteAccessPurpose = remoteAccess.purpose;
    },
    updateRemoteAccesses(ipAddress) {
      let find = this.remoteAccesses.findIndex(
        (remoteAccess) => remoteAccess.ipAddress == ipAddress
      );

      if (find > -1) {
        this.remoteAccesses[find].type = this.remoteAccessType;
        this.remoteAccesses[find].ipAddress = this.remoteAccessIPAddress;
        this.remoteAccesses[find].firewall = this.remoteAccessFirewall;
        this.remoteAccesses[find].passphrase = this.remoteAccessPassphrase;
        this.remoteAccesses[find].purpose = this.remoteAccessPurpose;
        this.resetRemoteAccesses();
      }
    },
    deleteRemoteAccesses(ipAddress) {
      let find = this.remoteAccesses.findIndex(
        (remoteAccess) => remoteAccess.ipAddress == ipAddress
      );

      if (find > -1) {
        this.remoteAccesses.splice(find, 1);
        this.resetRemoteAccesses();
      }
    },
    resetRemoteAccesses() {
      this.remoteAccessType = "";
      this.remoteAccessIPAddress = "";
      this.remoteAccessFirewall = "";
      this.remoteAccessPassphrase = "";
      this.remoteAccessPurpose = "";
      this.updateRemoteAccessIPAddress = "";
    },
    createRemoteAccessNotes() {
      if (this.remoteAccessAdditionalNote) {
        this.remoteAccessAdditionalNotes.push(this.remoteAccessAdditionalNote);
        this.remoteAccessAdditionalNote = "";
        this.updateRemoteAccessAdditionalNote = "";
      }
    },
    updateRemoteAccessNotes(note) {
      let find = this.remoteAccessAdditionalNotes.findIndex(
        (notes) => notes == note
      );

      if (find > -1) {
        this.remoteAccessAdditionalNotes[
          find
        ] = this.remoteAccessAdditionalNote;
        this.remoteAccessAdditionalNote = "";
        this.updateRemoteAccessAdditionalNote = "";
      }
    },
    deleteRemoteAccessNotes(note) {
      let find = this.remoteAccessAdditionalNotes.findIndex(
        (notes) => notes == note
      );

      if (find > -1) {
        this.remoteAccessAdditionalNotes.splice(find, 1);
        this.remoteAccessAdditionalNote = "";
        this.updateRemoteAccessAdditionalNote = "";
      }
    },

    createPrinters() {
      if (this.printerSerialNumber) {
        this.printers.push({
          manufacturer: this.printerManufacturer,
          serialNumber: this.printerSerialNumber,
          location: this.printerLocation,
          adminURL: this.printerAdminURL,
          adminUsername: this.printerAdminUsername,
          adminPassword: this.printerAdminPassword,
        });
        this.resetPrinters();
      }
    },
    editPrinters(printer) {
      this.updatePrinterSerial = printer.serialNumber;
      this.printerManufacturer = printer.manufacturer;
      this.printerSerialNumber = printer.serialNumber;
      this.printerLocation = printer.location;
      this.printerAdminURL = printer.adminURL;
      this.printerAdminUsername = printer.adminUsername;
      this.printerAdminPassword = printer.adminPassword;
    },
    updatePrinters(serialNumber) {
      let find = this.printers.findIndex(
        (printer) => printer.serialNumber == serialNumber
      );

      if (find > -1) {
        this.printers[find].manufacturer = this.printerManufacturer;
        this.printers[find].serialNumber = this.printerSerialNumber;
        this.printers[find].location = this.printerLocation;
        this.printers[find].adminURL = this.printerAdminURL;
        this.printers[find].adminUsername = this.printerAdminUsername;
        this.printers[find].adminPassword = this.printerAdminPassword;
        this.resetPrinters();
      }
    },
    deletePrinters(serialNumber) {
      let find = this.printers.findIndex(
        (printer) => printer.serialNumber == serialNumber
      );

      if (find > -1) {
        this.printers.splice(find, 1);
        this.resetPrinters();
      }
    },
    resetPrinters() {
      this.printerManufacturer = "";
      this.printerSerialNumber = "";
      this.printerLocation = "";
      this.printerAdminURL = "";
      this.printerAdminUsername = "";
      this.printerAdminPassword = "";
      this.updatePrinterSerial = "";
    },
    createPrinterNotes() {
      if (this.printerAdditionalNote) {
        this.printerAdditionalNotes.push(this.printerAdditionalNote);
        this.printerAdditionalNote = "";
        this.updatePrinterAdditionalNote = "";
      }
    },
    updatePrinterNotes(note) {
      let find = this.printerAdditionalNotes.findIndex(
        (notes) => notes == note
      );

      if (find > -1) {
        this.printerAdditionalNotes[find] = this.printerAdditionalNote;
        this.printerAdditionalNote = "";
        this.updatePrinterAdditionalNote = "";
      }
    },
    deletePrinterNotes(note) {
      let find = this.printerAdditionalNotes.findIndex(
        (notes) => notes == note
      );

      if (find > -1) {
        this.printerAdditionalNotes.splice(find, 1);
        this.printerAdditionalNote = "";
        this.updatePrinterAdditionalNote = "";
      }
    },

    createNases() {
      if (this.nasSerialNumber) {
        this.nases.push({
          manufacturer: this.nasManufacturer,
          serialNumber: this.nasSerialNumber,
          location: this.nasLocation,
          warranty: this.nasWarrantyExpiry,
          sever: this.nasServerName,
          purpose: this.nasPurpose,
        });
        console.log(this.nasWarrantyExpiry);
        this.resetNases();
      }
    },
    editNases(nas) {
      this.updateNasSerial = nas.serialNumber;
      this.nasManufacturer = nas.manufacturer;
      this.nasSerialNumber = nas.serialNumber;
      this.nasLocation = nas.location;
      this.nasWarrantyExpiry = nas.warranty;
      this.nasServerName = nas.sever;
      this.nasPurpose = nas.purpose;
    },
    updateNases(serialNumber) {
      let find = this.nases.findIndex(
        (nas) => nas.serialNumber == serialNumber
      );

      if (find > -1) {
        this.nases[find].manufacturer = this.nasManufacturer;
        this.nases[find].serialNumber = this.nasSerialNumber;
        this.nases[find].location = this.nasLocation;
        this.nases[find].warranty = this.nasWarrantyExpiry;
        this.nases[find].sever = this.nasServerName;
        this.nases[find].purpose = this.nasPurpose;
        this.resetNases();
      }
    },
    deleteNases(serialNumber) {
      let find = this.nases.findIndex(
        (nas) => nas.serialNumber == serialNumber
      );

      if (find > -1) {
        this.nases.splice(find, 1);
        this.resetNases();
      }
    },
    resetNases() {
      this.nasManufacturer = "";
      this.nasSerialNumber = "";
      this.nasLocation = "";
      this.nasWarrantyExpiry = "";
      this.nasServerName = "";
      this.nasPurpose = "";
      this.updateNasSerial = "";
    },
    createNasNotes() {
      if (this.nasAdditionalNote) {
        this.nasAdditionalNotes.push(this.nasAdditionalNote);
        this.nasAdditionalNote = "";
        this.updateNasAdditionalNote = "";
      }
    },
    updateNasNotes(note) {
      let find = this.nasAdditionalNotes.findIndex((notes) => notes == note);

      if (find > -1) {
        this.nasAdditionalNotes[find] = this.nasAdditionalNote;
        this.nasAdditionalNote = "";
        this.updateNasAdditionalNote = "";
      }
    },
    deleteNasNotes(note) {
      let find = this.nasAdditionalNotes.findIndex((notes) => notes == note);

      if (find > -1) {
        this.nasAdditionalNotes.splice(find, 1);
        this.nasAdditionalNote = "";
        this.updateNasAdditionalNote = "";
      }
    },

    createUPSes() {
      if (this.upsSerialNumber) {
        this.upses.push({
          manufacturer: this.upsManufacturer,
          serialNumber: this.upsSerialNumber,
          location: this.upsLocation,
          ipAddress: this.upsIPAddress,
          username: this.upsUsername,
          password: this.upsPassword,
        });
        this.resetUPSes();
      }
    },
    editUPSes(ups) {
      this.updateUPSSerial = ups.serialNumber;
      this.upsManufacturer = ups.manufacturer;
      this.upsSerialNumber = ups.serialNumber;
      this.upsLocation = ups.location;
      this.upsIPAddress = ups.ipAddress;
      this.upsUsername = ups.username;
      this.upsPassword = ups.password;
    },
    updateUPSes(serialNumber) {
      let find = this.upses.findIndex(
        (ups) => ups.serialNumber == serialNumber
      );

      if (find > -1) {
        this.upses[find].manufacturer = this.upsManufacturer;
        this.upses[find].serialNumber = this.upsSerialNumber;
        this.upses[find].location = this.upsLocation;
        this.upses[find].ipAddress = this.upsIPAddress;
        this.upses[find].username = this.upsUsername;
        this.upses[find].password = this.upsPassword;
        this.resetUPSes();
      }
    },
    deleteUPSes(serialNumber) {
      let find = this.upses.findIndex(
        (ups) => ups.serialNumber == serialNumber
      );

      if (find > -1) {
        this.upses.splice(find, 1);
        this.resetUPSes();
      }
    },
    resetUPSes() {
      this.upsManufacturer = "";
      this.upsSerialNumber = "";
      this.upsLocation = "";
      this.upsIPAddress = "";
      this.upsUsername = "";
      this.upsPassword = "";
      this.updateUPSSerial = "";
    },
    createUPSNotes() {
      if (this.upsAdditionalNote) {
        this.upsAdditionalNotes.push(this.upsAdditionalNote);
        this.upsAdditionalNote = "";
        this.updateUPSAdditionalNote = "";
      }
    },
    updateUPSNotes(note) {
      let find = this.upsAdditionalNotes.findIndex((notes) => notes == note);

      if (find > -1) {
        this.upsAdditionalNotes[find] = this.upsAdditionalNote;
        this.upsAdditionalNote = "";
        this.updateUPSAdditionalNote = "";
      }
    },
    deleteUPSNotes(note) {
      let find = this.upsAdditionalNotes.findIndex((notes) => notes == note);

      if (find > -1) {
        this.upsAdditionalNotes.splice(find, 1);
        this.upsAdditionalNote = "";
        this.updateUPSAdditionalNote = "";
      }
    },

    createISPs() {
      if (this.ispAccountNumber) {
        this.isps.push({
          vendor: this.ispVendor,
          accountNumber: this.ispAccountNumber,
          primaryContact: this.ispPrimaryContact,
          agreementExpiry: this.ispAgreementExpiry,
        });
        this.resetISPs();
      }
    },
    editISPs(isp) {
      this.updateISPSerial = isp.accountNumber;
      this.ispVendor = isp.vendor;
      this.ispAccountNumber = isp.accountNumber;
      this.ispPrimaryContact = isp.primaryContact;
      this.ispAgreementExpiry = isp.agreementExpiry;
    },
    updateISPs(accountNumber) {
      let find = this.isps.findIndex(
        (isp) => isp.accountNumber == accountNumber
      );

      if (find > -1) {
        this.isps[find].vendor = this.ispVendor;
        this.isps[find].accountNumber = this.ispAccountNumber;
        this.isps[find].primaryContact = this.ispPrimaryContact;
        this.isps[find].agreementExpiry = this.ispAgreementExpiry;
        this.resetISPs();
      }
    },
    deleteISPs(accountNumber) {
      let find = this.isps.findIndex(
        (isp) => isp.accountNumber == accountNumber
      );

      if (find > -1) {
        this.isps.splice(find, 1);
        this.resetISPs();
      }
    },
    resetISPs() {
      this.ispVendor = "";
      this.ispAccountNumber = "";
      this.ispPrimaryContact = "";
      this.ispAgreementExpiry = "";
      this.updateISPSerial = "";
    },

    createPortals() {
      if (this.portalPortalURL) {
        this.portals.push({
          vendor: this.portalVendor,
          portalURL: this.portalPortalURL,
          username: this.portalUsername,
          password: this.portalPassword,
        });
        this.resetPortals();
      }
    },
    editPortals(portal) {
      this.updatePortalURL = portal.portalURL;
      this.portalVendor = portal.vendor;
      this.portalPortalURL = portal.portalURL;
      this.portalUsername = portal.username;
      this.portalPassword = portal.password;
    },
    updatePortals(portalURL) {
      let find = this.portals.findIndex(
        (portal) => portal.portalURL == portalURL
      );

      if (find > -1) {
        this.portals[find].vendor = this.portalVendor;
        this.portals[find].portalURL = this.portalPortalURL;
        this.portals[find].username = this.portalUsername;
        this.portals[find].password = this.portalPassword;
        this.resetPortals();
      }
    },
    deletePortals(portalURL) {
      let find = this.portals.findIndex(
        (portal) => portal.portalURL == portalURL
      );

      if (find > -1) {
        this.portals.splice(find, 1);
        this.resetPortals();
      }
    },
    resetPortals() {
      this.portalVendor = "";
      this.portalPortalURL = "";
      this.portalUsername = "";
      this.portalPassword = "";
      this.updatePortalURL = "";
    },

    createWans() {
      if (this.wanIP) {
        this.wans.push({
          vendor: this.wanVendor,
          wanIP: this.wanIP,
          username: this.wanUsername,
          password: this.wanPassword,
        });
        this.resetWans();
      }
    },
    editWans(wan) {
      this.updateWanIP = wan.wanIP;
      this.wanVendor = wan.vendor;
      this.wanIP = wan.wanIP;
      this.wanUsername = wan.username;
      this.wanPassword = wan.password;
    },
    updateWans(wanIP) {
      let find = this.wans.findIndex((wan) => wan.wanIP == wanIP);

      if (find > -1) {
        this.wans[find].vendor = this.wanVendor;
        this.wans[find].wanIP = this.wanIP;
        this.wans[find].username = this.wanUsername;
        this.wans[find].password = this.wanPassword;
        this.resetWans();
      }
    },
    deleteWans(wanIP) {
      let find = this.wans.findIndex((wan) => wan.wanIP == wanIP);

      if (find > -1) {
        this.wans.splice(find, 1);
        this.resetWans();
      }
    },
    resetWans() {
      this.wanVendor = "";
      this.wanIP = "";
      this.wanUsername = "";
      this.wanPassword = "";
      this.updateWanIP = "";
    },

    createWanNotes() {
      if (this.wanAdditionalNote) {
        this.wanAdditionalNotes.push(this.wanAdditionalNote);
        this.wanAdditionalNote = "";
        this.updateWanAdditionalNote = "";
      }
    },
    updateWanNotes(note) {
      let find = this.wanAdditionalNotes.findIndex((notes) => notes == note);

      if (find > -1) {
        this.wanAdditionalNotes[find] = this.wanAdditionalNote;
        this.wanAdditionalNote = "";
        this.updateWanAdditionalNote = "";
      }
    },
    deleteWanNotes(note) {
      let find = this.wanAdditionalNotes.findIndex((notes) => notes == note);

      if (find > -1) {
        this.wanAdditionalNotes.splice(find, 1);
        this.wanAdditionalNote = "";
        this.updateWanAdditionalNote = "";
      }
    },

    createWebSiteNotes() {
      if (this.webSiteAdditionalNote) {
        this.webSiteAdditionalNotes.push(this.webSiteAdditionalNote);
        this.webSiteAdditionalNote = "";
        this.updateWebSiteAdditionalNote = "";
      }
    },
    updateWebSiteNotes(note) {
      let find = this.webSiteAdditionalNotes.findIndex(
        (notes) => notes == note
      );

      if (find > -1) {
        this.webSiteAdditionalNotes[find] = this.webSiteAdditionalNote;
        this.webSiteAdditionalNote = "";
        this.updateWebSiteAdditionalNote = "";
      }
    },
    deleteWebSiteNotes(note) {
      let find = this.webSiteAdditionalNotes.findIndex(
        (notes) => notes == note
      );

      if (find > -1) {
        this.webSiteAdditionalNotes.splice(find, 1);
        this.webSiteAdditionalNote = "";
        this.updateWebSiteAdditionalNote = "";
      }
    },

    createHostings() {
      if (this.hostingDomain) {
        this.hostings.push({
          vendor: this.hostingVendor,
          domain: this.hostingDomain,
          cPanelUsername: this.cPanelUsername,
          cPanelPassword: this.cPanelPassword,
          cPanelURL: this.cPanelURL,
          wordPressUsername: this.wordPressUsername,
          wordPressPassword: this.wordPressPassword,
          wordPressAdminURL: this.wordPressAdminURL,
        });
        this.resetHostings();
      }
    },
    editHostings(hosting) {
      this.updateHostingDomain = hosting.domain;
      this.hostingVendor = hosting.vendor;
      this.hostingDomain = hosting.domain;
      this.cPanelUsername = hosting.cPanelUsername;
      this.cPanelPassword = hosting.cPanelPassword;
      this.cPanelURL = hosting.cPanelURL;
      this.wordPressUsername = hosting.wordPressUsername;
      this.wordPressPassword = hosting.wordPressPassword;
      this.wordPressAdminURL = hosting.wordPressAdminURL;
    },
    updateHostings(hostingDomain) {
      let find = this.hostings.findIndex(
        (hosting) => hosting.domain == hostingDomain
      );

      if (find > -1) {
        this.hostings[find].vendor = this.hostingVendor;
        this.hostings[find].domain = this.hostingDomain;
        this.hostings[find].cPanelUsername = this.cPanelUsername;
        this.hostings[find].cPanelPassword = this.cPanelPassword;
        this.hostings[find].cPanelURL = this.cPanelURL;
        this.hostings[find].wordPressUsername = this.wordPressUsername;
        this.hostings[find].wordPressPassword = this.wordPressPassword;
        this.hostings[find].wordPressAdminURL = this.wordPressAdminURL;
        this.resetHostings();
      }
    },
    deleteHostings(hostingDomain) {
      let find = this.hostings.findIndex(
        (hosting) => hosting.domain == hostingDomain
      );

      if (find > -1) {
        this.hostings.splice(find, 1);
        this.resetHostings();
      }
    },
    resetHostings() {
      this.hostingVendor = "";
      this.hostingDomain = "";
      this.cPanelUsername = "";
      this.cPanelPassword = "";
      this.cPanelURL = "";
      this.wordPressUsername = "";
      this.wordPressPassword = "";
      this.wordPressAdminURL = "";
      this.updateHostingDomain = "";
    },

    createSSLs() {
      if (this.sslCertificateURL) {
        this.ssls.push({
          issuer: this.sslIssuer,
          certificateURL: this.sslCertificateURL,
          wildcard: this.sslWildcard,
          expiryDate: this.sslPurpose,
          purpose: this.sslExpiryDate,
        });
        this.resetSSLs();
      }
    },
    editSSLs(ssl) {
      this.updateSSLCertificateURL = ssl.certificateURL;
      this.sslIssuer = ssl.issuer;
      this.sslCertificateURL = ssl.certificateURL;
      this.sslWildcard = ssl.wildcard;
      this.sslPurpose = ssl.expiryDate;
      this.sslExpiryDate = ssl.purpose;
    },
    updateSSLs(certificateURL) {
      let find = this.ssls.findIndex(
        (ssl) => ssl.certificateURL == certificateURL
      );

      if (find > -1) {
        this.ssls[find].issuer = this.sslIssuer;
        this.ssls[find].certificateURL = this.sslCertificateURL;
        this.ssls[find].wildcard = this.sslWildcard;
        this.ssls[find].expiryDate = this.sslPurpose;
        this.ssls[find].purpose = this.sslExpiryDate;
        this.resetSSLs();
      }
    },
    deleteSSLs(certificateURL) {
      let find = this.ssls.findIndex(
        (ssl) => ssl.certificateURL == certificateURL
      );

      if (find > -1) {
        this.ssls.splice(find, 1);
        this.resetSSLs();
      }
    },
    resetSSLs() {
      this.sslIssuer = "";
      this.sslCertificateURL = "";
      this.sslWildcard = "";
      this.sslPurpose = "";
      this.sslExpiryDate = "";
      this.updateSSLCertificateURL = "";
    },
    createSSLNotes() {
      if (this.sslAdditionalNote) {
        this.sslAdditionalNotes.push(this.sslAdditionalNote);
        this.sslAdditionalNote = "";
        this.updateSSLAdditionalNote = "";
      }
    },
    updateSSLNotes(note) {
      let find = this.sslAdditionalNotes.findIndex((notes) => notes == note);

      if (find > -1) {
        this.sslAdditionalNotes[find] = this.sslAdditionalNote;
        this.sslAdditionalNote = "";
        this.updateSSLAdditionalNote = "";
      }
    },
    deleteSSLNotes(note) {
      let find = this.sslAdditionalNotes.findIndex((notes) => notes == note);

      if (find > -1) {
        this.sslAdditionalNotes.splice(find, 1);
        this.sslAdditionalNote = "";
        this.updateSSLAdditionalNote = "";
      }
    },

    createDomains() {
      if (this.domainName) {
        this.domains.push({
          certificateURL: this.domainName,
          issuer: this.domainRegistrar,
          wildcard: this.domainAdmin,
          expiryDate: this.domainUsername,
          purpose: this.domainPassword,
        });
        this.resetDomains();
      }
    },
    editDomains(domain) {
      this.updateDomainName = domain.certificateURL;
      this.domainRegistrar = domain.issuer;
      this.domainName = domain.certificateURL;
      this.domainAdmin = domain.wildcard;
      this.domainUsername = domain.expiryDate;
      this.domainPassword = domain.purpose;
    },
    updateDomains(certificateURL) {
      let find = this.domains.findIndex(
        (domain) => domain.certificateURL == certificateURL
      );

      if (find > -1) {
        this.domains[find].issuer = this.domainRegistrar;
        this.domains[find].certificateURL = this.domainName;
        this.domains[find].wildcard = this.domainAdmin;
        this.domains[find].expiryDate = this.domainUsername;
        this.domains[find].purpose = this.domainPassword;
        this.resetDomains();
      }
    },
    deleteDomains(certificateURL) {
      let find = this.domains.findIndex(
        (domain) => domain.certificateURL == certificateURL
      );

      if (find > -1) {
        this.domains.splice(find, 1);
        this.resetDomains();
      }
    },
    resetDomains() {
      this.domainRegistrar = "";
      this.domainName = "";
      this.domainAdmin = "";
      this.domainUsername = "";
      this.domainPassword = "";
      this.updateDomainName = "";
    },
    createDomainNotes() {
      if (this.domainAdditionalNote) {
        this.domainAdditionalNotes.push(this.domainAdditionalNote);
        this.domainAdditionalNote = "";
        this.updateDomainAdditionalNote = "";
      }
    },
    updateDomainNotes(note) {
      let find = this.domainAdditionalNotes.findIndex((notes) => notes == note);

      if (find > -1) {
        this.domainAdditionalNotes[find] = this.domainAdditionalNote;
        this.domainAdditionalNote = "";
        this.updateDomainAdditionalNote = "";
      }
    },
    deleteDomainNotes(note) {
      let find = this.domainAdditionalNotes.findIndex((notes) => notes == note);

      if (find > -1) {
        this.domainAdditionalNotes.splice(find, 1);
        this.domainAdditionalNote = "";
        this.updateDomainAdditionalNote = "";
      }
    },

    createEmails() {
      if (this.emailAdminURL) {
        this.emails.push({
          vendor: this.emailVendor,
          adminURL: this.emailAdminURL,
          username: this.emailUsername,
          password: this.emailPassword,
        });
        this.resetEmails();
      }
    },
    editEmails(email) {
      this.updateEmailAdminURL = email.adminURL;
      this.emailVendor = email.vendor;
      this.emailAdminURL = email.adminURL;
      this.emailUsername = email.username;
      this.emailPassword = email.password;
    },
    updateEmails(adminURL) {
      let find = this.emails.findIndex((email) => email.adminURL == adminURL);

      if (find > -1) {
        this.emails[find].vendor = this.emailVendor;
        this.emails[find].adminURL = this.emailAdminURL;
        this.emails[find].username = this.emailUsername;
        this.emails[find].password = this.emailPassword;
        this.resetEmails();
      }
    },
    deleteEmails(adminURL) {
      let find = this.emails.findIndex((email) => email.adminURL == adminURL);

      if (find > -1) {
        this.emails.splice(find, 1);
        this.resetEmails();
      }
    },
    resetEmails() {
      this.emailVendor = "";
      this.emailAdminURL = "";
      this.emailUsername = "";
      this.emailPassword = "";
      this.updateEmailAdminURL = "";
    },
    createEmailNotes() {
      if (this.emailAdditionalNote) {
        this.emailAdditionalNotes.push(this.emailAdditionalNote);
        this.emailAdditionalNote = "";
        this.updateEmailAdditionalNote = "";
      }
    },
    updateEmailNotes(note) {
      let find = this.emailAdditionalNotes.findIndex((notes) => notes == note);

      if (find > -1) {
        this.emailAdditionalNotes[find] = this.emailAdditionalNote;
        this.emailAdditionalNote = "";
        this.updateEmailAdditionalNote = "";
      }
    },
    deleteEmailNotes(note) {
      let find = this.emailAdditionalNotes.findIndex((notes) => notes == note);

      if (find > -1) {
        this.emailAdditionalNotes.splice(find, 1);
        this.emailAdditionalNote = "";
        this.updateEmailAdditionalNote = "";
      }
    },

    createServers() {
      if (this.serverSerialNumber) {
        this.servers.push({
          manufacturer: this.serverManufacturer,
          serialNumber: this.serverSerialNumber,
          location: this.serverLocation,
          warranty: this.serverWarrantyExpiry,
          sever: this.serverServerName,
          purpose: this.serverPurpose,
        });
        this.resetServers();
      }
    },
    editServers(server) {
      this.updateServerSerial = server.serialNumber;
      this.serverManufacturer = server.manufacturer;
      this.serverSerialNumber = server.serialNumber;
      this.serverLocation = server.location;
      this.serverWarrantyExpiry = server.warranty;
      this.serverServerName = server.sever;
      this.serverPurpose = server.purpose;
    },
    updateServers(serialNumber) {
      let find = this.servers.findIndex(
        (server) => server.serialNumber == serialNumber
      );

      if (find > -1) {
        this.servers[find].manufacturer = this.serverManufacturer;
        this.servers[find].serialNumber = this.serverSerialNumber;
        this.servers[find].location = this.serverLocation;
        this.servers[find].warranty = this.serverWarrantyExpiry;
        this.servers[find].sever = this.serverServerName;
        this.servers[find].purpose = this.serverPurpose;
        this.resetServers();
      }
    },
    deleteServers(serialNumber) {
      let find = this.servers.findIndex(
        (server) => server.serialNumber == serialNumber
      );

      if (find > -1) {
        this.servers.splice(find, 1);
        this.resetServers();
      }
    },
    resetServers() {
      this.serverManufacturer = "";
      this.serverSerialNumber = "";
      this.serverLocation = "";
      this.serverWarrantyExpiry = "";
      this.serverServerName = "";
      this.serverPurpose = "";
      this.updateServerSerial = "";
    },
    createServerNotes() {
      if (this.serverAdditionalNote) {
        this.serverAdditionalNotes.push(this.serverAdditionalNote);
        this.serverAdditionalNote = "";
        this.updateServerAdditionalNote = "";
      }
    },
    updateServerNotes(note) {
      let find = this.serverAdditionalNotes.findIndex((notes) => notes == note);

      if (find > -1) {
        this.serverAdditionalNotes[find] = this.serverAdditionalNote;
        this.serverAdditionalNote = "";
        this.updateServerAdditionalNote = "";
      }
    },
    deleteServerNotes(note) {
      let find = this.serverAdditionalNotes.findIndex((notes) => notes == note);

      if (find > -1) {
        this.serverAdditionalNotes.splice(find, 1);
        this.serverAdditionalNote = "";
        this.updateServerAdditionalNote = "";
      }
    },

    createVirtuals() {
      if (this.virtualAdminURL) {
        this.virtuals.push({
          type: this.virtualType,
          adminURL: this.virtualAdminURL,
          adminUsername: this.virtualAdminUsername,
          adminPassword: this.virtualAdminPassword,
        });
        this.resetVirtuals();
      }
    },
    editVirtuals(virtual) {
      this.updateVirtualURL = virtual.adminURL;
      this.virtualType = virtual.type;
      this.virtualAdminURL = virtual.adminURL;
      this.virtualAdminUsername = virtual.adminUsername;
      this.virtualAdminPassword = virtual.adminPassword;
    },
    updateVirtuals(adminURL) {
      let find = this.virtuals.findIndex(
        (virtual) => virtual.adminURL == adminURL
      );

      if (find > -1) {
        this.virtuals[find].type = this.virtualType;
        this.virtuals[find].adminURL = this.virtualAdminURL;
        this.virtuals[find].adminUsername = this.virtualAdminUsername;
        this.virtuals[find].adminPassword = this.virtualAdminPassword;
        this.resetVirtuals();
      }
    },
    deleteVirtuals(adminURL) {
      let find = this.virtuals.findIndex(
        (virtual) => virtual.adminURL == adminURL
      );

      if (find > -1) {
        this.virtuals.splice(find, 1);
        this.resetVirtuals();
      }
    },
    resetVirtuals() {
      this.virtualType = "";
      this.virtualAdminURL = "";
      this.virtualAdminUsername = "";
      this.virtualAdminPassword = "";
      this.updateVirtualURL = "";
    },
    createVirtualNotes() {
      if (this.virtualAdditionalNote) {
        this.virtualAdditionalNotes.push(this.virtualAdditionalNote);
        this.virtualAdditionalNote = "";
        this.updateVirtualAdditionalNote = "";
      }
    },
    updateVirtualNotes(note) {
      let find = this.virtualAdditionalNotes.findIndex(
        (notes) => notes == note
      );

      if (find > -1) {
        this.virtualAdditionalNotes[find] = this.virtualAdditionalNote;
        this.virtualAdditionalNote = "";
        this.updateVirtualAdditionalNote = "";
      }
    },
    deleteVirtualNotes(note) {
      let find = this.virtualAdditionalNotes.findIndex(
        (notes) => notes == note
      );

      if (find > -1) {
        this.virtualAdditionalNotes.splice(find, 1);
        this.virtualAdditionalNote = "";
        this.updateVirtualAdditionalNote = "";
      }
    },

    createClouds() {
      if (this.cloudVendor) {
        this.clouds.push({
          accountNumber: this.cloudAccountNumber,
          vendor: this.cloudVendor,
          adminURL: this.cloudAdminURL,
          username: this.cloudUsername,
          password: this.cloudPassword,
        });
        this.resetClouds();
      }
    },
    editClouds(cloud) {
      this.updateCloudVendor = cloud.vendor;
      this.cloudAccountNumber = cloud.accountNumber;
      this.cloudVendor = cloud.vendor;
      this.cloudAdminURL = cloud.adminURL;
      this.cloudUsername = cloud.username;
      this.cloudPassword = cloud.password;
    },
    updateClouds(vendor) {
      let find = this.clouds.findIndex((cloud) => cloud.vendor == vendor);

      if (find > -1) {
        this.clouds[find].accountNumber = this.cloudAccountNumber;
        this.clouds[find].vendor = this.cloudVendor;
        this.clouds[find].adminURL = this.cloudAdminURL;
        this.clouds[find].username = this.cloudUsername;
        this.clouds[find].password = this.cloudPassword;
        this.resetClouds();
      }
    },
    deleteClouds(vendor) {
      let find = this.clouds.findIndex((cloud) => cloud.vendor == vendor);

      if (find > -1) {
        this.clouds.splice(find, 1);
        this.resetClouds();
      }
    },
    resetClouds() {
      this.cloudAccountNumber = "";
      this.cloudVendor = "";
      this.cloudAdminURL = "";
      this.cloudUsername = "";
      this.cloudPassword = "";
      this.updateCloudVendor = "";
    },
    createCloudNotes() {
      if (this.cloudAdditionalNote) {
        this.cloudAdditionalNotes.push(this.cloudAdditionalNote);
        this.cloudAdditionalNote = "";
        this.updateCloudAdditionalNote = "";
      }
    },
    updateCloudNotes(note) {
      let find = this.cloudAdditionalNotes.findIndex((notes) => notes == note);

      if (find > -1) {
        this.cloudAdditionalNotes[find] = this.cloudAdditionalNote;
        this.cloudAdditionalNote = "";
        this.updateCloudAdditionalNote = "";
      }
    },
    deleteCloudNotes(note) {
      let find = this.cloudAdditionalNotes.findIndex((notes) => notes == note);

      if (find > -1) {
        this.cloudAdditionalNotes.splice(find, 1);
        this.cloudAdditionalNote = "";
        this.updateCloudAdditionalNote = "";
      }
    },

    createSecurities() {
      if (this.securityVendor) {
        this.securities.push({
          accountNumber: this.securityAccountNumber,
          vendor: this.securityVendor,
          adminURL: this.securityAdminURL,
          username: this.securityUsername,
          password: this.securityPassword,
        });
        this.resetSecurities();
      }
    },
    editSecurities(security) {
      this.updateSecurityVendor = security.vendor;
      this.securityAccountNumber = security.accountNumber;
      this.securityVendor = security.vendor;
      this.securityAdminURL = security.adminURL;
      this.securityUsername = security.username;
      this.securityPassword = security.password;
    },
    updateSecurities(vendor) {
      let find = this.securities.findIndex(
        (security) => security.vendor == vendor
      );

      if (find > -1) {
        this.securities[find].accountNumber = this.securityAccountNumber;
        this.securities[find].vendor = this.securityVendor;
        this.securities[find].adminURL = this.securityAdminURL;
        this.securities[find].username = this.securityUsername;
        this.securities[find].password = this.securityPassword;
        this.resetSecurities();
      }
    },
    deleteSecurities(vendor) {
      let find = this.securities.findIndex(
        (security) => security.vendor == vendor
      );

      if (find > -1) {
        this.securities.splice(find, 1);
        this.resetSecurities();
      }
    },
    resetSecurities() {
      this.securityAccountNumber = "";
      this.securityVendor = "";
      this.securityAdminURL = "";
      this.securityUsername = "";
      this.securityPassword = "";
      this.updateSecurityVendor = "";
    },
    createSecurityNotes() {
      if (this.securityAdditionalNote) {
        this.securityAdditionalNotes.push(this.securityAdditionalNote);
        this.securityAdditionalNote = "";
        this.updateSecurityAdditionalNote = "";
      }
    },
    updateSecurityNotes(note) {
      let find = this.securityAdditionalNotes.findIndex(
        (notes) => notes == note
      );

      if (find > -1) {
        this.securityAdditionalNotes[find] = this.securityAdditionalNote;
        this.securityAdditionalNote = "";
        this.updateSecurityAdditionalNote = "";
      }
    },
    deleteSecurityNotes(note) {
      let find = this.securityAdditionalNotes.findIndex(
        (notes) => notes == note
      );

      if (find > -1) {
        this.securityAdditionalNotes.splice(find, 1);
        this.securityAdditionalNote = "";
        this.updateSecurityAdditionalNote = "";
      }
    },

    createLogServers() {
      if (this.logServerIP) {
        this.logServers.push({
          name: this.logServerName,
          ip: this.logServerIP,
          location: this.logServerLocation,
          serialNumber: this.logServerSerial,
          host: this.logServerHost,
          purpose: this.logServerPurpose,
        });
        this.resetLogServers();
      }
    },
    editLogServers(logServer) {
      this.updateLogServerIP = logServer.ip;
      this.logServerName = logServer.name;
      this.logServerIP = logServer.ip;
      this.logServerLocation = logServer.location;
      this.logServerSerial = logServer.serialNumber;
      this.logServerHost = logServer.host;
      this.logServerPurpose = logServer.purpose;
    },
    updateLogServers(ip) {
      let find = this.logServers.findIndex((logServer) => logServer.ip == ip);

      if (find > -1) {
        this.logServers[find].name = this.logServerName;
        this.logServers[find].ip = this.logServerIP;
        this.logServers[find].location = this.logServerLocation;
        this.logServers[find].serialNumber = this.logServerSerial;
        this.logServers[find].host = this.logServerHost;
        this.logServers[find].purpose = this.logServerPurpose;
        this.resetLogServers();
      }
    },
    deleteLogServers(ip) {
      let find = this.logServers.findIndex((logServer) => logServer.ip == ip);

      if (find > -1) {
        this.logServers.splice(find, 1);
        this.resetLogServers();
      }
    },
    resetLogServers() {
      this.logServerName = "";
      this.logServerIP = "";
      this.logServerLocation = "";
      this.logServerSerial = "";
      this.logServerHost = "";
      this.logServerPurpose = "";
      this.updateLogServerIP = "";
    },
    createLogServerNotes() {
      if (this.logServerAdditionalNote) {
        this.logServerAdditionalNotes.push(this.logServerAdditionalNote);
        this.logServerAdditionalNote = "";
        this.updateLogServerAdditionalNote = "";
      }
    },
    updateLogServerNotes(note) {
      let find = this.logServerAdditionalNotes.findIndex(
        (notes) => notes == note
      );

      if (find > -1) {
        this.logServerAdditionalNotes[find] = this.logServerAdditionalNote;
        this.logServerAdditionalNote = "";
        this.updateLogServerAdditionalNote = "";
      }
    },
    deleteLogServerNotes(note) {
      let find = this.logServerAdditionalNotes.findIndex(
        (notes) => notes == note
      );

      if (find > -1) {
        this.logServerAdditionalNotes.splice(find, 1);
        this.logServerAdditionalNote = "";
        this.updateLogServerAdditionalNote = "";
      }
    },

    createDomainCreds() {
      if (this.domainCredName) {
        this.domainCreds.push({
          name: this.domainCredName,
          username: this.domainCredUsername,
          password: this.domainCredPassword,
        });
        this.resetDomainCreds();
      }
    },
    editDomainCreds(domainCred) {
      this.updateCredName = domainCred.name;
      this.domainCredName = domainCred.name;
      this.domainCredUsername = domainCred.username;
      this.domainCredPassword = domainCred.password;
    },
    updateDomainCreds(name) {
      let find = this.domainCreds.findIndex(
        (domainCred) => domainCred.name == name
      );

      if (find > -1) {
        this.domainCreds[find].name = this.domainCredName;
        this.domainCreds[find].username = this.domainCredUsername;
        this.domainCreds[find].password = this.domainCredPassword;
        this.resetDomainCreds();
      }
    },
    deleteDomainCreds(name) {
      let find = this.domainCreds.findIndex(
        (domainCred) => domainCred.name == name
      );

      if (find > -1) {
        this.domainCreds.splice(find, 1);
        this.resetDomainCreds();
      }
    },
    resetDomainCreds() {
      this.domainCredName = "";
      this.domainCredUsername = "";
      this.domainCredPassword = "";
      this.updateCredName = "";
    },

    createDevices() {
      if (this.deviceName) {
        this.devices.push({
          name: this.deviceName,
          purpose: this.devicePurpose,
          username: this.deviceUsername,
          password: this.devicePassword,
        });
        this.resetDevices();
      }
    },
    editDevices(device) {
      this.updateDeviceName = device.name;
      this.deviceName = device.name;
      this.devicePurpose = device.purpose;
      this.deviceUsername = device.username;
      this.devicePassword = device.password;
    },
    updateDevices(name) {
      let find = this.devices.findIndex((device) => device.name == name);

      if (find > -1) {
        this.devices[find].name = this.deviceName;
        this.devices[find].purpose = this.devicePurpose;
        this.devices[find].username = this.deviceUsername;
        this.devices[find].password = this.devicePassword;
        this.resetDevices();
      }
    },
    deleteDevices(name) {
      let find = this.devices.findIndex((device) => device.name == name);

      if (find > -1) {
        this.devices.splice(find, 1);
        this.resetDevices();
      }
    },
    resetDevices() {
      this.deviceName = "";
      this.devicePurpose = "";
      this.deviceUsername = "";
      this.devicePassword = "";
      this.updateDeviceName = "";
    },

    createDSRMServers() {
      if (this.dsrmServerName) {
        this.dsrmServers.push({
          name: this.dsrmServerName,
          password: this.dsrmServerPassword,
        });
        this.resetDSRMServers();
      }
    },
    editDSRMServers(dsrmServer) {
      this.updateDSRMName = dsrmServer.name;
      this.dsrmServerName = dsrmServer.name;
      this.dsrmServerPassword = dsrmServer.password;
    },
    updateDSRMServers(name) {
      let find = this.dsrmServers.findIndex(
        (dsrmServer) => dsrmServer.name == name
      );

      if (find > -1) {
        this.dsrmServers[find].name = this.dsrmServerName;
        this.dsrmServers[find].password = this.dsrmServerPassword;
        this.resetDSRMServers();
      }
    },
    deleteDSRMServers(name) {
      let find = this.dsrmServers.findIndex(
        (dsrmServer) => dsrmServer.name == name
      );

      if (find > -1) {
        this.dsrmServers.splice(find, 1);
        this.resetDSRMServers();
      }
    },
    resetDSRMServers() {
      this.dsrmServerName = "";
      this.dsrmServerPassword = "";
      this.updateDSRMName = "";
    },

    createSQLServers() {
      if (this.sqlServerName) {
        this.sqlServers.push({
          name: this.sqlServerName,
          username: this.sqlServerUsername,
          password: this.sqlServerPassword,
        });
        this.resetSQLServers();
      }
    },
    editSQLServers(sqlServer) {
      this.updateSQLName = sqlServer.name;
      this.sqlServerName = sqlServer.name;
      this.sqlServerUsername = sqlServer.username;
      this.sqlServerPassword = sqlServer.password;
    },
    updateSQLServers(name) {
      let find = this.sqlServers.findIndex(
        (sqlServer) => sqlServer.name == name
      );

      if (find > -1) {
        this.sqlServers[find].name = this.sqlServerName;
        this.sqlServers[find].username = this.sqlServerUsername;
        this.sqlServers[find].password = this.sqlServerPassword;
        this.resetSQLServers();
      }
    },
    deleteSQLServers(name) {
      let find = this.sqlServers.findIndex(
        (sqlServer) => sqlServer.name == name
      );

      if (find > -1) {
        this.sqlServers.splice(find, 1);
        this.resetSQLServers();
      }
    },
    resetSQLServers() {
      this.sqlServerName = "";
      this.sqlServerUsername = "";
      this.sqlServerPassword = "";
      this.updateSQLName = "";
    },

    createSoftwares() {
      if (this.softwareName) {
        this.softwares.push({
          vendor: this.softwareVendor,
          name: this.softwareName,
          contact: this.softwareContact,
          support: this.softwareSupport,
          purpose: this.softwarePurpose,
        });
        this.resetSoftwares();
      }
    },
    editSoftwares(software) {
      this.updateSoftwareName = software.name;
      this.softwareVendor = software.vendor;
      this.softwareName = software.name;
      this.softwareContact = software.contact;
      this.softwareSupport = software.support;
      this.softwarePurpose = software.purpose;
    },
    updateSoftwares(name) {
      let find = this.softwares.findIndex((software) => software.name == name);

      if (find > -1) {
        this.softwares[find].vendor = this.softwareVendor;
        this.softwares[find].name = this.softwareName;
        this.softwares[find].contact = this.softwareContact;
        this.softwares[find].support = this.softwareSupport;
        this.softwares[find].purpose = this.softwarePurpose;
        this.resetSoftwares();
      }
    },
    deleteSoftwares(name) {
      let find = this.softwares.findIndex((software) => software.name == name);

      if (find > -1) {
        this.softwares.splice(find, 1);
        this.resetSoftwares();
      }
    },
    resetSoftwares() {
      this.softwareVendor = "";
      this.softwareName = "";
      this.softwareContact = "";
      this.softwareSupport = "";
      this.softwarePurpose = "";
      this.updateSoftwareName = "";
    },
    createSoftwareNotes() {
      if (this.softwareAdditionalNote) {
        this.softwareAdditionalNotes.push(this.softwareAdditionalNote);
        this.softwareAdditionalNote = "";
        this.updateSoftwareAdditionalNote = "";
      }
    },
    updateSoftwareNotes(note) {
      let find = this.softwareAdditionalNotes.findIndex(
        (notes) => notes == note
      );

      if (find > -1) {
        this.softwareAdditionalNotes[find] = this.softwareAdditionalNote;
        this.softwareAdditionalNote = "";
        this.updateSoftwareAdditionalNote = "";
      }
    },
    deleteSoftwareNotes(note) {
      let find = this.softwareAdditionalNotes.findIndex(
        (notes) => notes == note
      );

      if (find > -1) {
        this.softwareAdditionalNotes.splice(find, 1);
        this.softwareAdditionalNote = "";
        this.updateSoftwareAdditionalNote = "";
      }
    },

    createBackups() {
      if (this.backupAdminURL) {
        this.backups.push({
          vendor: this.backupVendor,
          adminURL: this.backupAdminURL,
          username: this.backupUsername,
          password: this.backupPassword,
          purpose: this.backupPurpose,
        });
        this.resetBackups();
      }
    },
    editBackups(backup) {
      this.updateBackupAdminURL = backup.adminURL;
      this.backupVendor = backup.vendor;
      this.backupAdminURL = backup.adminURL;
      this.backupUsername = backup.username;
      this.backupPassword = backup.password;
      this.backupPurpose = backup.purpose;
    },
    updateBackups(adminURL) {
      let find = this.backups.findIndex(
        (backup) => backup.adminURL == adminURL
      );

      if (find > -1) {
        this.backups[find].vendor = this.backupVendor;
        this.backups[find].adminURL = this.backupAdminURL;
        this.backups[find].username = this.backupUsername;
        this.backups[find].password = this.backupPassword;
        this.backups[find].purpose = this.backupPurpose;
        this.resetBackups();
      }
    },
    deleteBackups(adminURL) {
      let find = this.backups.findIndex(
        (backup) => backup.adminURL == adminURL
      );

      if (find > -1) {
        this.backups.splice(find, 1);
        this.resetBackups();
      }
    },
    resetBackups() {
      this.backupVendor = "";
      this.backupAdminURL = "";
      this.backupUsername = "";
      this.backupPassword = "";
      this.backupPurpose = "";
      this.updateBackupAdminURL = "";
    },
    createBackupNotes() {
      if (this.backupAdditionalNote) {
        this.backupAdditionalNotes.push(this.backupAdditionalNote);
        this.backupAdditionalNote = "";
        this.updateBackupAdditionalNote = "";
      }
    },
    updateBackupNotes(note) {
      let find = this.backupAdditionalNotes.findIndex((notes) => notes == note);

      if (find > -1) {
        this.backupAdditionalNotes[find] = this.backupAdditionalNote;
        this.backupAdditionalNote = "";
        this.updateBackupAdditionalNote = "";
      }
    },
    deleteBackupNotes(note) {
      let find = this.backupAdditionalNotes.findIndex((notes) => notes == note);

      if (find > -1) {
        this.backupAdditionalNotes.splice(find, 1);
        this.backupAdditionalNote = "";
        this.updateBackupAdditionalNote = "";
      }
    },

    createOffSiteBackups() {
      if (this.offSiteBackupAdminURL) {
        this.offSiteBackups.push({
          vendor: this.offSiteBackupVendor,
          adminURL: this.offSiteBackupAdminURL,
          username: this.offSiteBackupUsername,
          password: this.offSiteBackupPassword,
          purpose: this.offSiteBackupPurpose,
        });
        this.resetOffSiteBackups();
      }
    },
    editOffSiteBackups(offSiteBackup) {
      this.updateOffSiteBackupAdminURL = offSiteBackup.adminURL;
      this.offSiteBackupVendor = offSiteBackup.vendor;
      this.offSiteBackupAdminURL = offSiteBackup.adminURL;
      this.offSiteBackupUsername = offSiteBackup.username;
      this.offSiteBackupPassword = offSiteBackup.password;
      this.offSiteBackupPurpose = offSiteBackup.purpose;
    },
    updateOffSiteBackups(adminURL) {
      let find = this.offSiteBackups.findIndex(
        (offSiteBackup) => offSiteBackup.adminURL == adminURL
      );

      if (find > -1) {
        this.offSiteBackups[find].vendor = this.offSiteBackupVendor;
        this.offSiteBackups[find].adminURL = this.offSiteBackupAdminURL;
        this.offSiteBackups[find].username = this.offSiteBackupUsername;
        this.offSiteBackups[find].password = this.offSiteBackupPassword;
        this.offSiteBackups[find].purpose = this.offSiteBackupPurpose;
        this.resetOffSiteBackups();
      }
    },
    deleteOffSiteBackups(adminURL) {
      let find = this.offSiteBackups.findIndex(
        (offSiteBackup) => offSiteBackup.adminURL == adminURL
      );

      if (find > -1) {
        this.offSiteBackups.splice(find, 1);
        this.resetOffSiteBackups();
      }
    },
    resetOffSiteBackups() {
      this.offSiteBackupVendor = "";
      this.offSiteBackupAdminURL = "";
      this.offSiteBackupUsername = "";
      this.offSiteBackupPassword = "";
      this.offSiteBackupPurpose = "";
      this.updateOffSiteBackupAdminURL = "";
    },
    createOffSiteBackupNotes() {
      if (this.offSiteBackupAdditionalNote) {
        this.offSiteBackupAdditionalNotes.push(
          this.offSiteBackupAdditionalNote
        );
        this.offSiteBackupAdditionalNote = "";
        this.updateOffSiteBackupAdditionalNote = "";
      }
    },
    updateOffSiteBackupNotes(note) {
      let find = this.offSiteBackupAdditionalNotes.findIndex(
        (notes) => notes == note
      );

      if (find > -1) {
        this.offSiteBackupAdditionalNotes[
          find
        ] = this.offSiteBackupAdditionalNote;
        this.offSiteBackupAdditionalNote = "";
        this.updateOffSiteBackupAdditionalNote = "";
      }
    },
    deleteOffSiteBackupNotes(note) {
      let find = this.offSiteBackupAdditionalNotes.findIndex(
        (notes) => notes == note
      );

      if (find > -1) {
        this.offSiteBackupAdditionalNotes.splice(find, 1);
        this.offSiteBackupAdditionalNote = "";
        this.updateOffSiteBackupAdditionalNote = "";
      }
    },

    createComputers() {
      if (this.computerSerialNumber) {
        this.computers.push({
          manufacturer: this.computerManufacturer,
          serialNumber: this.computerSerialNumber,
          location: this.computerLocation,
          warranty: this.computerWarrantyExpiry,
          primaryUser: this.computerPrimaryUser,
          devicePassword: this.computerDeviceName,
        });
        this.resetComputers();
      }
    },
    editComputers(computer) {
      this.updateComputerSerial = computer.serialNumber;
      this.computerManufacturer = computer.manufacturer;
      this.computerSerialNumber = computer.serialNumber;
      this.computerLocation = computer.location;
      this.computerWarrantyExpiry = computer.warranty;
      this.computerPrimaryUser = computer.primaryUser;
      this.computerDeviceName = computer.devicePassword;
    },
    updateComputers(serialNumber) {
      let find = this.computers.findIndex(
        (computer) => computer.serialNumber == serialNumber
      );

      if (find > -1) {
        this.computers[find].manufacturer = this.computerManufacturer;
        this.computers[find].serialNumber = this.computerSerialNumber;
        this.computers[find].location = this.computerLocation;
        this.computers[find].warranty = this.computerWarrantyExpiry;
        this.computers[find].primaryUser = this.computerPrimaryUser;
        this.computers[find].devicePassword = this.computerDeviceName;
        this.resetComputers();
      }
    },
    deleteComputers(serialNumber) {
      let find = this.computers.findIndex(
        (computer) => computer.serialNumber == serialNumber
      );

      if (find > -1) {
        this.computers.splice(find, 1);
        this.resetComputers();
      }
    },
    resetComputers() {
      this.computerManufacturer = "";
      this.computerSerialNumber = "";
      this.computerLocation = "";
      this.computerWarrantyExpiry = "";
      this.computerPrimaryUser = "";
      this.computerDeviceName = "";
      this.updateComputerSerial = "";
    },

    createVOIPs() {
      if (this.voipSerialNumber) {
        this.voips.push({
          manufacturer: this.voipManufacturer,
          serialNumber: this.voipSerialNumber,
          location: this.voipLocation,
          adminURL: this.voipAdminURL,
          adminUsername: this.voipAdminUsername,
          adminPassword: this.voipAdminPassword,
        });
        this.resetVOIPs();
      }
    },
    editVOIPs(voip) {
      this.updateVOIPSerial = voip.serialNumber;
      this.voipManufacturer = voip.manufacturer;
      this.voipSerialNumber = voip.serialNumber;
      this.voipLocation = voip.location;
      this.voipAdminURL = voip.adminURL;
      this.voipAdminUsername = voip.adminUsername;
      this.voipAdminPassword = voip.adminPassword;
    },
    updateVOIPs(serialNumber) {
      let find = this.voips.findIndex(
        (voip) => voip.serialNumber == serialNumber
      );

      if (find > -1) {
        this.voips[find].manufacturer = this.voipManufacturer;
        this.voips[find].serialNumber = this.voipSerialNumber;
        this.voips[find].location = this.voipLocation;
        this.voips[find].adminURL = this.voipAdminURL;
        this.voips[find].adminUsername = this.voipAdminUsername;
        this.voips[find].adminPassword = this.voipAdminPassword;
        this.resetVOIPs();
      }
    },
    deleteVOIPs(serialNumber) {
      let find = this.voips.findIndex(
        (voip) => voip.serialNumber == serialNumber
      );

      if (find > -1) {
        this.voips.splice(find, 1);
        this.resetVOIPs();
      }
    },
    resetVOIPs() {
      this.voipManufacturer = "";
      this.voipSerialNumber = "";
      this.voipLocation = "";
      this.voipAdminURL = "";
      this.voipAdminUsername = "";
      this.voipAdminPassword = "";
      this.updateVOIPSerial = "";
    },
    createVOIPNotes() {
      if (this.voipAdditionalNote) {
        this.voipAdditionalNotes.push(this.voipAdditionalNote);
        this.voipAdditionalNote = "";
        this.updateVOIPAdditionalNote = "";
      }
    },
    updateVOIPNotes(note) {
      let find = this.voipAdditionalNotes.findIndex((notes) => notes == note);

      if (find > -1) {
        this.voipAdditionalNotes[find] = this.voipAdditionalNote;
        this.voipAdditionalNote = "";
        this.updateVOIPAdditionalNote = "";
      }
    },
    deleteVOIPNotes(note) {
      let find = this.voipAdditionalNotes.findIndex((notes) => notes == note);

      if (find > -1) {
        this.voipAdditionalNotes.splice(find, 1);
        this.voipAdditionalNote = "";
        this.updateVOIPAdditionalNote = "";
      }
    },

    createTelephones() {
      if (this.telephonyAccountNumber) {
        this.telephones.push({
          manufacturer: this.telephonyVendor,
          accountNumber: this.telephonyAccountNumber,
          contact: this.telephonyContact,
          support: this.telephonySupport,
          purpose: this.telephonyPurpose,
        });
        this.resetTelephones();
      }
    },
    editTelephones(telephony) {
      this.updateTelephonyAccountNumber = telephony.accountNumber;
      this.telephonyVendor = telephony.manufacturer;
      this.telephonyAccountNumber = telephony.accountNumber;
      this.telephonyContact = telephony.contact;
      this.telephonySupport = telephony.support;
      this.telephonyPurpose = telephony.purpose;
    },
    updateTelephones(accountNumber) {
      let find = this.telephones.findIndex(
        (telephony) => telephony.accountNumber == accountNumber
      );

      if (find > -1) {
        this.telephones[find].manufacturer = this.telephonyVendor;
        this.telephones[find].accountNumber = this.telephonyAccountNumber;
        this.telephones[find].contact = this.telephonyContact;
        this.telephones[find].support = this.telephonySupport;
        this.telephones[find].purpose = this.telephonyPurpose;
        this.resetTelephones();
      }
    },
    deleteTelephones(accountNumber) {
      let find = this.telephones.findIndex(
        (telephony) => telephony.accountNumber == accountNumber
      );

      if (find > -1) {
        this.telephones.splice(find, 1);
        this.resetTelephones();
      }
    },
    resetTelephones() {
      this.telephonyVendor = "";
      this.telephonyAccountNumber = "";
      this.telephonyContact = "";
      this.telephonySupport = "";
      this.telephonyPurpose = "";
      this.updateTelephonyAccountNumber = "";
    },
    createTelephonyNotes() {
      if (this.telephonyAdditionalNote) {
        this.telephonyAdditionalNotes.push(this.telephonyAdditionalNote);
        this.telephonyAdditionalNote = "";
        this.updateTelephonyAdditionalNote = "";
      }
    },
    updateTelephonyNotes(note) {
      let find = this.telephonyAdditionalNotes.findIndex(
        (notes) => notes == note
      );

      if (find > -1) {
        this.telephonyAdditionalNotes[find] = this.telephonyAdditionalNote;
        this.telephonyAdditionalNote = "";
        this.updateTelephonyAdditionalNote = "";
      }
    },
    deleteTelephonyNotes(note) {
      let find = this.telephonyAdditionalNotes.findIndex(
        (notes) => notes == note
      );

      if (find > -1) {
        this.telephonyAdditionalNotes.splice(find, 1);
        this.telephonyAdditionalNote = "";
        this.updateTelephonyAdditionalNote = "";
      }
    },

    createFinalNotes() {
      if (this.finalAdditionalNote) {
        this.finalAdditionalNotes.push(this.finalAdditionalNote);
        this.finalAdditionalNote = "";
        this.updateFinalAdditionalNote = "";
      }
    },
    updateFinalNotes(note) {
      let find = this.finalAdditionalNotes.findIndex((notes) => notes == note);

      if (find > -1) {
        this.finalAdditionalNotes[find] = this.finalAdditionalNote;
        this.finalAdditionalNote = "";
        this.updateFinalAdditionalNote = "";
      }
    },
    deleteFinalNotes(note) {
      let find = this.finalAdditionalNotes.findIndex((notes) => notes == note);

      if (find > -1) {
        this.finalAdditionalNotes.splice(find, 1);
        this.finalAdditionalNote = "";
        this.updateFinalAdditionalNote = "";
      }
    },
  },
};
</script>

<style scoped>
.new-ticket {
  text-align: center;
  padding: 1rem 0;
  border-top: solid 1px #d8d6de;
}
.new-ticket a {
  font-weight: 500;
  color: #7367f0;
  text-decoration: none;
  font-size: 0.8rem;
}

.flex-rows a {
  font-family: "poppins";
  color: #7367f0;
  float: right;
  text-decoration: none;
  display: inline;
}

.alert {
  font-weight: 600;
  font-size: 1rem;
  padding: 0.71rem 1rem;
  margin-bottom: 0;
  margin-top: 0.71rem;
}

.alert-danger {
  background-color: #fbdddd;
  border-color: #f9cfcf;
  box-shadow: rgb(234 84 85 / 40%) 0px 6px 15px -7px;
  color: #ea5455;
}

.btn-primary {
  border-color: #7367f0 !important;
  background-color: #7367f0 !important;
  color: #fff !important;
  font-family: "poppins";
  font-size: 0.9rem;
}

.company {
  text-align: center;
}

.company img {
  margin-bottom: 1rem !important;
}

.main-container h4 {
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 1rem !important;
  line-height: 1.2;
  color: #5e5873;
}

#profile-img {
  width: 170px;
}

.main-container {
  margin: 1em auto;
  max-width: 1140px;
  padding: 1.5rem;
  background-clip: border-box;
  border: 1px solid rgba(34, 41, 47, 0.125);
  border-radius: 0.428rem;
  background-color: white;
  font-family: "poppins";
}

.error-feedback {
  color: #ea5455;
  font-size: 0.8rem;
  font-family: "poppins";
  font-weight: 500;
}

label {
  color: #5e5873;
  font-size: 0.9rem;
  margin-bottom: 0.1rem;
  font-family: "poppins";
  font-weight: 500;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.438rem 0.438rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.card-container.card {
  width: 300px !important;
}

.card {
  background-color: white;
  padding: 15px 0px 15px 0px;
  margin: auto;
  border: 0px;
  box-shadow: 0 0 0 0 rgb(34 41 47 / 10%);
}

@media screen and (max-width: 400px) {
  .main-container {
    width: calc(100% - 1.5em);
    padding: 1rem !important;
  }
  .card {
    padding: 0;
  }

  .card-container.card {
    width: 100% !important;
  }
}
@media screen and (max-width: 500px) {
  .main-container {
    padding: 0.5rem !important;
  }
}

/* Header styles */

.header {
  margin: 1em auto;
  text-align: center;
}

.header p ion-icon,
.header a ion-icon {
  margin-bottom: -2px;
}

.header a p {
  color: #5e5873;
  margin: 0;
}

.header .contact-details a {
  color: #5e5873;
  font-weight: 500;
}

.header h1 {
  font-weight: 500;
  margin-bottom: 1rem !important;
  line-height: 1.2;
}

/* body */

.main-container h2 {
  color: #5e5873;
  font-weight: 600;
  margin-bottom: 1rem;
}

.main-container p {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
}

.main-container ul {
  padding-left: 2rem;
  list-style: inside;
}

.main-container a {
  color: #5e5873;
  font-weight: 600;
}

.hidden {
  display: none;
}

/* footer */

.footer {
  margin: 1em auto;
  padding: 0.8rem 1.5rem 0.8rem 1.5rem;
  background-clip: border-box;
  border: 1px solid rgba(34, 41, 47, 0.125);
  border-radius: 0.428rem;
  background-color: white;
  max-width: 1140px;
}

.flex {
  display: flex;
  align-items: center;
}

.footer .button {
  width: 120px;
}

.footer .pagination {
  width: calc(100% - 240px);
  margin: 0rem 1rem;
  background-color: white;
  justify-content: center;
  font-weight: 600;
  display: block;
  text-align: center;
}
.pagination-numbers {
  margin-top: 0.7rem;
}
.pagination-numbers span {
  cursor: pointer;
  padding: 0.2rem 0.5rem;
  margin: 0.4rem;
  border: 1px solid rgba(34, 41, 47, 0.125);
  border-radius: 0.428rem;
  background-color: white;
}

.active-page {
  background-color: #7367f0 !important;
  color: white;
}

.main-container h3 {
  color: #7367f0;
  font-weight: 600;
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

input[type="checkbox"] {
  margin-right: 0.5em;
  width: 1em;
  height: 1em;
}

.page2 .form {
  margin-left: 1.5rem;
}

.page2 .form label {
  font-size: 0.95rem;
  font-weight: 400;
  color: #212529;
  line-height: 1rem;
}

.page2 .form .flex {
  margin-bottom: 1rem;
}

.sub-heading {
  margin-bottom: 3rem;
  border-left: solid 5px #212529;
  padding: 0rem 0rem 0rem 0.5rem;
}

.small-heading {
  margin-top: 2rem !important;
  margin-bottom: 3rem;
  border-left: solid 5px #212529;
  padding: 0rem 0rem 0rem 0.5rem;
}

.form {
  display: flex;
  flex-wrap: wrap;
}

.form .inputGroup {
  width: calc(33.33% - 1rem);
  margin: 0.25rem 0.5rem;
}

.additionalNotes {
  width: calc(100% - 1rem) !important;
}

.form .inputGroup label {
  display: block;
  width: 100%;
}

.create {
  color: green !important;
  font-size: 1.2rem;
}

.trash {
  color: red !important;
  font-size: 1.2rem;
}

select {
  color: #6e6b7b;
  background-color: #fff;
  cursor: default;
  margin: 0em;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  padding: 0.35rem 0.2rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.45;
  font-family: "poppins";
  width: 100%;
}

input,
textarea {
  overflow: hidden;
  padding: 0.35rem 0.5rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  margin-right: 1rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
  width: 100%;
}

input:focus {
  box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%) !important;
  background-color: #fff !important;
  border-color: #7367f0 !important;
}

.submit-button {
  margin: 0.5rem 0.5rem 1.5rem 0.5rem;
}

.submit-button button {
  width: 120px;
  font-weight: 500;
  font-size: 0.9rem;
}

.page2 .submit-button button {
  width: 150px;
}

/* Table Styles */

table {
  width: 100%;
  border-radius: 5px !important;
  box-shadow: 0 4px 8px 0 rgb(34 41 47 / 20%);
  width: 100%;
  word-wrap: break-word;
  color: #48474e;
  text-indent: initial;
  border-spacing: 2px;
  margin-top: 1rem;
}

table th {
  background-color: #f3f2f7;
  vertical-align: top;
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 0.5px;
  border-top: 1px solid #ebe9f1;
  padding: 0.6rem 1rem;
  border-bottom: 2px solid #ebe9f1;
  font-weight: 500;
  line-height: 1;
  font-weight: 600;
}

table tbody td {
  padding: 0.5rem 1rem;
  border-top: 1px solid #ebe9f1;
  vertical-align: middle;
  display: table-cell;
  border-collapse: collapse;
  font-size: 14px;
}

.btn-primary {
  margin-right: 1rem;
}

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.popup,
.popup-organization {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(27, 3, 3, 0.307);
  z-index: 9999;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
}

.popup-organization {
  background-color: rgba(0, 0, 0, 0.85);
}

.popup-inner {
  text-align: center;
  width: 30rem;
  padding: 1.5rem;
  background-clip: border-box;
  border: 1px solid rgba(34, 41, 47, 0.125);
  border-radius: 0.428rem;
  background-color: white;
}

.popup-inner #profile-img {
  width: 140px;
}

.popup-inner p {
  margin: 1.5rem 0;
}

.popup-inner button {
  width: 6rem;
}

@media screen and (max-width: 1190px) {
  .footer,
  .main-container {
    margin: 1em;
  }
}

@media screen and (max-width: 600px) {
  .footer,
  .main-container {
    margin: 1em 0.5rem;
  }
  .pagination div {
    display: none;
  }
}

@media screen and (max-width: 870px) {
  .pagination-numbers {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .main-container h4 {
    font-size: 0.9rem;
  }

  .main-container h3 {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }

  .main-container p {
    font-size: 0.85rem;
  }

  .main-container ul li {
    font-size: 0.85rem;
  }

  .main-container h2 {
    font-size: 1.2rem;
  }

  .popup-inner #profile-img {
    width: 120px;
  }
  .company img {
    margin-bottom: 0.5rem !important;
  }
  .header h1 {
    font-size: 2.2rem;
    margin-bottom: 0.5rem !important;
  }

  .header .contact-details {
    font-size: 0.9rem;
  }

  .footer {
    font-size: 0.9rem;
    padding: 0.5rem 1rem 0.5rem 1rem;
  }

  .table-container {
    overflow: auto;
    padding: 0 5px;
    margin-bottom: 1.5rem;
  }

  .main-container {
    padding: 0.9rem;
  }

  .form .inputGroup {
    width: calc(50% - 0.5rem);
    margin: 0.25rem 0.25rem;
  }
  .form .inputGroup label {
    font-size: 0.85rem;
  }
  .btn-primary {
    margin-right: 0.2rem;
  }

  input,
  textarea {
    margin-right: 0.2rem;
  }
}

b,
strong {
  font-weight: 600;
}

.page2 ul input {
  margin: 0.6rem 0rem 0rem 0.35rem;
  width: calc(100% - 1rem);
}

.popup-inner .header p {
  margin: 0;
}

#upto-here {
  border-color: #fc5c9c !important;
  background-color: #fc5c9c !important;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.3;
}
</style>
